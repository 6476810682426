import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown, Button, Badge } from "react-bootstrap";
import Select from "react-select";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import * as OrganisationService from "../../service/organisation"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData
} from "../redux/piDataStore";
import * as OtvService from "../../service/otvServices";

function OTVPickList() {

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);
  const dispatch = useDispatch()
  const userData = useSelector(selectUserData)

  const [show, filtersetShow] = useState(false);
  const filteropen = () => {
    filtersetShow(true);
    getFilterOtvDataList(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      sortData.sort_by,
      false,
      "",
      true
    );
  }
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [vendorFilterList, setVendorFilterList] = useState([]);
  const [vendorFilterValue, setVendorFilterValue] = useState([]);
  const [invoiceFilterList, setinvoiceFilterList] = useState([]);
  const [invoiceFilterValue, setinvoiceFilterValue] = useState([]);
  const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const [reqByNameList, setReqByNameList] = useState([]);
  const [reqByNameValue, setReqByNameValue] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const handleShow = () => setShow(true);
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: ""
  });

  const [filterNonOtv, setFilterNonOtv] = useState({
    end_date: "",
    start_date: "",
    id: "",
    type: "",
    vendor_id: "",
    invoice_no: "",
    expense_category_id: "",
    status: "",
    sap_status: "",
    requested_by_id: "",
  });

  const navigate = useNavigate();


  const handlePageClick = (action) => {
    let activePage = action.selected + 1
    setCurrentPage(activePage)

  };

  useEffect(() => {
    getOtvDataList(
      filterNonOtv.type,
      searchStr,
      filterNonOtv.end_date,
      filterNonOtv.start_date,
      filterNonOtv.requested_by_id,
      "",
      filterNonOtv.vendor_id,
      filterNonOtv.invoice_no,
      filterNonOtv.expense_category_id,
      filterNonOtv.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      true
    );

  }, []);

  const handleKey = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status,
    ssc_flag
  ) => {
    let queryParm = "sort_by=" + sort_by;
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    if (paginate !== "" && paginate !== undefined && paginate !== null) {
      queryParm = queryParm + "&paginate=" + paginate;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
      queryParm = queryParm + "&sap_status=" + sap_status;
    }
    if (ssc_flag !== "" && ssc_flag !== undefined && ssc_flag !== null) {
      queryParm = queryParm + "&ssc_flag=" + ssc_flag;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (type !== "" && type !== undefined && type !== null) {
      queryParm = queryParm + "&type=" + type;
    }
    if (
      requested_by_id !== "" &&
      requested_by_id !== undefined &&
      requested_by_id !== null
    ) {
      queryParm = queryParm + "&requested_by_id=" + requested_by_id;
    }
    if (vendor_id !== "" && vendor_id !== undefined && vendor_id !== null) {
      queryParm = queryParm + "&vendor_id=" + vendor_id;
    }
    if (invoice_no !== "" && invoice_no !== undefined && invoice_no !== null) {
      queryParm = queryParm + "&invoice_no=" + invoice_no;
    }
    if (
      expense_category_id !== "" &&
      expense_category_id !== undefined &&
      expense_category_id !== null
    ) {
      queryParm = queryParm + "&expense_category_id=" + expense_category_id;
    }
    return queryParm;
  };
  const onDataViewNavigate = (id, flag) => {
    navigate(`/viewotv/${id}`, {
      state: { from: id, flag: flag },
    });
  };

  const getOtvDataList = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status,
    ssc_flag
  ) => {
    OtvService
      .getOtvDataList(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          sap_status,
          ssc_flag
        )
      )
      .then((response) => {
        setDataList(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {

    if (e.target.value.length > 2 || e.target.value.length == 0) {
      getOtvDataList(
        filterNonOtv.type,
        e.target.value.toLowerCase(),
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        "",
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
      setCurrentPage(1)
      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterNonOtv.end_date;
    const start_date = filterNonOtv.start_date;
    const id = filterNonOtv.id;
    const vendor_id = filterNonOtv.vendor_id;
    const invoice_no = filterNonOtv.invoice_no;
    const expense_category_id = filterNonOtv.expense_category_id;
    const status = filterNonOtv.status;
    const requestId = filterNonOtv.requested_by_id;
    const sap_status = filterNonOtv.sap_status;

    getOtvDataList(
      filterNonOtv.type,
      searchStr,
      end_date,
      start_date,
      id,
      requestId,
      vendor_id,
      invoice_no,
      expense_category_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      sap_status,
      true
    );
    filterclose();
  };


  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterNonOtv));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setinvoiceFilterValue([]);
    setReqByNameValue([]);
    setVendorFilterValue([]);
    setFilterNonOtv(ClearData);
    filterclose();
    getOtvDataList(
      "",
      searchStr,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      true
    );
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(dataList));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setDataList(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.map((res) => {
        if (res?.id != data?.id) {
          return res;
        }
      });
      setPostingData(obj);
      setDataList(copyData1);
    }
  };

  const onReceive = () => {
    if (postingData?.length < 1) {
      toast.error("Please select a Record to receive")
      return
    }

    confirmAlert({
      title: "Receive OTV/NON-PO Pick List",
      message: `Are you sure to Receive Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => receivePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };


  const onDecline = () => {
    confirmAlert({
      title: "Discard OTV/NON-PO Pick List",
      message: `Are you sure to Discard Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => declinePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(dataList));
    if (!data?.length) {
      return
    }
    let copyposData = JSON.parse(JSON.stringify(postingData));

    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        // postingData.push(res);
        let payl = {
          id: res?.id,
        }

        if (copyposData?.length > 0) {
          copyposData?.map((pdata) => {
            if (pdata?.id != payl?.id) {
              postingData.push(payl);
            }
          })
        }
        else {
          postingData.push(payl);
        }

      });
      setDataList(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setDataList(data);
      setPostingData([]);
    }
  };

  const getExportValue = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status,
    ssc_flag
  ) => {
    dispatch(setDisplayLoader("Display"));
    OtvService
      .getExportValue(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          sap_status,
          ssc_flag
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Otv-nonpo-picklist.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const receivePickList = () => {
    let data = [];

    postingData.map((res) => {
      if (res) {
        data.push(res?.id)
      }
    });

    if (data?.length < 1) {
      toast.error("Please select an Record to receive")
      return
    }


    let obj = { data: data };

    OtvService.receivePickList(obj).then((response) => {
      toast.success("Records received successfully.")
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        "",
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
      setPostingData([]);
    });
  };



  const declinePickList = () => {
    let data = postingData;
    let idList = [];
    data.map((res) => {
      idList.push(res.id);
    });
    let obj = { data: postingData };
    // OtvService.declinePickList(obj).then((response) => {
    //   getOtvPickList(
    //     searchStr,
    //     currentPage,
    //     pageSizeNo,
    //     sortData.sort_by,
    //     true,
    //     "",
    //      "","","","","",false
    //   );
    // });
  };

  const onPushRequestBy = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, requested_by_id: ids });
    setReqByNameValue(e);
  };

  const onPushVendorFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, vendor_id: ids });
    setVendorFilterValue(e);
  };

  const onPushInvoiceFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    if (ids !== "") {
      setFilterNonOtv((prev) => ({ ...prev, invoice_no: ids }));
    }
    setinvoiceFilterValue(e);
  };

  const getFilterOtvDataList = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status,
    ssc_flag
  ) => {
    OtvService
      .getOtvDataList(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          sap_status,
          ssc_flag
        )
      )
      .then((response) => {
        let vendorData = [];
        let invoiceNo = [];
        let requestedBy = [];
        let requestedId = [];

        response?.data?.dataList?.result?.map((res) => {
          let venData = {
            value: res.vendor_code,
            label: res.vendor_name,
          };
          vendorData.push(venData);
          let invoiceData = {
            value: res.invoice_number,
            label: res.invoice_number,
          };
          invoiceNo.push(invoiceData);
          let reqData = {
            value: res.id,
            label: res.id,
          };
          requestedId.push(reqData);
          let reqByData = {
            value: res.created_by,
            label: res.created_by_name,
          };
          requestedBy.push(reqByData);
        });
        let jsonObject = requestedBy.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        let jsonObjectVend = vendorData.map(JSON.stringify);
        let uniqueSetVend = new Set(jsonObjectVend);
        let uniqueArrayVend = Array.from(uniqueSetVend).map(JSON.parse);
        setVendorFilterList(uniqueArrayVend);
        setinvoiceFilterList(invoiceNo);
        // setReqIdList(requestedId);
        setReqByNameList(uniqueArray);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">OTV/NON-PO Pick List</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Invoice,
                              <br />
                              bank name
                              <br />
                              bank acc
                              <br />
                              bank ifsc
                              <br />
                              type
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc manager" && */}
                      {/* <button
                            class="btn btn-primary-inner"
                            type="button"
                            onClick={() => onDecline()}
                          >
                            <i class="far fa-check-circle"></i> Discard
                          </button> */}
                      {/* )) } */}


                      {/* { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc user" && */}
                      <button
                        class="btn btn-primary-inner"
                        type="button"
                        onClick={() => onReceive()}
                      >
                        <i class="far fa-check-circle"></i> Receive
                      </button>
                      {/* )) } */}

                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={dataList?.length === 0}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  "",
                                  searchStr,
                                  filterNonOtv.end_date,
                                  filterNonOtv.start_date,
                                  filterNonOtv.id,
                                  "",
                                  filterNonOtv.vendor_id,
                                  filterNonOtv.invoice_no,
                                  filterNonOtv.expense_category_id,
                                  filterNonOtv.status,
                                  "",
                                  "",
                                  "id",
                                  "",
                                  filterNonOtv.sap_status,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                {/* <Tabs defaultActiveKey="approval" id="approvaltabs">
                  <Tab
                    eventKey="approval"
                    title={
                      <React.Fragment>
                        Pickup List
                        <span class="numberpurchse">{totalRecord}</span>
                      </React.Fragment>
                    }
                  > */}
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>Sno.</th>
                        <th>
                          <label class="logCheck d-inline-block">
                            <input
                              type="checkbox"
                              name="permission"
                              onClick={(e) => {
                                handleAllData(e);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </th>
                        {/* <th>Status</th>
                            <th>Pending At</th> */}
                        <th>Action</th>
                        <th
                        //   onClick={(e) => {
                        //     handlerData("id");
                        //   }}
                        >
                          Request ID
                        </th>
                        <th
                        //   onClick={(e) => {
                        //     handlerData("code");
                        //   }}
                        >
                          Vendor Code
                        </th>
                        <th
                        //   onClick={(e) => {
                        //     handlerData("name");
                        //   }}
                        >
                          Vendor Name
                        </th>
                        <th>GST</th>
                        <th>PAN</th>
                        <th>Location</th>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Expense Category</th>
                        <th>Base Amount</th>
                        <th>GL Code</th>
                        <th>Cost Center</th>
                        <th>Bank Name</th>
                        <th>Bank A/c No</th>
                        <th>IFC Code</th>
                        <th>SAP Document No</th>
                        <th>SAP Message</th>
                        <th>SAP status</th>
                        <th>Type</th>
                        <th>Requested By</th>
                        <th>Requested On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList?.length > 0 ? (
                        dataList?.map((data, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <label class="logCheck d-inline-block">
                                  <input
                                    type="checkbox"
                                    name="permission"
                                    onClick={(e) => {
                                      selectDataToPost(e, data);
                                    }}
                                    checked={data?.checked === "Y"}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </td>
                              {/* <td>
                                  {data.is_active === true ? (
                                    data?.status === null ? (
                                      <Badge bg="secondary">Pending</Badge>
                                    ) : data?.status == "A" ? (
                                      <Badge bg="success">Approved</Badge>
                                    ) : (
                                      <Badge bg="danger">Rejected</Badge>
                                    )
                                  ) : (
                                    <Badge bg="danger">Deleted</Badge>
                                  )}
                                </td>
                                <td>
                                  {data?.status == "A" ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={`${data?.current_approver_data?.current_approver_code}`}
                                      position="bottom"
                                    >
                                      {data?.current_approver_data
                                        ?.current_approver_name !== undefined
                                        ? `${data?.current_approver_data?.current_approver_name} (${data?.current_approver_data?.current_approver_code})`
                                        : ""}
                                    </Tooltip>
                                  )}
                                </td> */}
                              <td>
                                <button
                                  class="btn btn-blanktd text-primary f-12"
                                onClick={() =>
                                  onDataViewNavigate(data.id, 1)
                                }
                                >
                                  <i class="far fa-eye"></i>
                                </button>

                              </td>
                              <td>{`${data?.type}${data?.id}`}</td>
                              <td>
                                {data?.vendor_code === null
                                  ? "104746"
                                  : data?.vendor_code}
                              </td>
                              <td>
                                {data?.otv_name === null
                                  ? data?.vendor_name
                                  : data?.otv_name}
                              </td>
                              <td>{data?.vendor_gst}</td>
                              <td>{data?.pan_no}</td>
                              <td>{data?.vendor_location}</td>
                              <td>{data?.invoice_number}</td>
                              <td>
                                {moment(data?.invoice_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{data?.expense_name}</td>
                              <td>{data?.gross_amount}</td>
                              <td>
                                <Tooltip
                                  title={`${data?.gl_code}`}
                                  position="bottom"
                                >{`${data?.gl_desc} (${data?.gl_code})`}</Tooltip>
                              </td>
                              {data?.cost_center_id !== null ?
                                <td>
                                  {" "}
                                  <Tooltip
                                    title={`${data?.cost_center_code}`}
                                    position="bottom"
                                  >{`${data?.cost_center_name} (${data?.cost_center_code})`}</Tooltip>
                                </td>
                                :
                                <td></td>
                              }
                              <td>{data?.bank_name}</td>
                              <td>{data?.bank_account_number}</td>
                              <td>{data?.bank_ifsc_code}</td>
                              <td>{data?.sap_doc_no}</td>
                              <td>
                                <Tooltip
                                  title={`${data?.sap_message}`}
                                  position="bottom"
                                >
                                  {data?.sap_message}
                                </Tooltip>
                              </td>
                              <td>{data?.sap_status}</td>
                              <td>
                                {data?.type === "OTV" ? (
                                  <Tooltip
                                    title="OTV Payment Request"
                                    position="bottom"
                                  >
                                    OTV Payment Request
                                  </Tooltip>
                                ) : data?.type === "NONPO" ? (
                                  <Tooltip
                                    title="Non PO Vendor Payment Request"
                                    position="bottom"
                                  >
                                    Non PO Vendor Payment Request
                                  </Tooltip>
                                ) : data?.type === "NONREIMB" ? (
                                  <Tooltip
                                    title="Vendor Invoice Reimb below 5K"
                                    position="bottom"
                                  >
                                    Vendor Invoice Reimb below 5K
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{data?.created_by}</td>
                              <td>
                                {moment(data?.created_at)
                                  .utc()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>No Records Found...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={50} label={50}>
                          50
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                        <option value={200} label={200}>
                          200
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      //   key={pageSizeNo+''+travelList.length}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3">






                    </div>
                  </div>
                </div>
                {/* </Tab>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="start_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      start_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.start_date}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="end_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      end_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.end_date}
                />
              </div>
            </div>
          </div>

          <div class="form-group innergroup">
            <label>Select Type</label>
            <select
              class="form-control newbgselect"
              name="type"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  type: e.target.value,
                })
              }
              value={filterNonOtv.type}
            >
              <option value="">Select</option>
              <option value="OTV">OTV Payment Request</option>
              <option value="NONPO">Non PO Vendor Payment Request</option>
              <option value="NONREIMB">Vendor Invoice Reimb below 5K</option>
            </select>
          </div>
          {/* 
          {currentTab === "otvReport" && (
            <div class="form-group innergroup">
              <label>Request Id</label>
              <Select
                isMulti
                options={reqIdList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => onRequestId(e)}
                value={reqIdValue}
              />
            </div>
          )} */}
          <div class="form-group innergroup">
            <label>Request By</label>
            <Select
              isMulti
              options={reqByNameList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushRequestBy(e)}
              value={reqByNameValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Vednor Name</label>
            <Select
              isMulti
              options={vendorFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                onPushVendorFilter(e);
              }}
              value={vendorFilterValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Invoice No.</label>
            <Select
              isMulti
              options={invoiceFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushInvoiceFilter(e)}
              value={invoiceFilterValue}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>Expenses Category</label>
            <Select
              options={expenseList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushExpensesFilter(e)}
              value={expenseValue}
            />
          </div> */}

          {/* <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  status: e.target.value,
                })
              }
              value={filterNonOtv.status}
            >
              <option value="">Select</option>
              <option value="P">Pending</option>
              <option value="D">Rejected</option>
              <option value="A">Approved</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select SAP Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  sap_status: e.target.value,
                })
              }
              value={filterNonOtv?.sap_status}
            >
              <option value="">Select</option>
              <option value="E">Error</option>
              <option value="S">Success</option>
            </select>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OTVPickList;