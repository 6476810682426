import React, { useState, useEffect } from "react";
// import Logo from "../assets/images/logo.png";
// import Sidemenu from "./include/sidemenu";
// import Header from "./include/header";
import * as moment from "moment";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
} from "react-bootstrap";
import Select from "react-select";
import * as AttendanceService from "../../../service/AttendanceService";
import * as EmpService from "../../../service/employee";
import * as UserService from "../../../service/userService";
import avatar from "../../../assets/images/avatarImg.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { getUserByReporting } from "../../../service/userService";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
  selectUserData,
} from "../../redux/piDataStore";
import { get_sap_schedule } from "../../../service/searchpo";

function AttendanceReport({ bhr }) {
  const dispatch = useDispatch();
  let location = useLocation();
  const userData = useSelector(selectUserData)
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userDetails = useSelector(selectUserData);
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [moduleList, setModuleList] = useState([]);
  const [userList, setUserList] = useState([]);
  // const [filterdata, setfilterData] = useState({
  //   from_date: `${moment(new Date()).format("YYYY")}-${moment(
  //     new Date()
  //   ).subtract(1, 'month').format("MM")}-21`,
  //   to_date: `${moment(new Date()).format("YYYY")}-${moment(new Date())
  //     .format("MM")}-22`,
  //   user_id: 0,
  // });
  const [status, setStatus] = useState([]);
  const statusList = [
    {
      "label": 'Present',
      'value': 'P'
    },
    {
      "label": 'Absent',
      'value': 'A'
    },
    {
      "label": 'Travel',
      'value': 'T'
    },
    {
      "label": 'HQ',
      'value': 'HQ'
    },
    {
      "label": 'COL',
      'value': 'COL'
    },
    {
      "label": 'Week Off',
      'value': 'WO'
    },
    {
      "label": 'Holiday',
      'value': 'HLD'
    },
    {
      "label": 'Privilege Leave',
      'value': 'PL'
    }
  ]
  const [filterdata, setfilterData] = useState({
    from_date: moment(new Date()).format("YYYY-MM-DD"),
    to_date: moment(new Date()).format("YYYY-MM-DD"),
    user_id: 0,
    psa_id: [],
    business_units_id: "",
    status: []
  });
  const [attendanceDataForExport, setAttendanceDataForExport] = useState({
    from_date: "",
    to_date: "",
    user_id: "",
  });
  const [nextSapPostingTime30, setNextSapPostingTime30] = useState('')
  const [nextSapPostingTime90, setNextSapPostingTime90] = useState('')
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [psaVal, setPsaVal] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const countryopt = [
    {
      value: "india +91",
      label: (
        <div>
          <img src="images/flag/ind.png" class="countryimageph" /> +91
        </div>
      ),
    },
    {
      value: "uae +971",
      label: (
        <div>
          <img src="images/flag/uae.png" class="countryimageph" /> +971
        </div>
      ),
    },
    {
      value: "united kindom +44",
      label: (
        <div>
          <img src="images/flag/uk.jpg" class="countryimageph" /> +44
        </div>
      ),
    },
    {
      value: "usa +1",
      label: (
        <div>
          <img src="images/flag/usa.jpg" class="countryimageph" /> +1
        </div>
      ),
    },
    {
      value: "japan +81",
      label: (
        <div>
          <img src="images/flag/jap.jpg" class="countryimageph" /> +81
        </div>
      ),
    },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [importshow, importsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [attendance, setAttendance] = useState([]);
  const [userToList, setUserToList] = useState([]);
  const getUserData = (value) => {
    let flag = bhr === undefined ? false : true;
    let loc = true
    AttendanceService.getAllUserDataNew(value, flag, loc)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.first_name + res?.last_name + "(" + res?.username + ")",
          };
        });
        setModuleList(obj);
        // setModuleList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getUsersByReportingId = async (id) => {
    await getUserByReporting(id, false, "id", true)
      .then((response) => {
        if (response?.status === 200) {
          // let userDropdown = response?.data?.dataList?.result?.map((obj) => {
          //   return {
          //     label: `${obj.first_name} ${obj.last_name}`,
          //     value: obj.id,
          //   };
          // });
          let obj = response?.data?.dataList?.result
            ?.filter((x) => {
              if (x?.is_active === true || x?.is_active === false) {
                return true;
              }
            })
            .map((res) => {
              return {
                value: res?.id,
                label: res?.full_name + "(" + res?.username + ")",
              };
            });
          setModuleList(obj);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getBranchFilter = () => {
    UserService.getBranchFilter()
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAttendance = (
    search,
    from_date,
    to_date,
    user_id,
    psa_id,
    business_units_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    AttendanceService.getAttendanceReport(
      handleKey(
        search,
        from_date,
        to_date,
        user_id,
        psa_id,
        business_units_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        is_admin
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.dataList?.result.filter((obj) => {
        //   if (obj?.attendance?.length > 0) {
        //     return true;
        //   }
        // });

        setAttendance(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // let categories = [];
        // response?.data.map((x) => {
        //   categories.push(x?.catgory);
        // });
        // setCategory(categories);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getExportDetails = (
    search,
    from_date,
    to_date,
    user_id,
    psa_id,
    business_units_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    dispatch(setDisplayLoader("Display"));
    AttendanceService.getExportValue(
      handleKey(
        search,
        from_date,
        to_date,
        user_id,
        psa_id,
        business_units_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        is_admin
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Attendance.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    from_date,
    to_date,
    user_id,
    psa_id,
    business_units_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (psa_id !== "" && psa_id !== undefined && psa_id !== null) {
      queryParm = queryParm + "&psa_id=" + psa_id;
    }
    if (business_units_id !== "" && business_units_id !== undefined && business_units_id !== null) {
      queryParm = queryParm + "&business_units_id=" + business_units_id;
    }
    if (bhr !== undefined) {
      queryParm = queryParm + "&bhr=" + true;
    } else {
      if (is_admin !== "" && is_admin !== undefined && is_admin !== null) {
        queryParm = queryParm + "&is_admin=" + is_admin;
      }
    }
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getAttendance(
        e.target.value.toLowerCase(),
        filterdata.from_date,
        filterdata.to_date,
        filterdata.user_id,
        "",
        "",        
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = (e) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAttendance(
        "",
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getAttendance(
      searchStr,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.user_id,
      filterdata.psa_id,
      filterdata.business_units_id,
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getAttendance(
      searchStr,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.user_id,
      filterdata.psa_id,
      filterdata.business_units_id,
      "",
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };

  const filterShow = () => {
    getUserData(0);
    // if (userDetails?.is_superuser == true) {
    //   getUserData(0);
    // } else {
    //   getUsersByReportingId(userDetails?.id);
    // }
    LocationFilter();
    getBranchFilter();
    filteropen();
  };

  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    dispatch(setDisplayLoader("Display"));
    setAttendanceDataForExport({
      ...attendanceDataForExport,
      from_date: filterdata.from_date,
      to_date: filterdata.to_date,
      user_id: filterdata.user_id,
      status: filterdata?.status
    });
    const from_date = filterdata.from_date;
    const to_date = filterdata.to_date;
    const user_id = filterdata.user_id;
    const location_id = filterdata.psa_id;
    const bu_id = filterdata.business_units_id;
    const status = filterdata?.status;
    getAttendance(
      searchStr,
      from_date,
      to_date,
      user_id,
      location_id,
      bu_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };
  const ModuleListData = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`user${index}`}
        value={`${item?.id}`}
        label={`${item?.first_name} ${item?.last_name} (${item?.username})`}
      />
    ));
  };
  const clearFilter = () => {
    setFilterApplied(false);
    if (
      filterdata.from_date === moment(new Date()).format("YYYY-MM-DD") &&
      filterdata.to_date === moment(new Date()).format("YYYY-MM-DD") &&
      filterdata.user_id === 0
    ) {
      filterClose();
    } else {
      setUserList([]);
      setStatus([]);
      setPsaVal([]);
      setBranchData([]);
      let ClearData = JSON.parse(JSON.stringify(filterdata));
      Object.entries(ClearData).map((type) => {
        if (
          type[1].constructor.name.toLowerCase() === "string" ||
          type[1].constructor.name.toLowerCase() === "array"
        ) {
          if (type[1]?.length > 0) {
            ClearData[type[0]] = "";
          }
        } else {
          if (type[1]?.length > 0) {
            ClearData[type[0]] = "";
          }
        }
      });
      setfilterData(ClearData);
      filterClose();
      const d = new Date();
      let month = d.getMonth();
      let currMonth = month + 1;
      // let from_date = "2023-" + month + "-22";
      // let to_date = "2023-" + currMonth + "-22";
      let from_date = moment(new Date()).format("YYYY-MM-DD");
      let to_date = moment(new Date()).format("YYYY-MM-DD");
      dispatch(setDisplayLoader("Display"));
      getAttendance(
        searchStr,
        from_date,
        to_date,
        0,
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  useEffect(() => {
    if (location?.state?.from === "dashboard") {
      const d = new Date();
      let month = d.getMonth();
      let currMonth = month + 1;
      // let from_date = "2023-" + month + "-22";
      // let to_date = "2023-" + currMonth + "-22";
      let from_date = moment(new Date()).format("YYYY-MM-DD");
      let to_date = moment(new Date()).format("YYYY-MM-DD");
      let user_id = location?.state?.user_id;
      dispatch(setDisplayLoader("Display"));
      getAttendance(
        searchStr,
        from_date,
        to_date,
        user_id,
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    } else {
      const d = new Date();
      let month = d.getMonth();
      let currMonth = month + 1;
      // let from_date = "2023-" + month + "-22";
      // let to_date = "2023-" + currMonth + "-22";
      // let from_date = "2023-04-22";
      // let to_date = "2023-05-22";
      const from_date = filterdata.from_date;
      const to_date = filterdata.to_date;
      const user_id = filterdata.user_id;
      const psa_id = filterdata.psa_id;
      const business_units_id = filterdata.business_units_id;
      dispatch(setDisplayLoader("Display"));
      getAttendance(
        searchStr,
        from_date,
        to_date,
        user_id,
        psa_id,
        business_units_id,
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    }
    getSapSchedule();
  }, [pageSizeNo]);

  const getSapSchedule = () => {
    get_sap_schedule('sync_attendance_from_savior_past_30_days').then((response) => {
      let next_sap_posting_time_30 = response?.data?.dataList?.result
      setNextSapPostingTime30(next_sap_posting_time_30)
    })
    get_sap_schedule('sync_attendance_from_savior_past_90_days').then((response) => {
      let next_sap_posting_time_90 = response?.data?.dataList?.result
      setNextSapPostingTime90(next_sap_posting_time_90)
    })
  }


  return (
    <>
      <div>
        {/* <Sidemenu></Sidemenu> */}
        {/* <Header></Header> */}

        <div class="content-wrapper-inner">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">
                  {bhr !== undefined ? "BHR Attendance Report" : "Attendance"}
                </h4>
                {/* <Breadcrumb>
                  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                  <Breadcrumb.Item active>Attendance</Breadcrumb.Item>
                </Breadcrumb> */}
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      className="bs-tooltip-end tooltip"
                      id="overlay-example"
                    >
                      <div class="tooltip-inner text-left">
                        Search on{" "}
                        <strong>
                          {" "}
                          Shift,
                          <br />
                          Employee Code,
                          <br />
                          Employee Name,
                          <br />
                          Manager,
                          <br />
                          Status
                        </strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search on Employee Code, Employee Name..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={(e) => {
                            closeButtonCallBack(e);
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {/* {finalUrlValue?.D ? ( */}
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={attendance?.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              filterdata.from_date,
                              filterdata.to_date,
                              filterdata.user_id,
                              filterdata.psa_id,
                              filterdata.business_units_id,
                              filterdata.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* ) : (
                    ""
                  )} */}
                  <button
                    onClick={() => {
                      filterShow();
                    }}
                    class="btn btn-secondary-inner"
                    style={{
                      backgroundColor: filterApplied && "yellow",
                      color: filterApplied && userData?.dark_mode === false && "#000"
                    }}
                  >
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <b style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF" }}>Next Attendance Sync 30 Days:</b> <span className="mr-3" style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF" }}>{moment.utc(nextSapPostingTime30).format('DD-MM-YYYY hh:mm A')}</span>
                <b style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF" }}>Next Attendance Sync 90 Days:</b> <span className="mr-3" style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF" }}>{moment.utc(nextSapPostingTime90).format('DD-MM-YYYY hh:mm A')}</span>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>Employee Code</th>
                  {/* {userDetails?.is_hr === true && ( */}
                  <>
                    <th>Employee Name</th>
                    <th>Manager</th>
                  </>
                  {/* )} */}
                  <th
                    onClick={(e) => {
                      handlerData("shift");
                    }}
                    className={
                      sortData["shift"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["shift"]
                    }
                  >
                    Shift
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("attendance_date");
                    }}
                    className={
                      sortData["attendance_date"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["attendance_date"]
                    }
                  >
                    Date
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("status");
                    }}
                    className={
                      sortData["status"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["status"]
                    }
                  >
                    Status
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("in_time");
                    }}
                    className={
                      sortData["in_time"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["in_time"]
                    }
                  >
                    In Time
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("out_time");
                    }}
                    className={
                      sortData["out_time"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["out_time"]
                    }
                  >
                    Out Time
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("in_address");
                    }}
                    className={
                      sortData["in_address"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["in_address"]
                    }
                  >
                    Working Hours
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("out_address");
                    }}
                    className={
                      sortData["out_address"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["out_address"]
                    }
                  >
                    Std. Working Hours (Minutes)
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendance?.length ? (
                  attendance?.map((data, index) => (
                    <>
                      <tr>
                        <td class="">{data?.employee_code}</td>
                        {/* {userDetails?.is_hr === true && ( */}
                        <>
                          <td class="text-theme fw-bold">
                            <div class="d-flex align-items-center">
                              <div>
                                <div class="team-profile">
                                  {/* <img src="images/pro-userpic.jpg" alt="" /> */}

                                  {/* <AvatarGroup total={data.image.length}> */}
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={
                                      data?.image === "" ? avatar : `data:image/gif;base64,${data?.image}`
                                    }
                                  />
                                </div>
                              </div>
                              <div class="teamtxt">
                                <h5 class="mb-0 text-dark">{data?.fullname}</h5>
                                {/* <p>{data?.email}</p> */}
                              </div>
                            </div>
                          </td>
                          <td>{data?.manager_name}</td>
                        </>
                        {/* )} */}
                        <td>{data?.shift}</td>
                        <td>
                          {moment
                            .utc(data?.attendance_date)
                            .format("DD-MM-YYYY")}<br />
                          ({moment.utc(data?.attendance_date).format('dddd')})
                        </td>

                        <td>
                          {data?.status == "P" ? (
                            <span class="badge bad-status badge-success">
                              P
                            </span>
                          ) : data?.status == "A" || data?.status == "HLF" ? (
                            <span class="badge bad-status badge-danger">
                              {data?.status}
                            </span>
                          ) : data?.status == "MIS" ? (
                            <span class="badge bad-status badge-danger">
                              MIS
                            </span>
                          ) : data?.status == "L" ? (
                            <span class="badge bad-status badge-primary">
                              L
                            </span>
                          ) : data?.status == "OL" ? (
                            <span class="badge bad-status badge-primary">
                              {/* Occasional Leave */}
                              OL
                            </span>
                          ) : data?.status == "PL" ? (
                            <span class="badge bad-status badge-primary">
                              {/* Privilege Leave */}
                              PL
                            </span>
                          ) : data?.status == "WO" ? (
                            <span class="badge bad-status badge-secondary">
                              {/* Privilege Leave */}
                              WO
                            </span>
                          ) : (
                            <span class="badge bad-status badge-success">
                              {/* Week-Off */}
                              {data?.status}
                            </span>
                          )}
                        </td>
                        <td>{data?.in_time}</td>
                        <td>{data?.out_time}</td>
                        <td>{data?.working_hours}</td>
                        {data?.std_working_hours?.length > 0 ? (
                          <td>{data?.std_working_hours[0]?.shiftduration}</td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {attendance?.length == 0 ? (
            ""
          ) : (
            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control main-pointer"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              <div className="row ml-3 mt-1">
                <div className="col-md-12" style={{
                  color: userDetails?.dark_mode === false && "#FFF"
                 }}>
                  <p>Notes:-</p>
                  <ul className="ml-3">
                    <li>
                      PRESENT --{" "}
                      <span>
                        P = Present; POW = Present On Weekly off; POH = Present
                        On Holiday; WO = Weekly Off; OD/T = On Duty/Tour
                      </span>
                    </li>
                    <li>
                      ABSENT -- A = Absent; A1 = Absent or leave before & after
                      weekly off; LWP = Leave Without Pay
                    </li>
                    <li>
                      LEAVE -- COL = Contingency Leave; PL = Privilege Leave; H_
                      = Half Leave
                    </li>
                    <li>MISC -- HLD = Holiday; MIS = Missing punch entry</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        backdrop="static"
        keyboard={false}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger"></span>
            </label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  from_date: e.target.value,
                }))
              }
              value={filterdata?.from_date}
              max={moment(filterdata.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger"></span>
            </label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  to_date: e.target.value,
                }))
              }
              value={filterdata?.to_date}
              min={moment(filterdata.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Absent Since<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>1 Day</option>
              <option>2 Day</option>
              <option>3 Day</option>
              <option>4 Day</option>
              <option>5 Day</option>
            </select>
          </div> */}
          <div class="form-group innergroup">
            <label>
              Select Employee<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={moduleList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Employee"
              value={userList}
              onChange={(e) => {
                setUserList(e);
                setfilterData({
                  ...filterdata,
                  user_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
            {/* <select
              className="form-control newbgselect main-pointer"
              name="module_parent_id"
              type="text"
              onChange={(e) => {
                setfilterData({
                  ...filterdata,
                  user_id: e.target.value,
                });
              }}
              value={filterdata?.user_id}
            >
              <option value="" label="Select Employee" />
              <option value={0} label="Self Attendance" />
              {ModuleListData(moduleList)}
            </select> */}
          </div>
          <div className="form-group innergroup">
                <label>
                  Select Location<span class="text-danger"></span>
                </label>
                <Select
                  isMulti
                  name="sub_personal_area"
                  options={subPersonalArea}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    setPsaVal(e);
                    let psa_data = e?.map((value) => {
                      return value.value;
                    });
                    setfilterData({
                      ...filterdata,
                      psa_id: psa_data,
                    })

                  }}
                  value={psaVal}
                />
              </div>
              <div className="form-group innergroup">
                <label>Business Unit</label>
                <select
                  className="form-control newbgselect"
                  name="business_units_id"
                  onChange={(e) => {
                    setfilterData({
                      ...filterdata,
                      business_units_id: e.target.value,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  value={filterdata?.business_units_id}
                >
                  <option value="" label="Select Business Unit" disabled />
                  {renderBranchOptions(branchData)}
                </select>
              </div>    
          <div class="form-group innergroup">
            <label>
              Status<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={statusList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Status"
              value={status}
              onChange={(e) => {
                setStatus(e);
                setfilterData({
                  ...filterdata,
                  status: e
                    .map((obj) => {
                      return obj.value;
                    }).toString()
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Employee Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Employee Code"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Email Id<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email Id"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Mobile No.</label>
                    <div class="mobile-country-drop">
                      <div class="row">
                        <div class="col-5 pr-0">
                          <Select
                            options={countryopt}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="+91"
                            defaultValue={[countryopt[0]]}
                          />
                        </div>
                        <div class="col-7 pl-0">
                          <input
                            type="number"
                            class="form-control nonumbdrop"
                            placeholder="Enter Mobile No."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Department<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Designation<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Branch<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Telephone Ext.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Telephone Ext."
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Sequence<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Sequence"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Masking Mobile<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Masking Email<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label className="d-block">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4 mt-2">
                      Active
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button class="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="far fa-file-excel"></i> DOWNLOAD TEMPLATE
                    </button>
                  </div>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                  />
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i class="far fa-times-circle"></i>
                  </span>
                  <button class="btn btn-primary-inner">
                    Proceed to Upload
                  </button>
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i class="far fa-times-circle"></i>
                  </span>
                  <button class="btn btn-primary-inner" disabled>
                    Proceed to Upload
                  </button>
                  <div class="w-75 mt-3">
                    <ProgressBar
                      striped
                      variant="success"
                      animated
                      now={45}
                      label="40%"
                    />
                  </div>
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                  <p>
                    The system was unable to read few records from the uploaded
                    file.
                    <br />
                    Please correct or remove those records from the file and
                    upload again.
                  </p>
                  <span class="badge bad-status mb-3 badge-success">
                    Records Succeded: 230
                  </span>
                  <span class="badge bad-status mb-3 badge-danger">
                    Records Failed: 50
                  </span>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="fas fa-chevron-circle-left"></i> Go Back
                    </button>
                  </div>
                </div>

                <table class="table uploadrecordstab">
                  <thead>
                    <tr>
                      <th>Upload Date</th>
                      <th>Uploaded By</th>
                      <th>File Name</th>
                      <th>Records Uploaded</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AttendanceReport;
