import React, { useState, useEffect, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import * as MenuItem from "../../service/sidemenu";
import { connect } from "react-redux";
import * as ActionTypes from "../../store/actions";
import { useSelector } from "react-redux";
import {
  selectPixelData,
  setUserData,
  selectUserData,
} from "../../pages/redux/piDataStore";
import { loginRequest } from "../../Config";
import { useMsalAuthentication } from "@azure/msal-react";
import * as userService from "../../service/userService";
import moment from "moment";
// import {ApproveCashbookModules} from "../CashbookProfile/ApproveCashbookModules"
import {
  Header,
  Sidemenu,
  Dashboard,
  CityIndex,
  CountryIndex,
  StateIndex,
  ModuleMaster,
  SubModule,
  CMSNews,
  Capex,
  MdoPage,
  RoleMaster,
  User,
  Department,
  SubDeptGrid,
  Designation,
  Business,
  Organization,
  EmployeeDir,
  Employee,
  PolicyCategory,
  PolicySubCategory,
  BrandingCategory,
  Policy,
  Hrpolicy,
  Branding,
  Transaction,
  ActionPo,
  ApproverAssignment,
  SearchPO,
  ViewPO,
  SurveyApproval,
  ViewSurveyform,
  ViewPoSearch,
  Analytics,
  Jv,
  Addjv,
  Survey,
  AddSurvey,
  SurveySetting,
  Myidea,
  ShareIdea,
  IdeaLeads,
  Event,
  ViewJV,
  JvRmApprovalMaster,
  ReleaseGroup,
  ReleaseStrategy,
  ApprovalData,
  ReleaseCode,
  ReleaseStrat,
  MyLeave,
  ViewActivePO,
  Reservation,
  SourceApprover,
  SourceList,
  SourceListAll,
  CommonApprover,
  ViewSource,
  MyLeaveApp,
  SkillMaster,
  HolidayMaster,
  MyTeamIdea,
  SkillGroupMapping,
  Delegate,
  AssignBspoc,
  ShiftMaster,
  PurchaseOrganisation,
  PurchaseGroup,
  DocumentTypeIndex,
  SkillGroup,
  ChangeShiftIndex,
  ShiftApproval,
  TravelIntimation,
  TravelListing,
  ExpenseClaim,
  ClaimListing,
  Skill,
  SkillReport,
  SkillLocation,
  NpsDeclaration,
  NpsReport,
  LoanApproval,
  LoanApply,
  LoanReport,
  SalaryApprover,
  SkillUser,
  VendorInvoice,
  FaqMaster,
  FaqDashboard,
  HelpDesk,
  ApproveCmsModule,
  SkillQuaterly,
  SkillTab,
  ServiceApprover,
  ServiceEntryListAll,
  ServiceEntryList,
  ServiceEntrySearch,
  ApproveService,
  GIMapped,
  GIBalancesheet,
  GIApprovral,
  ApproveGI,
  CREInstrument,
  ActiveContract,
  // LRGR portal components
  TransportRoot,
  TransportTable,
  BillRoot,
  BillTable,
  TAdmin,
  LRGRPlant,
  LRGRTransporter,
  LRGRSubTransporterRoot,
  LRGRSubTransporterTable,
  LRGRSummary,
  // Agri portal components
  MarketSizeRoot,
  MarketSizeTable,
  AGSalesRoot,
  AGSalesTable,
  ETReviewTable,
  ETReviewRoot,
  MarketPlayer,
  MarketPlayerTable,
  MajorProducts,
  MajorProductsTable,
  CropPattern,
  CropPatternTable,
  CropScenario,
  CropScenarioTable,
  ChannelSize,
  ChannelSizeTable,
  CompetitionBrandMapping,
  CompetitionBrandMappingTable,
  CompetitionMoleculeMapping,
  CompetitionMoleculeMappingTable,
  CropPlan,
  CropPlanTable,
  SalesPlan,
  SalesPlanTable,
  DetailedSales,
  DetailedSalesPlan,
  CropProductStrategy,
  CropProductStrategyTable,
  FieldWork,
  FieldWorkTable,
  ExpensePlan,
  ExpensePlanTable,
  AddRole,
  AutoRoleAssign,
  GlApprover,
  GlCostCenter,
  PRlist,
  PRApproval,
  PRplantMaster,
  PROrgMaster,
  PRTypeMaster,
  PRUnitMaster,
  PRPurchMaster,
  PRreports,
  PRUserMaster,
  SyncPR,
  UploadSigma,
  SkillUsage,
  UserPlantMapping,
  ContractApproval,
  UserLeaveAllocation,
  ViewCa,
  ApproveEssModule,
  DetailedSalesPlanTable,
  OverallStrategy,
  OverallStrategyTable,
  PopPlanner,
  PopPlannerTable,
  FieldWorkPlanner,
  FieldWorkPlannerTable,
  MediclaimDecl,
  EmployeeInsurance,
  MediclaimReport,
  UploadPremium,
  MaterialRequest,
  AddNewMater,
  MaterialExtension,
  AddMatExt,
  UpdateCAS,
  MatHSN,
  MatExtApproval,
  HSNCodeApproval,
  PowerUser,
  AuthorizeUser,
  MatDescrp,
  RefMat,
  MatApproval,
  AppMaterReq,
  ViewContractSearch,
  CreatePR,
  ViewPR,
  CashExpenses,
  HigherEdu,
  HigherEduApplication,
  HigherEduListing,
  HigherEduApproval,
  HigherEduClaim,
  HigherEduAddClaim,
  HigherEduClaimDetail,
  HigherEduClaimListing,
  HigherEduClaimApproval,
  HigherEduReport,
  ViewExpenes,
  CashbookReceipt,
  CashbookVendors,
  CashDemand,
  CashbookApproval,
  CashReports,
  OTVRequest,
  NonPORequest,
  VendorreimRequest,
  VendorPayReq,
  OTVApproval,
  MediclaimPremium,
  MediclaimPremiumTopUp,
  LeaveBackDate,
  UserDetails,
  ViewEmployee2,
  NotFound,
  OTHOur,
  GST,
  CompanyDoc,
  GSTView,
  CompanyDocView,
  SuccessFactor,
  // ApproveCashbookModules,
  ManageExpenseGrid,
  CashbookBeneficial,
  BulkUploadLeave,
  OTVApprover,
  MediBroker,
  ApproveCashbookModules,
  AddOtherExpense,
  OtherExpListing,
  ViewExpenTravInt,
  ViewTravelInt,
  SustainPolicy,
  RollingPlan,
  RollingPlanTable,
  DispatchPlan,
  DispatchPlanTable,
  ViewOtvNonpo,
  TravelListingApproval,
  LeaveReport,
  PermissionDenied,
  SurveyAsst,
  SurveyPoll,
  SurveySurveys,
  SurveyResult,
  Viewassess,
  // ViewExternalassess,
  ViewSurveyUserResponse,
  ViewSurvey,
  ViewTravelList,
  ExpenseViewSap,
  ClaimApp,
  NoticePeriodApprover,
  EmpRefReimbursement,
  FbpReportComp,
  UserDeclaration,
  ReservationReport,
  SearchCA,
  ViewCaSearch,
  ReimbursementApproval,
  ReimburseRecoveryReport,
  UserGlMapping,
  TravelBackDateEntry,
  OtherExpenseListingSap,
  ApplyOtherAdvance,
  MyOtherAdvance,
  OtherAdvanceApproval,
  ViewPrintPage,
  ReimbursementDec,
  ReimbursementDecReport,
  UserCertificationReport,
  NPSUserGuide,
  ReimbursementReport,
  CardMappingIndex,
  AssignGiIndex,
  AddNewVehicleIndex,
  AssignNewVehicleIndex,
  VehicleHistory,
  ViewTravelExpenseData,
  SurveyFeedback,
  DepartmentMaster,
  PlantMaster,
  SubGroupMaster,
  EssPolicyMaster,
  VehicalReg,
  VehicleExpense,
  VehicalReqHistory,
  VehicalInsurance,
  VehicalInsuranceAll,
  VehiclePickInsurance,
  VehicleExpenseInsurance,
  VehicleCheckInsurance,
  VehicalInsuranceHistoryInsurance,
  BackDateListing,
  TravelAdvanceAccess,
  TravelAdvanceAccessListing,
  TravelLogAmount,
  ApproveOtherAdv,
  AllOtherAdvance,
  JivagroAddVehicalAll,
  VehicleRegistrationReport,
  VehicleProjectedInsurance,
} from "../index";
import PrivateRoute from "../privateroute/index";
import {
  setDashboardValue,
  setFinalUrlPermission,
  setDisplayLoader,
  setSortedModuleData,
} from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import Brand from "../roleRightMaster/BrandMaster/Brand";
import LeaveManage from "../LeaveMaster/LeaveManage";
import LeaveAllocation from "../LeaveMaster/LeaveAllocation";
import SurveyCategory from "../company/SurveyCategory";
import { useNavigate } from "react-router-dom";
import BalanceSheet from "../module/glbalancesheet";
import Attendance from "../roleRightMaster/UserMaster/Attendance";
import * as HeaderService from "../../service/header";
import ChatGpt from "../faq/ChatGPT";
import PFVoluntary from "../VoluntaryPF/voluntary-pf";
import ContractAssigment from "../ContractApproval/ContractAssignment";
import CompAllMast from "../ShiftMaster/comp-off-master";
import VPFOpenDeclaration from "../VoluntaryPF/vpf-open-decl";
import CompAll from "../ShiftMaster/comp-off-allowance";
import IdeaImplementation from "../idea/ideaImplementation";
import { useMsal } from "@azure/msal-react";
import TravelPickup from "../TravelMaster/travel-pick";
import ApproveTravelExpense from "../TravelMaster/ApproveTravelExpense";
import TravelUnderProcessList from "../TravelMaster/Travelunderprocesslist";
import PFVoluntaryReport from "../VoluntaryPF/vpfreport";
import NPSOpenDeclaration from "../NpsDeclaration/nps-open-decl";
import Traveldiscardtopicklist from "../TravelMaster/Traveldiscardtopicklist";
import Otherexpdiscardtopicklist from "../OtherExpense/Otherexpdiscardtopicklist";

import TravelExpense from "../TravelMaster/travel-expense";
import TravelDeviation from "../TravelMaster/Travel-deviation";
import TravelCheckExpens from "../TravelMaster/travel-check-expense";
import AttendanceReport from "../roleRightMaster/UserMaster/attendance-report";
import TravelBills from "../TravelMaster/travel-bills";
import EmpListReport from "../TravelMaster/EmpListReport";
import TravelKmreport from "../TravelMaster/travel-km-report";
import TravelIntimreport from "../TravelMaster/travel-intimation-report";
import TravelIntimDetailreport from "../TravelMaster/travel-intimation-detailreport";
import NoticeReimbursement from "../ClaimReimb/NoticeReimb";
import TravelHotelExpReport from "../TravelMaster/travel-hotel-expense-report";
import TravelConveyanceExpReport from "../TravelMaster/travel-conveyance-expense-report";
import TravelProvisReport from "../TravelMaster/travel-provis-report";

import TravelFcmExpReport from "../TravelMaster/travel-fcm-ticket-expense-report";
import ApproveTravelModules from "../TravelMaster/ApproveTravelModules";
import PrReportSap from "../PrModule/PrReportSap";
import AuthorizeUserMaster from "../MaterialCreation/authrize-user-backup";

import ViewHSNApproval from "../MaterialCreation/view-hsn-approval";
import POApprovalReport from "../searchpo/po-approval-report";
import CustomRoleAssign from "../roleManagement/CustomRoleAssign";
import SyncSes from "../Ses/sync-ses";
import SyncPO from "../searchpo/sync-po";
import IdeaApproval from "../idea/idea-approval";
import SyncMaterial from "../MaterialCreation/sync-material";
import OTVPickList from "../OTVPayment/otv_non_po_pick_list";
import { getCourses } from "../../service/mandatory";
import { getCourseList } from "../../service/dashboard";
import ViewOtherExpense from "../TravelMaster/viewOtherExpense";
import VehiclePick from "../Vehicle2/vehicle-pick";
import VehicleCheck from "../Vehicle2/vehicle-check";
import OtherExpPick from "../OtherExpense/otherexp-pick";
import OtherExpPrintview from "../OtherExpense/printView";

import OtherExpCheck from "../OtherExpense/otherexp-check";
import OtherExpCheckExpens from "../OtherExpense/otherexp-check-expense";
import OtherExpUnderProcess from "../OtherExpense/otherexp-under-process-list";
import OtherExpDetailReport from "../OtherExpense/otherexp-detailreport";
import OtherExpBHRReport from "../OtherExpense/otherexp-bhrreport";
import OtherExpAllReq from "../OtherExpense/otherexp-allrequests";
import OtherExpFarmerGLReport from "../OtherExpense/otherexp-farmercode";
import OtherExpMachineGLReport from "../OtherExpense/otherexp-glcode";
import AddOtherExpEntryException from "../TravelMaster/AddOtherExpExceptionMaster";
import ListOtherExpEntryException from "../TravelMaster/ListOtherExpExceptionMaster";
import TravelProcessedTa from "../TravelMaster/travel-processed-ta";
import ViewOtherAdvance from "../TravelMaster/ViewOtherAdvance";
import PrintVehicleCheck from "../Vehicle2/PrintVehicleCheck";
import PrintVehicleInsuranceCheck from "../Vehicle2/PrintVehicleIsuranceCheck";
import VehicleInsuranceReport from "../Vehicle2/VehicleInsuranceReport";
import VehicleReport from "../Vehicle2/VehicleReport";
import AbscondingReport from "../roleRightMaster/UserMaster/absconding-report";
import Visitor from "../transaction/visitor";
import VisitorMasterManagement from "../transaction/visitor-master-management";
import CashbookWiseExpenseReport from "../CashbookProfile/cashbook-wise-expense-report";
import VisitingSite from "../transaction/visiting-site";
import CanteenManagement from "../cms-news/canteen-management";
import CanteenMaster from "../cms-news/canteen-master-management";
import ReimbOpenDeclaration from "../ClaimReimb/reimbursement-open-decl";
import VisitingSiteMaster from "../transaction/visiting-site-master";
import UserRole from "../UserRole/UserRole";

// import TravelPickup from "../TravelMaster/travel-pick";
// import OTHOur from "../OTHours/ot_Hours"
const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  data: state.auth.isLoggedIn,
});
function Home({ onRightsUpdate, profileStatus, data, userLogin }) {
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const { result, error } = useMsalAuthentication();
  const { accounts, inProgress, instance } = useMsal();
  const pathname = window.location.pathname;
  const [myRights, setMyRights] = useState({});
  const userDataValue = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getSideMenu = async () => {
    let deletegatedLogin =
      localStorage?.getItem("delegatedLogin") === null ? false : true;
    let initialUser =
      localStorage?.getItem("initialUser") === null
        ? ""
        : localStorage?.getItem("initialUser");
    MenuItem.getSideMenu(deletegatedLogin, initialUser)
      .then((response) => {
        const data = response?.data?.dataList;
        let resultData = response?.data?.dataList?.result;
        setMyRights(data);
        dispatch(setDisplayLoader("Hide"));
        dispatch(setDashboardValue(data.result));
        dispatch(setSortedModuleData(data.module_id_sorting));
        const ll = {};
        for (let kk in resultData) {
          if (resultData[kk].length > 0) {
            resultData[kk].forEach((element) => {
              ll[element.righturl] = element.id;
              if (element.have_child) {
                element.child.forEach((element) => {
                  ll[element.righturl] = element.id;
                });
              }
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 403) {
          window.localStorage.removeItem("accesstoken");
          navigate("/custom", { replace: true });
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }
      });
  };
  const getUserDetails = async (id) => {
    if (!userDataValue.id) {
      dispatch(setDisplayLoader("Display"));
      HeaderService.getUserDetails(id)
        .then((response) => {
          getSideMenu();
          dispatch(setUserData(response?.data?.dataList));
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const getPermissionUrlData = async (data) => {
    HeaderService.getPermissionUrlData(data)
      .then((response) => {
        let data = response?.data?.dataList;
        // if (data.length === 0) {
        //   navigate("/deniedPermission");
        // } else {
        if (!userDataValue.id) {
          getUserDetails(0);
        }
        dispatch(setFinalUrlPermission(data));
        // }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };

  useEffect(() => {
    if (data === false) {
      if (localStorage.getItem("fromAd") === "true") {
        localStorage.clear();
        sessionStorage.clear();
        handleLogout("redirect");
      } else {
        localStorage.removeItem("accesstoken");
        localStorage.removeItem("isTemporary");
        window.location.replace("/custom");
      }
    }
  }, [data]);

  const adLogin = () => {
    // alert("login side");
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        localStorage.setItem("accesstoken", response?.accessToken);
        localStorage.setItem("expiry", response?.expiresOn);
        localStorage.setItem("isTemporary", "false");
        localStorage.setItem("fromAd", true);
        userLogin(response?.accessToken, "false");
        saveAdUserToken(
          response?.accessToken,
          response?.account?.idTokenClaims?.userid
        );
        // window.location.replace("/dashboard");
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          localStorage.setItem("accesstoken", response?.accessToken);
          localStorage.setItem("expiry", response?.expiresOn);
          localStorage.setItem("isTemporary", "false");
          localStorage.setItem("fromAd", true);
          userLogin(response?.accessToken, "false");
          saveAdUserToken(
            response?.accessToken,
            response?.account?.idTokenClaims?.userid
          );
          // window.location.replace("/dashboard");
        });
      });
  };

  const saveAdUserToken = async (token, username) => {
    let obj = {
      token: token,
      username: username,
      client_secret: clientSecret,
    };
    await userService
      .saveAdUserToken(obj)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setInterval(() => {
      if (
        localStorage?.getItem("expiry") !== null &&
        moment(localStorage?.getItem("expiry")).diff(
          moment(new Date()),
          "minutes"
        ) <= 20
      ) {
        adLogin();
      }
    }, 120000);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getCourseListForUser();
    }, [1000 * 60 * 60 * 12]);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      localStorage.setItem(
        "lastSkippedCourse",
        moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      );
      getCourseListForUser();
    }, [12 * (60 * 60) * 1000]);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getCourseListForUser = async () => {
    await getCourseList()
      .then((response) => {
        let res = response?.data?.dataList;
        if (
          (res?.cyber === true && res?.posh === true && res?.coc === true) ||
          response?.data?.success === false ||
          response?.data?.code === 401
        ) {
          // window.location.replace("/dashboard");
          return;
        } else {
          window.location.replace("/mandatory");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (
      pathname === "/dashboard" ||
      pathname === "/deniedPermission" ||
      pathname === "/denied" ||
      pathname.substring(0, 7) === "/common" ||
      pathname.substring(0, 5) === "/view" ||
      pathname === "/addjv" ||
      pathname === "/myteamidea"
    ) {
      getUserDetails(0);
    } else {
      getPermissionUrlData(pathname);
    }
  }, [pathname]);

  let template = (
    <>
      <PrivateRoute>
        <div className="container-fluid" onClick={profileStatus}>
          <div className="row">
            <Sidemenu myRights={myRights}></Sidemenu>
            <div className="col-md-10 col-lg-11" id="tabBody">
              <Header myRights={myRights}></Header>
              <Routes>
                <Route path="/dashboard" exact element={<Dashboard />} />
                <Route
                  path="/attendance/:userId?"
                  exact
                  element={<Attendance />}
                />
                <Route path="/cms-news" exact element={<CMSNews />} />
                <Route path="/capex-user" exact element={<Capex />} />
                <Route path="/add-user-role" exact element={<UserRole />} />
                <Route path="/mdo-user" exact element={<MdoPage />} />
                <Route path="/role" exact element={<RoleMaster />} />
                <Route path="/user" exact element={<User />} />
                <Route path="/userdetails" exact element={<UserDetails />} />
                <Route path="/department" exact element={<Department />} />
                <Route path="/subdepartment" exact element={<SubDeptGrid />} />
                <Route path="/designation" exact element={<Designation />} />
                <Route path="/bu" exact element={<Business />} />
                <Route path="/organisation" exact element={<Organization />} />
                <Route path="/module" exact element={<ModuleMaster />} />
                <Route path="/sub-module" exact element={<SubModule />} />
                <Route path="/country" exact element={<CountryIndex />} />
                <Route path="/city" exact element={<CityIndex />} />
                <Route path="/state" exact element={<StateIndex />} />
                <Route path="/vehicle-req" exact element={<VehicalReg />} />
                <Route
                  path="/vehicle_req_all"
                  exact
                  element={<JivagroAddVehicalAll />}
                />
                <Route
                  path="/vehicle-history"
                  exact
                  element={<VehicalReqHistory />}
                />
                <Route
                  path="/vehicle-insurance"
                  exact
                  element={<VehicalInsurance />}
                />
                <Route
                  path="/vehicle_insurance_all"
                  exact
                  element={<VehicalInsuranceAll />}
                />
                <Route path="/employee-dir" exact element={<Employee />} />
                <Route path="/employee" exact element={<ViewEmployee2 />} />
                <Route path="/event" exact element={<Event />} />
                <Route path="/event" exact element={<Event />} />
                <Route path="/reimb-dec" exact element={<ReimbursementDec />} />
                <Route
                  path="/reimb-open-decl"
                  exact
                  element={<ReimbOpenDeclaration />}
                />
                <Route
                  path="/reimb-dec-report"
                  exact
                  element={<ReimbursementDecReport />}
                />
                <Route
                  path="/approve-service"
                  exact
                  element={<ApproveService />}
                />
                <Route
                  path="/common-approve/:id"
                  exact
                  element={<CommonApprover />}
                />
                <Route path="/approve-source" exact element={<ViewSource />} />
                <Route
                  path="/policy-category"
                  exact
                  element={<PolicyCategory />}
                />
                <Route
                  path="/policy-subcategory"
                  exact
                  element={<PolicySubCategory />}
                />
                <Route
                  path="/branding-category"
                  exact
                  element={<BrandingCategory />}
                />
                <Route path="/policy" exact element={<Policy />} />
                {/* <Route
                  path="/cashbookBeneficary"
                  exact
                  element={<CashbookBeneficial />}
                /> */}
                <Route path="/brand" exact element={<Brand />} />
                <Route path="/userpolicy" exact element={<Hrpolicy />} />
                <Route path="/branding" exact element={<Branding />} />
                {/* <Route path="/purchase-order" exact element={<Transaction />} /> */}
                <Route path="/active-po" exact element={<ActionPo />} />
                <Route
                  path="/approver-assignment"
                  exact
                  element={<ApproverAssignment />}
                />
                <Route
                  path="/service-approver"
                  exact
                  element={<ServiceApprover />}
                />
                <Route path="/search-po" exact element={<SearchPO />} />
                <Route
                  path="/po-approval"
                  exact
                  element={<POApprovalReport />}
                />
                <Route path="/viewpurchase/:id" exact element={<ViewPO />} />
                <Route path="/viewposearch" exact element={<ViewPoSearch />} />
                <Route path="/viewactivepo" exact element={<ViewActivePO />} />
                <Route path="/analytics" exact element={<Analytics />} />
                <Route path="/jv-report" exact element={<Jv />} />
                <Route path="/jv-approval" exact element={<Jv />} />
                <Route path="/jv" exact element={<Jv />} />
                <Route path="/addjv" exact element={<Addjv />} />
                <Route path="/survey" exact element={<Survey />} />
                <Route
                  path="/contract-po"
                  exact
                  element={<ContractApproval />}
                />
                <Route
                  path="/active-contract"
                  exact
                  element={<ActiveContract />}
                />
                <Route
                  path="/contract-assignment"
                  exact
                  element={<ContractAssigment />}
                />
                <Route
                  path="/view-contract-search"
                  exact
                  element={<ViewContractSearch />}
                />
                <Route
                  path="/view-contract-search"
                  exact
                  element={<ViewContractSearch />}
                />
                <Route path="/survey-type" exact element={<SurveyCategory />} />
                <Route path="/addsurvey" exact element={<AddSurvey />} />
                <Route
                  path="/surveysetting"
                  exact
                  element={<SurveySetting />}
                />
                <Route
                  path="/survey-assessment"
                  exact
                  element={<SurveyAsst />}
                />
                <Route path="/survey-polls" exact element={<SurveyPoll />} />
                <Route
                  path="/survey-feedback"
                  exact
                  element={<SurveyFeedback />}
                />
                <Route
                  path="/survey-surveys"
                  exact
                  element={<SurveySurveys />}
                />
                <Route
                  path="/survey-approvals/:id"
                  exact
                  element={<SurveyApproval />}
                />
                <Route
                  path="/viewsurveyform/:id"
                  exact
                  element={<ViewSurveyform />}
                />
                <Route path="/survey-result" exact element={<SurveyResult />} />
                <Route
                  path="/viewmyassignment/:surveyId/:view"
                  exact
                  element={<Viewassess />}
                />
                {/* <Route path="/viewassignment" exact element={<ViewExternalassess />} /> */}
                <Route
                  path="/viewsurvey_userresponse"
                  exact
                  element={<ViewSurveyUserResponse />}
                />
                <Route path="/viewsurvey" exact element={<ViewSurvey />} />
                <Route path="/myidea" exact element={<Myidea />} />
                <Route path="/source-list" exact element={<SourceList />} />
                <Route
                  path="/source-list-all"
                  exact
                  element={<SourceListAll />}
                />
                <Route
                  path="/source-list-approval"
                  exact
                  element={<SourceListAll />}
                />

                <Route
                  path="/source-approver"
                  exact
                  element={<SourceApprover />}
                />
                <Route
                  path="/service-entry-sheet"
                  exact
                  element={<ServiceEntryList />}
                />
                <Route
                  path="/service-entry-all"
                  exact
                  element={<ServiceEntryListAll />}
                />
                <Route
                  path="/service-entry-approval"
                  exact
                  element={<ServiceEntryListAll />}
                />
                <Route
                  path="/ses-search"
                  exact
                  element={<ServiceEntrySearch />}
                />
                <Route path="/shareidea" exact element={<ShareIdea />} />
                <Route path="/idealead" exact element={<IdeaLeads />} />
                <Route path="/myTeamIdea" exact element={<MyTeamIdea />} />
                <Route path="/viewjv/:id" exact element={<ViewJV />} />
                <Route path="/jvRm" exact element={<JvRmApprovalMaster />} />
                <Route path="/releasegroup" exact element={<ReleaseGroup />} />
                <Route path="/releasecode" exact element={<ReleaseCode />} />
                <Route
                  path="/releasestrategy"
                  exact
                  element={<ReleaseStrategy />}
                  Apply
                  Leave
                />
                <Route path="/releasestrat" exact element={<ReleaseStrat />} />
                <Route path="/approveTable" exact element={<ApprovalData />} />
                <Route path="/manage-leave" exact element={<LeaveManage />} />
                <Route path="/leave-rule" exact element={<LeaveAllocation />} />
                <Route path="/my-leave" exact element={<MyLeave />} />
                <Route path="/ot-hour" exact element={<OTHOur />} />
                <Route path="/comp-off" exact element={<CompAllMast />} />
                <Route path="/comp-allowance" exact element={<CompAll />} />
                <Route
                  path="/mediclaim-broker"
                  exact
                  element={<MediBroker />}
                />
                <Route
                  path="/leave-backdate"
                  exact
                  element={<LeaveBackDate />}
                />
                <Route
                  path="/holiday-master"
                  exact
                  element={<HolidayMaster />}
                />
                <Route path="/reservation" element={<Reservation />} />
                <Route
                  path="/reservation-report"
                  element={<ReservationReport />}
                />
                <Route path="/leave-approval" element={<MyLeaveApp />} />
                <Route
                  path="/skill-department"
                  element={<DepartmentMaster />}
                />
                <Route path="/skill-plant" element={<PlantMaster />} />
                <Route path="/skill-sub-group" element={<SubGroupMaster />} />
                <Route path="/skill-master" element={<SkillMaster />} />

                <Route path="/skill-plant" element={<PlantMaster />} />
                <Route
                  path="/skill-group-mapping"
                  element={<SkillGroupMapping />}
                />
                <Route path="/skill" element={<Skill />} />
                <Route path="/skill-report" element={<SkillReport />} />
                <Route path="/skill-location" element={<SkillLocation />} />
                <Route path="/skill-group" element={<SkillGroup />} />
                <Route path="/skill-sub-group" element={<SubGroupMaster />} />
                <Route path="/delegate" element={<Delegate />} />
                <Route path="/assignBspoc" element={<AssignBspoc />} />
                <Route path="/my-idea-approval" element={<IdeaApproval />} />
                <Route path="/shift-master" exact element={<ShiftMaster />} />
                <Route
                  path="/purchase-org"
                  exact
                  element={<PurchaseOrganisation />}
                />
                <Route
                  path="/purchase-group"
                  exact
                  element={<PurchaseGroup />}
                />
                <Route
                  path="/shift-approval"
                  exact
                  element={<ShiftApproval />}
                />
                <Route
                  path="/shift-approval-report"
                  exact
                  element={<ShiftApproval />}
                />
                <Route
                  path="/shift-bhr-report"
                  exact
                  element={<ShiftApproval bhr={true} />}
                />
                <Route path="/docType" exact element={<DocumentTypeIndex />} />
                <Route
                  path="/changeShift"
                  exact
                  element={<ChangeShiftIndex />}
                />
                <Route
                  path="/travel-intimation"
                  exact
                  element={<TravelIntimation />}
                />
                <Route
                  path="/travel-listing"
                  exact
                  element={<TravelListing />}
                />
                <Route path="/expense-claim" exact element={<ExpenseClaim />} />
                <Route path="/claim-listing" exact element={<ClaimListing />} />
                <Route
                  path="/travelEntryException"
                  exact
                  element={<BackDateListing  />}
                />
                <Route
                  path="/viewtravelint"
                  exact
                  element={<ViewTravelInt />}
                />
                <Route
                  path="/otheradvance/applyadvance"
                  exact
                  element={<ApplyOtherAdvance />}
                />
                <Route
                  path="/otheradvance/myotheradvance"
                  exact
                  element={<MyOtherAdvance />}
                />
                <Route
                  path="/otheradvance/view-other-advance"
                  exact
                  element={<ViewOtherAdvance />}
                />
                <Route
                  path="/otheradvance/allOtherAdvance"
                  exact
                  element={<AllOtherAdvance />}
                />
                <Route
                  path="/otheradvance/approvals"
                  exact
                  element={<OtherAdvanceApproval />}
                />
                <Route
                  path="/add-other-expense"
                  exact
                  element={<AddOtherExpense />}
                />
                {/* <Route  
                  path="/view-other-expense"
                  exact
                  element={<ViewExpenTravInt />}
                /> */}
                <Route
                  path="/other-listing"
                  exact
                  element={<OtherExpListing />}
                />
                <Route
                  path="/print-other-listing"
                  exact
                  element={<ViewExpenTravInt />}
                />
                <Route
                  path="/otherexception-master"
                  exact
                  element={<AddOtherExpEntryException />}
                />

                <Route
                  path="/otherexception-list"
                  exact
                  element={<ListOtherExpEntryException />}
                />

                <Route
                  path="/expense-listing"
                  exact
                  element={<OtherExpenseListingSap />}
                />
                <Route
                  path="/travel-view-list"
                  exact
                  element={<ViewTravelList />}
                />
                <Route
                  path="/expense-view-list"
                  exact
                  element={<ExpenseViewSap />}
                />

                <Route
                  path="/otherexp-printview"
                  exact
                  element={<OtherExpPrintview />}
                />

                <Route
                  path="/viewotherexp"
                  exact
                  element={<ViewExpenTravInt />}
                />
                <Route
                  path="/nps-declaration"
                  exact
                  element={<NpsDeclaration />}
                />
                <Route
                  path="/nps-open-decl"
                  exact
                  element={<NPSOpenDeclaration />}
                />
                <Route
                  path="/nps-user-guide"
                  exact
                  element={<NPSUserGuide />}
                />
                <Route path="/loan-approval" exact element={<LoanApproval />} />
                <Route path="/loan-apply" exact element={<LoanApply />} />
                <Route path="/loan-report" exact element={<LoanReport />} />
                <Route
                  path="/salary-approver"
                  exact
                  element={<SalaryApprover />}
                />
                <Route path="/skill-user" exact element={<SkillUser />} />
                <Route
                  path="/vendor-invoice"
                  exact
                  element={<VendorInvoice />}
                />
                <Route
                  path="/vendor-invoice-pending"
                  exact
                  element={<VendorInvoice />}
                />
                <Route
                  path="/vendor-invoice-report"
                  exact
                  element={<VendorInvoice />}
                />

                <Route
                  path="/vendor-invoice-approval"
                  exact
                  element={<VendorInvoice />}
                />

                <Route path="/npsreport" exact element={<NpsReport />} />
                <Route path="/faq" exact element={<FaqMaster />} />
                <Route path="/faqs" exact element={<FaqDashboard />} />
                <Route path="/help-desk" exact element={<HelpDesk />} />
                <Route path="/chatgpt" exact element={<ChatGpt />} />
                <Route
                  path="/view-approve-cms"
                  exact
                  element={<ApproveCmsModule />}
                />
                <Route
                  path="/approveEss"
                  exact
                  element={<ApproveEssModule />}
                />
                <Route
                  path="/approveOtherAdv"
                  exact
                  element={<ApproveOtherAdv />}
                />
                <Route
                  path="/skill-quarterly"
                  exact
                  element={<SkillQuaterly />}
                />
                <Route path="/skill-tab" exact element={<SkillTab />} />
                <Route path="/skill-usage" exact element={<SkillUsage />} />
                <Route
                  path="/gi-balancesheet"
                  exact
                  element={<GIBalancesheet />}
                />
                <Route path="/gi-approval" exact element={<GIApprovral />} />
                <Route path="/approve-gi" exact element={<ApproveGI />} />
                <Route
                  path="/cre-instrument"
                  exact
                  element={<CREInstrument />}
                />
                <Route path="/add-role" exact element={<AddRole />} />
                {/* LRGR portal routes */}
                <Route path="/transport" exact element={<TransportRoot />} />
                <Route
                  path="/transport/table"
                  exact
                  element={<TransportTable />}
                />
                <Route path="/billprocess" exact element={<BillRoot />} />
                <Route
                  path="/billprocess/table"
                  exact
                  element={<BillTable />}
                />
                <Route path="/tadmin" exact element={<TAdmin />} />
                <Route path="/plant" exact element={<LRGRPlant />} />
                <Route
                  path="/transporter"
                  exact
                  element={<LRGRTransporter />}
                />
                <Route
                  path="/subtransporter"
                  exact
                  element={<LRGRSubTransporterRoot />}
                />
                <Route
                  path="/subtransporter/table"
                  exact
                  element={<LRGRSubTransporterTable />}
                />
                <Route path="/summary" exact element={<LRGRSummary />} />
                {/* Agri portal routes */}

                <Route
                  path="/etreview/table"
                  exact
                  element={<ETReviewTable />}
                />

                <Route path="/etreview/root" exact element={<ETReviewRoot />} />

                <Route
                  path="/agsales/agtable"
                  exact
                  element={<AGSalesTable />}
                />
                <Route path="/agsales/agroot" exact element={<AGSalesRoot />} />
                <Route
                  path="/mymarket/marketsize"
                  exact
                  element={<MarketSizeRoot />}
                />
                <Route
                  path="/mymarket/marketsize/table"
                  exact
                  element={<MarketSizeTable />}
                />
                <Route
                  path="/agsales/agtable"
                  exact
                  element={<AGSalesTable />}
                />
                <Route path="/agsales/agroot" exact element={<AGSalesRoot />} />

                <Route
                  path="/etreview/table"
                  exact
                  element={<ETReviewTable />}
                />

                <Route path="/etreview/root" exact element={<ETReviewRoot />} />

                <Route
                  path="/mymarket/marketplayer"
                  exact
                  element={<MarketPlayer />}
                />
                <Route
                  path="/mymarket/marketplayer/table"
                  exact
                  element={<MarketPlayerTable />}
                />
                <Route
                  path="/mymarket/majorproducts"
                  exact
                  element={<MajorProducts />}
                />
                <Route
                  path="/mymarket/majorproducts/table"
                  exact
                  element={<MajorProductsTable />}
                />
                <Route
                  path="/mymarket/croppattern"
                  exact
                  element={<CropPattern />}
                />
                <Route
                  path="/mymarket/croppattern/table"
                  exact
                  element={<CropPatternTable />}
                />
                <Route
                  path="/mymarket/cropscenario"
                  exact
                  element={<CropScenario />}
                />
                <Route
                  path="/mymarket/cropscenario/table"
                  exact
                  element={<CropScenarioTable />}
                />
                <Route
                  path="/mymarket/channelsize"
                  exact
                  element={<ChannelSize />}
                />
                <Route
                  path="/mymarket/channelsize/table"
                  exact
                  element={<ChannelSizeTable />}
                />
                <Route
                  path="/mymarket/competitionbrandmapping"
                  exact
                  element={<CompetitionBrandMapping />}
                />
                <Route
                  path="/mymarket/competitionbrandmapping/table"
                  exact
                  element={<CompetitionBrandMappingTable />}
                />
                <Route
                  path="/mymarket/competitionmoleculemapping"
                  exact
                  element={<CompetitionMoleculeMapping />}
                />
                <Route
                  path="/mymarket/competitionmoleculemapping/table"
                  exact
                  element={<CompetitionMoleculeMappingTable />}
                />
                <Route path="/myplan/cropplan" exact element={<CropPlan />} />
                <Route
                  path="/myplan/cropplan/table"
                  exact
                  element={<CropPlanTable />}
                />
                <Route path="/myplan/salesplan" exact element={<SalesPlan />} />
                <Route
                  path="/myplan/salesplan/table"
                  exact
                  element={<SalesPlanTable />}
                />
                <Route
                  path="/myplan/detailedsalesplan"
                  exact
                  element={<DetailedSalesPlan />}
                />
                <Route
                  path="/myplan/detailedsalesplan/table"
                  exact
                  element={<DetailedSalesPlanTable />}
                />
                <Route
                  path="/mystrategy/cropproductstrategy"
                  exact
                  element={<CropProductStrategy />}
                />
                <Route
                  path="/mystrategy/cropproductstrategy/table"
                  exact
                  element={<CropProductStrategyTable />}
                />
                <Route path="/myplan/fieldwork" exact element={<FieldWork />} />
                <Route
                  path="/myplan/fieldwork/table"
                  exact
                  element={<FieldWorkTable />}
                />
                <Route
                  path="/myplan/expenseplan"
                  exact
                  element={<ExpensePlan />}
                />
                <Route
                  path="/myplan/expenseplan/table"
                  exact
                  element={<ExpensePlanTable />}
                />
                <Route
                  path="/myplan/rollingplan"
                  exact
                  element={<RollingPlan />}
                />
                <Route
                  path="/myplan/rollingplan/table"
                  exact
                  element={<RollingPlanTable />}
                />
                <Route
                  path="/myplan/dispatchplan"
                  exact
                  element={<DispatchPlan />}
                />
                <Route
                  path="/myplan/dispatchplan/table"
                  exact
                  element={<DispatchPlanTable />}
                />
                <Route path="/autoRole" exact element={<AutoRoleAssign />} />
                <Route path="/GlCostMapping" exact element={<GlCostCenter />} />
                <Route path="/GlMapping" exact element={<GlApprover />} />
                <Route path="/pr-list" exact element={<PRlist />} />
                <Route path="/pr-approval" exact element={<PRApproval />} />
                <Route path="/pr-reports" exact element={<PRreports />} />
                <Route path="/pr-user-reports" exact element={<PRreports />} />
                <Route path="/sap-pr-reports" exact element={<PrReportSap />} />
                <Route path="/sync-pr" exact element={<SyncPR />} />
                <Route path="/sync-ses" exact element={<SyncSes />} />
                <Route path="/sync-po" exact element={<SyncPO />} />
                <Route path="/sync-material" exact element={<SyncMaterial />} />
                <Route path="/upload-cigma" exact element={<UploadSigma />} />
                <Route
                  path="/pr-unit-master"
                  exact
                  element={<PRUnitMaster />}
                />
                <Route
                  path="/pr-plant-master"
                  exact
                  element={<PRplantMaster />}
                />
                <Route
                  path="/pr-group-master"
                  exact
                  element={<PRPurchMaster />}
                />
                <Route path="/pr-org-master" exact element={<PROrgMaster />} />
                <Route
                  path="/pr-type-master"
                  exact
                  element={<PRTypeMaster />}
                />
                <Route
                  path="/pr-user-master"
                  exact
                  element={<PRUserMaster />}
                />
                <Route path="/create-pr" exact element={<CreatePR />} />
                <Route path="/view-pr-approval" exact element={<ViewPR />} />
                <Route
                  path="/contract-approval"
                  exact
                  element={<ContractApproval />}
                />
                <Route path="/userPlant" exact element={<UserPlantMapping />} />
                <Route path="/balancesheet" exact element={<BalanceSheet />} />
                <Route
                  path="/allocation-leave"
                  exact
                  element={<UserLeaveAllocation />}
                />
                <Route path="/view-ca" exact element={<ViewCa />} />
                <Route
                  path="/mystrategy/overallstrategy"
                  exact
                  element={<OverallStrategy />}
                />
                <Route
                  path="/mystrategy/overallstrategy/table"
                  exact
                  element={<OverallStrategyTable />}
                />
                <Route
                  path="/myplan/popplanner"
                  exact
                  element={<PopPlanner />}
                />
                <Route
                  path="/myplan/popplanner/table"
                  exact
                  element={<PopPlannerTable />}
                />
                <Route
                  path="/myplan/fieldworkplanner"
                  exact
                  element={<FieldWorkPlanner />}
                />
                <Route
                  path="/myplan/fieldworkplanner/table"
                  exact
                  element={<FieldWorkPlannerTable />}
                />
                <Route path="/vpf" exact element={<PFVoluntary />} />
                <Route
                  path="/vpfreport"
                  exact
                  element={<PFVoluntaryReport />}
                />
                {/* Lazy loading tabs in medicalim declration */}
                <Route path="/mediclaim-decl" element={<MediclaimDecl />}>
                  <Route
                    index
                    element={
                      <Suspense fallback={<>Loading...</>}>
                        <MediclaimDeclarationAdd />
                      </Suspense>
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <Suspense fallback={<>Loading...</>}>
                        <MediclaimDeclarationEdit />
                      </Suspense>
                    }
                  />
                  <Route
                    path="open"
                    element={
                      <Suspense fallback={<>Loading...</>}>
                        <MediclaimDeclarationOpen />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/user-mediclaim-open"
                  exact
                  element={<UserDeclaration />}
                />
                <Route
                  path="/employee-insurance"
                  exact
                  element={<EmployeeInsurance />}
                />
                <Route
                  path="/mediclaim-report"
                  exact
                  element={<MediclaimReport />}
                />
                <Route
                  path="/mediclaim-upload"
                  exact
                  element={<UploadPremium />}
                />
                <Route
                  path="/mediclaim-premium"
                  exact
                  element={<MediclaimPremium />}
                />
                <Route
                  path="/mediclaim-premium-topup"
                  exact
                  element={<MediclaimPremiumTopUp />}
                />
                <Route
                  path="/material-req"
                  exact
                  element={<MaterialRequest />}
                />
                <Route path="/addnewmaterial" exact element={<AddNewMater />} />
                <Route path="/mat-ext" exact element={<MaterialExtension />} />
                <Route
                  path="/addnewmaterialext"
                  exact
                  element={<AddMatExt />}
                />
                <Route path="/mat-cas" exact element={<UpdateCAS />} />
                <Route path="/mat-hsn" exact element={<MatHSN />} />
                <Route path="/mat-hsn-report" exact element={<MatHSN />} />
                <Route
                  path="/mat-ext-approval"
                  exact
                  element={<MatExtApproval />}
                />
                <Route
                  path="/hsn-approval"
                  exact
                  element={<HSNCodeApproval />}
                />
                <Route
                  path="/view-hsn-approval"
                  exact
                  element={<ViewHSNApproval />}
                />
                <Route path="/power-user" exact element={<PowerUser />} />
                <Route
                  path="/authrize-user"
                  exact
                  element={<AuthorizeUser />}
                />
                <Route
                  path="/authrize-user-master"
                  exact
                  element={<AuthorizeUserMaster />}
                />
                <Route path="/mat-descp" exact element={<MatDescrp />} />
                <Route path="/ref-mat" exact element={<RefMat />} />
                <Route path="/mat-approval" exact element={<MatApproval />} />
                <Route
                  path="/mat-approval-report"
                  exact
                  element={<MatApproval />}
                />
                <Route
                  path="/approve-mat-req"
                  exact
                  element={<AppMaterReq />}
                />
                <Route
                  path="/cashbook-expenses"
                  exact
                  element={<CashExpenses />}
                />
                <Route
                  path="/cashbook-wise-expense-report"
                  exact
                  element={<CashbookWiseExpenseReport />}
                />
                <Route path="/higher-edu" exact element={<HigherEdu />} />
                <Route
                  path="/higher-edu-application"
                  exact
                  element={<HigherEduApplication />}
                />
                <Route
                  path="/higher-edu-listing"
                  exact
                  element={<HigherEduListing />}
                />
                <Route
                  path="/higher-edu-report"
                  exact
                  element={<HigherEduReport />}
                />
                <Route
                  path="/higher-edu-approval"
                  exact
                  element={<HigherEduApproval />}
                />
                <Route
                  path="/higher-edu-claim-listing"
                  exact
                  element={<HigherEduClaimListing />}
                />
                <Route
                  path="/higher-edu-claim"
                  exact
                  element={<HigherEduClaim />}
                />
                <Route
                  path="/higher-edu-claim-detail"
                  exact
                  element={<HigherEduClaimDetail />}
                />
                <Route
                  path="/higher-edu-add-claim"
                  exact
                  element={<HigherEduAddClaim />}
                />
                <Route
                  path="/higher-edu-claim-approval"
                  exact
                  element={<HigherEduClaimApproval />}
                />
                <Route
                  path="/view-cash-expenses"
                  exact
                  element={<ViewExpenes />}
                />
                <Route
                  path="/cashbook-receipts"
                  exact
                  element={<CashbookReceipt />}
                />
                <Route
                  path="/cashbook-vendors"
                  exact
                  element={<CashbookVendors />}
                />
                <Route path="/cashbook-demand" exact element={<CashDemand />} />
                <Route
                  path="/cashbook-approval"
                  exact
                  element={<CashbookApproval />}
                />
                <Route
                  path="/cashbook-reports"
                  exact
                  element={<CashReports />}
                />
                <Route
                  path="/otv-payment-request"
                  exact
                  element={<OTVRequest />}
                />
                <Route path="/otv-pick-list" exact element={<OTVPickList />} />
                <Route
                  path="/non-po-payment-request"
                  exact
                  element={<NonPORequest />}
                />
                <Route
                  path="/vendor-reimb"
                  exact
                  element={<VendorreimRequest />}
                />
                <Route
                  path="/otv-vendor-request"
                  exact
                  element={<VendorPayReq />}
                />

                <Route path="/visitor" exact element={<Visitor />} />
                <Route
                  path="/visitor-master-management"
                  exact
                  element={<VisitorMasterManagement />}
                />
                <Route
                  path="/visiting-site-details"
                  exact
                  element={<VisitingSite />}
                />
                <Route
                  path="/visiting-site-master"
                  exact
                  element={<VisitingSiteMaster />}
                />
                <Route path="/otv-report" exact element={<VendorPayReq />} />
                <Route path="/otv-approval" exact element={<OTVApproval />} />
                <Route path="/notfound" exact element={<NotFound />} />
                <Route
                  path="/successfactor"
                  exact
                  element={<SuccessFactor />}
                />
                <Route path="/gst" exact element={<GST />} />
                <Route path="/companydoc" exact element={<CompanyDoc />} />
                <Route path="/gstView" exact element={<GSTView />} />
                <Route
                  path="/companydocView"
                  exact
                  element={<CompanyDocView />}
                />
                <Route
                  path="/view-approve-cashbook-modules"
                  exact
                  element={<ApproveCashbookModules />}
                />
                <Route
                  path="/view-travel"
                  exact
                  element={<ApproveTravelModules />}
                />
                {/* Cashbook manage expense Route */}
                <Route
                  path="/manage-expense"
                  exact
                  element={<ManageExpenseGrid />}
                />
                <Route
                  path="/cashbook-beneficial"
                  exact
                  element={<CashbookBeneficial />}
                />
                <Route path="/bulk-leave" exact element={<BulkUploadLeave />} />
                <Route
                  path="/vpf-open-decl"
                  exact
                  element={<VPFOpenDeclaration />}
                />
                <Route path="/otvApprover" exact element={<OTVApprover />} />
                <Route path="/viewotv/:id" exact element={<ViewOtvNonpo />} />
                <Route
                  path="/travelApproval"
                  exact
                  element={<TravelListingApproval />}
                />
                <Route
                  path="/idea-implementation"
                  exact
                  element={<IdeaImplementation />}
                />
                <Route path="/leave-report" exact element={<LeaveReport />} />
                <Route
                  path="/bhr-leave-report"
                  exact
                  element={<LeaveReport bhr={true} />}
                />
                <Route
                  path="/deniedpermission"
                  exact
                  element={<PermissionDenied />}
                />
                <Route path="/sus-policy" exact element={<SustainPolicy />} />
                <Route path="/travel-pick" exact element={<TravelPickup />} />

                <Route
                  path="/travel-approve-expense"
                  exact
                  element={<ApproveTravelExpense />}
                />
                <Route
                  path="/under_process_list"
                  exact
                  element={<TravelUnderProcessList />}
                />
                <Route path="/claim-reimb" exact element={<ClaimApp />} />
                <Route
                  path="/reimbursement-report"
                  exact
                  element={<ReimbursementReport />}
                />
                <Route
                  path="/reimb-recovery-report"
                  exact
                  element={<ReimburseRecoveryReport />}
                />
                <Route
                  path="/notice-approver"
                  exact
                  element={<NoticePeriodApprover />}
                />
                <Route
                  path="/emp-ref-reimb"
                  exact
                  element={<EmpRefReimbursement />}
                />
                <Route
                  path="/canteen-menu-management"
                  exact
                  element={<CanteenManagement />}
                />
                <Route
                  path="/canteen-menu-master"
                  exact
                  element={<CanteenMaster />}
                />
                <Route path="/canteen-menu" exact element={<CanteenMaster />} />

                {/* <Route path="/fbp" exact element={<FbpReportComp />} />
                <Route path="/fbpreport" exact element={<FbpReportComp />} /> */}
                <Route
                  path="/travel-expense"
                  exact
                  element={<TravelExpense />}
                />
                <Route
                  path="/travel-check-expense"
                  exact
                  element={<TravelCheckExpens />}
                />
                <Route
                  path="/otherexp-check-expense"
                  exact
                  element={<OtherExpCheckExpens />}
                />
                <Route
                  path="/otherexp-underprocess"
                  exact
                  element={<OtherExpUnderProcess />}
                />
                <Route
                  path="/otherexp-detailreport"
                  exact
                  element={<OtherExpDetailReport />}
                />
                <Route
                  path="/otherexp-bhrreport"
                  exact
                  element={<OtherExpBHRReport />}
                />
                <Route
                  path="/otherexp-allrequests"
                  exact
                  element={<OtherExpAllReq />}
                />
                <Route
                  path="/otherexp-machineglreport"
                  exact
                  element={<OtherExpMachineGLReport />}
                />
                <Route
                  path="/otherexp-farmerglreport"
                  exact
                  element={<OtherExpFarmerGLReport />}
                />
                <Route
                  path="/attendance-report"
                  exact
                  element={<AttendanceReport />}
                />
                <Route
                  path="/absconding-report"
                  exact
                  element={<AbscondingReport />}
                />
                <Route
                  path="/bhr-attendance-report"
                  exact
                  element={<AttendanceReport bhr={true} />}
                />
                <Route path="/search-ca" exact element={<SearchCA />} />
                <Route path="/viewcasearch" exact element={<ViewCaSearch />} />
                <Route
                  path="/reimbursement-approval/:type"
                  exact
                  element={<ReimbursementApproval />}
                />
                {/* <Route path="/notice-reimb" exact element={<NoticeReimbursement />} /> */}
                <Route path="/travel-bills" exact element={<TravelBills />} />
                <Route
                  path="/emp-list-report"
                  exact
                  element={<EmpListReport />}
                />
                <Route
                  path="/travel-processed-ta"
                  exact
                  element={<TravelProcessedTa />}
                />
                <Route
                  path="/travel-km-report"
                  exact
                  element={<TravelKmreport />}
                />
                <Route
                  path="/travel-hotel-exp-report"
                  exact
                  element={<TravelHotelExpReport />}
                />
                <Route
                  path="/travel-conveyance-exp-report"
                  exact
                  element={<TravelConveyanceExpReport />}
                />

                <Route
                  path="/travel-provis-report"
                  exact
                  element={<TravelProvisReport />}
                />
                <Route
                  path="/travel-fcm-exp-report"
                  exact
                  element={<TravelFcmExpReport />}
                />
                <Route
                  path="/travel-intimat-report"
                  exact
                  element={<TravelIntimreport />}
                />
                <Route
                  path="/travel-intimat-detailreport"
                  exact
                  element={<TravelIntimDetailreport />}
                />
                <Route
                  path="/travel-deviat-report"
                  exact
                  element={<TravelDeviation />}
                />
                <Route
                  path="/attendance-report"
                  exact
                  element={<AttendanceReport />}
                />
                <Route path="/user-gl" exact element={<UserGlMapping />} />
                <Route
                  path="/expensesPrint"
                  exact
                  element={<ViewPrintPage />}
                />
                <Route
                  path="/user-certification-report"
                  exact
                  element={<UserCertificationReport />}
                />
                <Route
                  path="/custom-role"
                  exact
                  element={<CustomRoleAssign />}
                />
                <Route
                  path="/ess-policy"
                  exact
                  element={<EssPolicyMaster />}
                ></Route>
                <Route
                  path="/cardPettyForm"
                  exact
                  element={<CardMappingIndex />}
                />
                <Route path="/assign-Gi" exact element={<AssignGiIndex />} />
                <Route
                  path="/vehicle-res"
                  exact
                  element={<AddNewVehicleIndex />}
                />
                <Route
                  path="/vehicle-assign"
                  exact
                  element={<AssignNewVehicleIndex />}
                />
                <Route
                  path="/vehicle_history"
                  exact
                  element={<VehicleHistory />}
                />
                <Route path="/vehicle-pick" exact element={<VehiclePick />} />
                <Route path="/otherexp-pick" exact element={<OtherExpPick />} />
                <Route
                  path="/otherexp-check"
                  exact
                  element={<OtherExpCheck />}
                />
                <Route
                  path="/vehicle-expense"
                  exact
                  element={<VehicleExpense />}
                />
                <Route path="/vehicle-check" exact element={<VehicleCheck />} />
                <Route
                  path="/vehicle-report"
                  exact
                  element={<VehicleReport />}
                />
                <Route
                  path="/Projected-Registration-report"
                  exact
                  element={<VehicleRegistrationReport />}
                />
                <Route
                  path="/print-vehicle-check"
                  exact
                  element={<PrintVehicleCheck />}
                />

                <Route
                  path="/vehicle-pick-insurance"
                  exact
                  element={<VehiclePickInsurance />}
                />
                <Route
                  path="/vehicle-history-insurance"
                  exact
                  element={<VehicalInsuranceHistoryInsurance />}
                />

                <Route
                  path="/vehicle-expense-insurance"
                  exact
                  element={<VehicleExpenseInsurance />}
                />
                <Route
                  path="/vehicle-check-insurance"
                  exact
                  element={<VehicleCheckInsurance />}
                />
                <Route
                  path="/print-vehicle-insurance-check"
                  exact
                  element={<PrintVehicleInsuranceCheck />}
                />
                <Route
                  path="/vehicle-insurance-report"
                  exact
                  element={<VehicleInsuranceReport />}
                />
                <Route
                  path="/Projected-insurance-report"
                  exact
                  element={<VehicleProjectedInsurance />}
                />
                <Route path="/viewOther" exact element={<ViewOtherExpense />} />
                <Route
                  path="/viewExpenseTravel"
                  exact
                  element={<ViewTravelExpenseData />}
                />
                <Route
                  path="/travel-advance-access"
                  exact
                  element={<TravelAdvanceAccess />}
                />
                <Route
                  path="/travel-advance-access-list"
                  exact
                  element={<TravelAdvanceAccessListing />}
                />
                <Route
                  path="/travel/discard-to-picklist"
                  exact
                  element={<Traveldiscardtopicklist />}
                />
                <Route
                  path="/otherexp/discard-to-picklist"
                  exact
                  element={<Otherexpdiscardtopicklist />}
                />
                <Route
                  path="/travel-log-amount"
                  exact
                  element={<TravelLogAmount />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </PrivateRoute>
    </>
  );
  return <>{myRights.result !== undefined ? template : ""}</>;
}

/* Lazy Imports */

/* Mediclaim Declration routes  */
const MediclaimDeclarationAdd = React.lazy(() =>
  import("../Employee-Insurance/declaration/add-declaration")
);
const MediclaimDeclarationEdit = React.lazy(() =>
  import("../Employee-Insurance/declaration/edit-declaration")
);
const MediclaimDeclarationOpen = React.lazy(() =>
  import("../Employee-Insurance/declaration/open-declaration")
);

const mapDispatchToProps = (dispatch) => ({
  onRightsUpdate: (myrights) =>
    dispatch({ type: ActionTypes.UPDATE_RIGHTS, myrights }),
  userLogin: (token, istemp) =>
    dispatch({ type: ActionTypes.LOGIN_USER, token, istemp }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
