import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import * as SubAreaService from "../../service/subAreaServices";
import moment from "moment";
import {
  getBackDatedExport,
  getBackDatesEntries,
  removeBackDateEntry,
  saveBackDateAlloc,
} from "../../service/leaveService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getUsersListDataNew } from "../../service/userService";
import { confirmAlert } from "react-confirm-alert";

function LeaveBackDate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userList, setUserList] = useState([]);
  const userData = useSelector(selectUserData);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPeriod, setMinPeriod] = useState("");
  const [maxPeriod, setMaxPeriod] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [formObj, setFormObj] = useState({
    user: "",
    from_date: "",
    to_date: "",
    period_from: "",
    period_to: "",
  });
  const [formError, setFormError] = useState({
    userError: "",
    fromDateError: "",
    toDateError: "",
    periodFromError: "",
    periodToError: "",
  });
  const [recordList, setRecordList] = useState([]);
  const [filterValue, setFilterValue] = useState({
    user_id: "",
    year: ""
  });

  useEffect(() => {
    if (userData?.id !== undefined) {
      dispatch(setDisplayLoader("Display"));
      // getUserList();
      getRecords("", "", true, 1, 10, "-id", true);
    }
  }, [userData]);

  useEffect(() => {
    loadUserMasterList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    let today = new Date();
    let min = moment(today).subtract(100, "days").format("YYYY-MM-DD");
    setMaxPeriod(moment(today).subtract(1, "days").format("YYYY-MM-DD"));
    setMinPeriod(min);
  }, []);

  const handleKey = (
    search,
    filter,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    location
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (filter !== "") {
      if (filter?.user_id !== "") {
        queryParm = queryParm + "&user_id=" + filter.user_id.value;
      }
      if (filter?.year !== "") {
        queryParm = queryParm + "&year=" + filter.year;
      }
    }
    return queryParm;
  };
  const handlelocationKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
   
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    // if (
    //   is_sf !== "" &&
    //   is_sf !== undefined &&
    //   is_sf !== null &&
    //   is_sf.length !== 0
    // ) {
    //   queryParm = queryParm + "&is_sf=" + is_sf;
    // }
   
    return queryParm;
  };
  const getRecords = async (
    search,
    filter,
    status,
    currentPage,
    pageSize,
    sortBy,
    paginate
  ) => {
    await getBackDatesEntries(
      handleKey(search, filter, status, currentPage, pageSize, sortBy, paginate)
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        setRecordList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  const getRecordsExport = async (
    search,
    filter,
    status,
    currentPage,
    pageSize,
    sortBy,
    paginate
  ) => {
    await getBackDatedExport(
      handleKey(search, filter, status, currentPage, pageSize, sortBy, paginate)
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Leave Backdate Exception.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  const loadUserMasterList = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
   
  ) => {
    SubAreaService.getSubAreaUser(
      handlelocationKey(
        search,
        // is_sf,           
        pageNo,
        page_size,
        sort_by,
        paginate,
       
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let res = response?.data?.dataList?.result
        .filter((obj) => {
          if (obj?.username !== "admin" && obj?.username !== "super_pi") {
            return true;
          }
        })
        .map((obj) => {
          return {
            label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
            value: obj?.id,
          };
        });
        setUserList(res);
        
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setShowForm(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
 
  const getUserList = async () => {
    await getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserList(res);
      }
    });
  };

  const handleChange = (event, name) => {
    switch (name) {
      case "user":
        setFormObj((prev) => ({
          ...prev,
          user: event,
        }));
        break;
      case "from":
        setFormObj((prev) => ({
          ...prev,
          from_date: event.target.value,
        }));
        break;
      case "to":
        setFormObj((prev) => ({
          ...prev,
          to_date: event.target.value,
        }));
        break;
      case "period":
        setFormObj((prev) => ({
          ...prev,
          period_from: event.target.value,
        }));
        break;
      case "period_to":
        setFormObj((prev) => ({
          ...prev,
          period_to: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleErrors = () => {
    let flag = false;
    if (formObj?.user === "") {
      setFormError((prev) => ({
        ...prev,
        userError: true,
      }));
      flag = true;
    }
    if (formObj?.period_from === "") {
      setFormError((prev) => ({
        ...prev,
        periodFromError: true,
      }));
      flag = true;
    }
    if (formObj?.period_to === "") {
      setFormError((prev) => ({
        ...prev,
        periodToError: true,
      }));
      flag = true;
    }
    if (formObj?.from_date === "") {
      setFormError((prev) => ({
        ...prev,
        fromDateError: true,
      }));
      flag = true;
    }
    if (formObj?.to_date === "") {
      setFormError((prev) => ({
        ...prev,
        toDateError: true,
      }));
      flag = true;
    }
    return flag;
  };

  const saveRecord = async () => {
    if (handleErrors() === false) {
      await saveBackDateAlloc(formObj)
        .then((response) => {
          if (response?.status === 200) {
            if (response?.data?.detail !== undefined) {
              toast.error(response?.data?.detail);
            } else {
              toast.success("Back Date Window opened for user.");
            }
            getRecords(
              searchStr,
              filterValue,
              true,
              currentPage,
              pageSizeNo,
              "-id",
              true
            );
            addnewclose();
          }
        })
        .catch((error) => {
          toast.error("Kindly try again after sometime.");
        });
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getRecords(
      searchStr,
      filterValue,
      true,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const mainDataFilter = () => {
    if (filterValue?.user_id !== "") {
      setFilterApplied(true);
    }
    getRecords(
      searchStr,
      filterValue,
      true,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
    filterclose();
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    console.log(sdata?.sort_by);
    getRecords(
      searchStr,
      filterValue,
      true,
      currentPage,
      pageSizeNo,
      sdata?.sort_by,
      true
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length >= 2 || e.target.value.length === 0) {
      getRecords(
        e.target.value.toLowerCase(),
        filterValue,
        true,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const deleteException = (id) => {
    removeBackDateEntry(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Backdate Exception Deleted");
        getRecords(
          searchStr,
          filterValue,
          true,
          currentPage,
          pageSizeNo,
          sortData?.sort_by,
          true
        );
      }
    });
  };

  const handleDelete = (id) => {
    confirmAlert({
      message: `Are you sure to delete this exception entry ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteException(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/manage-leave">Leave Master</Link>
              </li>
              <li>
                <Link to="/allocation-leave">Leave Rule</Link>
              </li>
              <li>
                <Link to="/allocation-leave">Leave Allocation</Link>
              </li>
              <li>
                <Link to="/my-leave">My Leaves</Link>
              </li>
              <li>
                <Link to="/bulk-leave">Leave Bulk Upload</Link>
              </li>
              <li>
                <Link to="/leave-approval">For Approval Leaves</Link>{" "}
              </li>
              <li class="active">
                <Link to="/leave-backdate">Back Date Entry Exception</Link>
              </li>
            </ul>
          </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Back Date Entry Exception</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Employee Name, Employee Code, Created By, Updated
                              By...
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Employee Name, Employee Code, Created By, Updated By"
                          onChange={(event) => {
                            handleSearch(event);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" className="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              style={{ border: 0, background: "none" }}
                              onClick={() => {
                                getRecordsExport(
                                  searchStr,
                                  filterValue,
                                  true,
                                  currentPage,
                                  pageSizeNo,
                                  sortData?.sort_by,
                                  true
                                );
                              }}
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                      <button
                        class="btn btn-primary-inner"
                        onClick={addnewopen}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Actions</th>
                      <th
                        onClick={(e) => {
                          handlerData("user__username");
                        }}
                        className={
                          sortData["user__username"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["user__username"]
                        }
                      >
                        Employee Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("user__full_name");
                        }}
                        className={
                          sortData["user__full_name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["user__full_name"]
                        }
                      >
                        Employee Name
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("period_from");
                        }}
                        className={
                          sortData["period_from"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["period_from"]
                        }
                      >
                        Period From
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("period_to");
                        }}
                        className={
                          sortData["period_to"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["period_to"]
                        }
                      >
                        Period To
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("from_date");
                        }}
                        className={
                          sortData["from_date"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["from_date"]
                        }
                      >
                        From Date
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("to_date");
                        }}
                        className={
                          sortData["to_date"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["to_date"]
                        }
                      >
                        To Date
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("created_by__full_name");
                        }}
                        className={
                          sortData["created_by__full_name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["created_by__full_name"]
                        }
                      >
                        Created By
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("created_at");
                        }}
                        className={
                          sortData["created_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["created_at"]
                        }
                      >
                        Created At
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_by__full_name");
                        }}
                        className={
                          sortData["updated_by__full_name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_by__full_name"]
                        }
                      >
                        Last Updated By
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_at");
                        }}
                        className={
                          sortData["updated_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_at"]
                        }
                      >
                        Last Updated At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {recordList?.length !== 0 ? (
                      recordList?.map((obj) => (
                        <tr>
                          <td>
                            {/* <button
                              onClick={addnewopen}
                              class="btn btn-blanktd text-primary"
                            >
                              <i class="far fa-edit"></i>{" "}
                            </button> */}
                            <button
                              class="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                handleDelete(obj.id);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                          <td>{obj?.user__username}</td>
                          <td>{obj?.full_name}</td>
                          <td>
                            {moment(obj?.period_from).format("DD-MM-YYYY")}
                          </td>
                          <td>{moment(obj?.period_to).format("DD-MM-YYYY")}</td>
                          <td>{moment(obj?.from_date).format("DD-MM-YYYY")}</td>
                          <td>{moment(obj?.to_date).format("DD-MM-YYYY")}</td>
                          <td>{obj?.created_by}</td>
                          <td>
                            {moment
                              .utc(obj?.created_at)
                              .format("DD-MM-YYYY, h:mm:ss A")}
                          </td>
                          <td>{obj?.updated_by}</td>
                          <td>
                            {moment
                              .utc(obj?.updated_at)
                              .format("DD-MM-YYYY, h:mm:ss A")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr> NO RECORDS FOUND </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select class="form-control">
                      <option>10</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>User</label>
            <Select
              options={userList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  user_id: e,
                }));
              }}
              value={filterValue?.user_id}
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Year</label>
            <select
              className="form-control newbgselect main-pointer"
              name="year"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  year: e.target.value
                }));
              }}
              value={filterValue?.year}
            >
              <option value="" label="Select Year" hidden />
              <option
                value={moment(new Date()).subtract(1, "year").format("YYYY")}
                label={moment(new Date()).subtract(1, "year").format("YYYY")}
              >
                {moment(new Date()).subtract(1, "year").format("YYYY")}
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setFilterValue((prev) => ({
                ...prev,
                user_id: "",
                year: ""
              }));
              setFilterApplied(false);
              getRecords(
                searchStr,
                "",
                true,
                currentPage,
                pageSizeNo,
                sortData?.sort_by,
                true
              );
              filterclose();
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={() => {
          addnewclose();
          setFormObj({
            user: "",
            from_date: "",
            to_date: "",
          });
        }}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Leave Back Date Entry Exception</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      User<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={userList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="user"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                        setFormError((prev) => ({
                          ...prev,
                          userError: false,
                        }));
                      }}
                      onBlur={(event) => {
                        if (event.target.value === "" && formObj?.user === "") {
                          setFormError((prev) => ({
                            ...prev,
                            userError: true,
                          }));
                        }
                      }}
                      value={formObj?.user}
                    />
                    {formError?.userError === true ? (
                      <div className="small text-danger">Select User</div>
                    ) : null}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Period Open From<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="from"
                      onChange={(event) => {
                        handleChange(event, "period");
                        setFormError((prev) => ({
                          ...prev,
                          periodFromError: false,
                        }));
                      }}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          setFormError((prev) => ({
                            ...prev,
                            periodFromError: true,
                          }));
                        }
                      }}
                      value={formObj?.period_from}
                      min={minPeriod}
                      max={maxPeriod}
                    />
                    {formError?.periodFromError === true ? (
                      <div className="small text-danger">
                        Select Period Open From
                      </div>
                    ) : null}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Period Open To<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="from"
                      onChange={(event) => {
                        handleChange(event, "period_to");
                        setFormError((prev) => ({
                          ...prev,
                          periodToError: false,
                        }));
                      }}
                      value={formObj?.period_to}
                      min={minPeriod}
                      max={maxPeriod}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          setFormError((prev) => ({
                            ...prev,
                            periodToError: true,
                          }));
                        }
                      }}
                    />
                    {formError?.periodToError === true ? (
                      <div className="small text-danger">
                        Select Period Open To
                      </div>
                    ) : null}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      From Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="from"
                      onChange={(event) => {
                        handleChange(event, "from");
                        setFormError((prev) => ({
                          ...prev,
                          fromDateError: false,
                        }));
                      }}
                      value={formObj?.from_date}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          setFormError((prev) => ({
                            ...prev,
                            fromDateError: true,
                          }));
                        }
                      }}
                    />
                    {formError?.fromDateError === true ? (
                      <div className="small text-danger">Select From Date</div>
                    ) : null}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      To Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="to"
                      onChange={(event) => {
                        handleChange(event, "to");
                        setFormError((prev) => ({
                          ...prev,
                          toDateError: false,
                        }));
                      }}
                      value={formObj?.to_date}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          setFormError((prev) => ({
                            ...prev,
                            toDateError: true,
                          }));
                        }
                      }}
                    />
                    {formError?.toDateError === true ? (
                      <div className="small text-danger">Select To Date</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              addnewclose();
              setFormObj({
                user: "",
                from_date: "",
                to_date: "",
              });
            }}
          >
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={saveRecord}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LeaveBackDate;
