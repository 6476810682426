import axios from "../utils/axios";
// import { AUTH_BASE_URL_SURVEY } from "../constants/URL";
import { AUTH_BASE_URL } from "../constants/URL";

  export const getUserSubareaList = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/userSubarea?${queryParm}`);
  };
  export const getSubAreaUser = async () => {
    return axios.get(`${AUTH_BASE_URL}/userSubarea/get-user-assigned-subarea`);
  };
  export const postSubareaUser = async (pData) => {
    return axios.post(`${AUTH_BASE_URL}/userSubarea`,pData);
  };

  export const updateSubareaUser = async (id,uData) => {
    return axios.put(`${AUTH_BASE_URL}/userSubarea/${id}`, uData);
  };

  
  
export const deleteSubareaUser = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/userSubarea/${id}`,
    method: "DELETE",
    data: id,
  });
};

export const isUserSubExist = async (pData) => {
  return axios.post(`${AUTH_BASE_URL}/userSubarea/exists`,pData);
};

export const getUsersList = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-detail`
  );
};