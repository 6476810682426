import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Sidemenu from "../include/sidemenu";
import * as BrandService from "../../service/brandService";
import * as CapexService from "../../service/CapexService";
import * as SubAreaService from "../../service/subAreaServices";
// import * as LoanDataService from "../../service/loansalary";
import * as UserService from "../../service/userSubAreaService";

import Header from "../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ModalTitle,
  Badge
} from "react-bootstrap";
import Select from "react-select";
// import CapexAddForm from "./CapexAddForm";
import * as Yup from "yup";
import * as CmsNewsService from "../../service/Cms-newsService";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function UserRole() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const options = [
    { value: "News", label: "News" },
    { value: "Notice", label: "Notice" },
    { value: "Popup", label: "Popup" },
  ];
  const [filterdata, setfilterData] = useState({
    Type: "",
    from_date: "",
    to_date: "",
    status: false,
  });
  const [roles, setRoles] = useState({
    group_ids: [],
    user_id: "",
  });
  const [personalSubData, setPersonalSubData] = useState([]);
  const [subAreaDrop, setSubAreaDrop] = useState([]);
  const [userList, setUserList] = useState([]);
  const [subAreaDataSelected, setSubAreaDataSelected] = useState([]);
  const [Errororganisation, setErrororganisation] = useState(false);
  const [editData, seteditNotice] = useState({});
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [noticeData, setNoticeData] = useState([]);
  const [capexData, setCapexData] = useState([]);
  const [usersLocalCache, setUsersLocalCache] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [hasFormErrors, set_hasFormErrors] = useState(false);
  const [USER, setUSER] = useState("");
  
  const [formData, setFormData] = useState({
    user_id: "",
    sub_area_id: [],
    is_all_location: false
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [filterApplied, setFilterApplied] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [editValue, setEditValue] = useState([]);
  const [addnewshow, addnewsetShow] = useState(false);
  const [isLocationFeild, setIsLocationField] = useState(true);
  const [isVisibleSubPA, setIsVisibleSubPA] = useState(true);
  const addnewopen = () =>{ 
    setSubAreaDataSelected([]);
    addnewsetShow(true);
  }
  const addnewclose = () => addnewsetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewnewshow, viewnewsetShow] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);

  const userData = useSelector(selectUserData);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    is_sf: "",
    business_units_id: "",
    personal_area_id: "",
    sub_personal_area_id: "",
    designation_id: "",
    status: true,
    role: [],
    rm_id: [],
    hr_id: [],
    head_id: [],
    joining_date_from: "",
    joining_date_to: "",
    leaving_date_from: "",
    leaving_date_to: ""
  });
  const getData = (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    status
  ) => {
    dispatch(setDisplayLoader("Display"));

    CmsNewsService.getNotices(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      status
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setNoticeData(response?.data?.result);
        setpageCount(response?.data?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    // is_sf,   
    pageNo,
    page_size,
    sort_by,
    paginate,
    
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
   
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    // if (
    //   is_sf !== "" &&
    //   is_sf !== undefined &&
    //   is_sf !== null &&
    //   is_sf.length !== 0
    // ) {
    //   queryParm = queryParm + "&is_sf=" + is_sf;
    // }
   
    return queryParm;
  };
  const loadUserMasterList = (
    search,
    // sis_sf,
    pageNo,
    page_size,
    sort_by,
    paginate,
   
  ) => {
    // console.log(role, "role");
    // dispatch(setDisplayLoader("Display"));
    SubAreaService.getUserSubareaList(
      handleKey(
        search,
        // is_sf,           
        pageNo,
        page_size,
        sort_by,
        paginate,
       
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        setUserList(response?.data?.dataList?.result);
        console.log(response);
             
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        console.log(pageCount);
        
        // setShowForm(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getSubPersonalArea = (business_unit_id, personal_area_id) => {
    UserService.getSubPersonalFilter(business_unit_id, personal_area_id)
      .then((response) => {
        // setSubPersonalAreaData(response?.data?.dataList?.result);
              
        let data = [];
        response?.data?.dataList?.result?.map((x) => {
          // let data2 = { value: x.id, label: x.name };
          let data2 = { value: x.id, label: `${x.name}(${x.description})` };
          data.push(data2);
        });
        setSubAreaDrop(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getBranch = async () => {
    await BrandService.getBranch(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchDatacopy(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const set_USER = (data) => {
    console.log(`setting`, data);
    let mdoUser = usersDropdown.filter((x) => {
      return x.value == data;
    });

    setFormData({
      ...formData,
      user_id: data,
    });

    setUSER(mdoUser);
  };
  // const groupsRoles = (data) => {
  //   // roles.user_id = data;
  //   // roles.group_ids = rolesData;
  //   UserService.groupsRoles(roles)
  //     .then((response) => {
  //       if (response.data.status == true && response.data.code == 200) {
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.response?.data?.detail);
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.detail) ||
  //           "failed"
  //       );
  //     });
  // };
  const pushDept = (e) => {
    // let check = 0;
    console.log(e);
    
    let collectData = e?.map((value) => {
      if (value !== 0) {
        return value.value;
      }
    });
    setFormData({
      ...formData,
      sub_area_id: collectData,
    });
    setPersonalSubData(collectData);
    if (e?.length > 0) {
      setIsLocationField(false)
    }
    else{
      setIsLocationField(true)
    }
    setSubAreaDataSelected(e);
    setErrororganisation(false);
    
  };

  const onClickEdit = (data) => {
    addnewsetShow(true);
    setEditFlag(true);
    setEditValue(data);
    set_USER(data.user_id);
    let collectData = data?.subarea_user_details.map((value) => {           
      if (value !== 0) {
        return value.personal_sub_area_id;
      }
    });
    setFormData({
      ...formData,
      sub_area_id: collectData,
    });   
  };
    const handleChange = (e) =>{
      console.log(e.target.value);
      
      setFormData({
        ...formData,
        sub_area_id: [],
        is_all_location: e.target.value,

      });
      setIsVisibleSubPA(false)     
    }
  const onClose = () => {
    addnewclose();
    set_USER("");
    set_hasFormErrors(false);
    setEditFlag(false);
    setFormData({
      user_id: "",
      sub_area_id: [],
      is_all_location: false
    })
    setIsLocationField(true)
    setIsVisibleSubPA(true) 
  };

 
  
  const isUserSubExist = (iid, data) => {
    SubAreaService.isUserSubExist(data)
      .then((response) => {
        console.log(`isexis..`, response.data.dataList.is_exist);

        if (response.data.dataList.is_exist) {
          toast.error("User already exists.");
          return;
        } else if (iid == -1) {
          createUserPersonalSubArea(data);
        } else {
          updateUserSubarea(iid, data);
        }

        return response.data.dataList.is_exist;
      })
      .catch((error) => {
        toast.error("Error in checking record.");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteUser = (id) => {
    SubAreaService.deleteSubareaUser(id)
      .then((response) => {
        toast.success("Deleted successfully.");
        loadUserMasterList(
          searchStr,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          "",
          "",
          ""
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateUserSubarea = (id, udata) => {
    SubAreaService.updateSubareaUser(id, udata)
      .then((response) => {
        toast.success("Updated successfully.");
        loadUserMasterList(
          searchStr,
          // departmentDataFilter.is_sf,      
          1,
          pageSizeNo,
          sortData.sort_by,
          true
        );
        onClose();
      })
      .catch((error) => {
        toast.error("Could not update.");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const createUserPersonalSubArea = (data) => {
    SubAreaService.postSubareaUser(data)
      .then((response) => {
        toast.success("Created successfully.");
        loadUserMasterList(
          searchStr,
          // departmentDataFilter.is_sf,      
          1,
          pageSizeNo,
          sortData.sort_by,
          true
        );
        onClose();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submitSubAreaForm = () => {
    if (!formData.user_id) {
      set_hasFormErrors(true);
      return;
    }

    if (editFlag) {
      let dataa = {};
      dataa["user_id"] = formData.user_id;
      dataa["sub_area_id"] = formData.sub_area_id;
      dataa["is_all_location"] = formData.is_all_location;

      // console.log(dataa);
      // isUserSubExist(formData.user_id, dataa);
      updateUserSubarea(formData.user_id,dataa)
      
    } else {
      console.log(formData);
      
            // createUserPersonalSubArea(formData)
      isUserSubExist(-1, formData);
    }
  };

  const getApproverList = () => {
    UserService.getUsersList().then((res) => {
      // setApproverList(res?.data?.dataList?.result);
         
      let data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      data.push({
        value: userData?.id,
        label: `${userData?.first_name} ${userData?.last_name} (${userData?.username})`,
      });
      setUsersDropdown(data);

      let data2 = [
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            username: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      data2.push({
        value: userData?.id,
        label: `${userData?.first_name} ${userData?.last_name} (${userData?.username})`,
      });
      setUsersLocalCache(data2);
    });
  };

  const getUserFixedData = (createdby_id) => {
    // console.log(`user local data..`,usersLocalCache);

    for (let i = 0; i < usersLocalCache.length; i++) {
      // console.log('log..',usersLocalCache[i],createdby_id);

      if (Number(usersLocalCache[i].username) == Number(createdby_id)) {
        return usersLocalCache[i].label;
      }
    }

    return "";
  };

  
  useEffect(() => {
    // getRolesList(false);
    getSubPersonalArea()
    getApproverList();
  }, []);

  useEffect(() => {
    if (editValue) {
      Object.keys(editValue).forEach((item) => {
        formData[item] = editValue[item];
      });
      console.log(editValue);
      if(editValue.is_all_location === false){
        let groupData = editValue?.subarea_user_details?.map((res)=>{
          return {
            value: res?.personal_sub_area_id,
            label: res?.subarea_descriptions
          }
        })
        setSubAreaDataSelected(groupData);
      }
      else{
        setSubAreaDataSelected([]);
      }
      
      

    
    
      // console.log(editValue);
      
      setFormData({
        ...formData,
        is_all_location: editValue.is_all_location,
      });
      // console.log(formData.is_all_loction);
      
    }

    console.log(`edit..`, formData);
  }, [editValue, 1000]);

  useEffect(() => {
    
    loadUserMasterList(
      searchStr,
      // departmentDataFilter.is_sf,      
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [usersLocalCache]);

  //   useEffect(() => {

  //     getBranch();
  //     getData(
  //       currentPage,
  //       pageSizeNo,
  //       sortData.sort_by,
  //       searchStr,
  //       true,
  //       filterdata.Type,
  //       filterdata.from_date,
  //       filterdata.to_date,
  //       filterdata.status
  //     );
  //   }, [pageSizeNo]);
  const deleteNotice = (id) => {
    // let data = { id: id };
    CmsNewsService.deleteNotice(id)
      .then((res) => {
        getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "");
        if (res.data.message.includes("can't")) {
          toast.warning(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleShow = (e) => {
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   searchStr,
    //   true,
    //   filterdata.Type,
    //   filterdata.from_date,
    //   filterdata.to_date
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const submitDelete = (id, type) => {
    confirmAlert({
      title: "Delete",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    console.log(currentPage,activePage,pageSizeNo);
    setCurrentPage(activePage);
    loadUserMasterList(
      "",
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      
    );
    
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   "-id",
    //   "",
    //   true,
    //   filterdata?.Type,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   filterdata?.status
    // );

    loadUserMasterList(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.Type,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status
    );

    filterclose();
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      // getData(1, pageSizeNo, sortData.sort_by, "", true, "", "", "", "");
      loadUserMasterList(
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        ""
      );
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      searchStr,
      true,
      filterdata.Type,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // getData(
      //   currentPage,
      //   pageSizeNo,
      //   "-id",
      //   e.target.value.toLowerCase(),
      //   true,
      //   "",
      //   "",
      //   "",
      //   ""
      // );
      loadUserMasterList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        ""
      );
      setSearchStr(e.target.value);
    }
  };
  const clearfilter = () => {
    setFilterApplied(false);
    filterclose();
    loadUserMasterList(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      ""
    );
    setfilterData({
      Type: "",
      from_date: "",
      to_date: "",
      status: "",
    });
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">User Role Assignment(Location Wise)</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">CMS</Breadcrumb.Item>
                <Breadcrumb.Item active>News, Notice & Popup</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              {/* <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Title,
                        <br />
                        Display On
                      </strong>
                    </div>
                  </Tooltip>
                }
              > */}
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  id="searchbar"
                  type="text"
                  class="form-control"
                  placeholder="Search on User ID..."
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* </OverlayTrigger> */}
            </div>

            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {/* <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        style={{ border: 0, background: "none" }}
                        onClick={() => {
                          getCapexExport(searchStr, sortData.sort_by);
                        }}
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button onClick={filteropen} class="btn btn-secondary-inner" style={{
                  backgroundColor: filterApplied && "yellow",
                  color: filterApplied && userData?.dark_mode === false && "#000"
                }}>
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button> */}
                {/* {finalUrlValue?.A ? ( */}
                <button
                  class="btn btn-primary-inner"
                  onClick={() => {
                    setEditFlag(false);
                    setEditValue(null);
                    setFormData({
                      user_id: "",
                      sub_area_id: [],
                      is_all_location: false
                      });
                    addnewopen();
                  }}
                >
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                {/* <th
                  onClick={(e) => {
                    handlerData("Type");
                  }}
                  className={
                    sortData["Type"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["Type"]
                  }
                >
                  Type
                </th> */}
                <th>Sr.</th>
                <th>User ID</th>
                <th>Personal Sub-Area</th>
                <th>Status</th>
                <th>Created by</th>
                <th>Created on</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
             
              {userList?.length ? (
                userList?.map((x, index) => (
                  <tr>
                    {/* <td class="text-theme fw-bold">{x.Type}</td> */}
                    <td>{index + 1}</td>
                    <td>
                      {x?.user_name}
                    </td>
                    {/* <td className="text-theme fw-bold">
                              {x?.subarea_name?.join('')}
                    </td> */}
                    <td>
                            {(x?.subarea_name?.length !== 0 && x?.is_all_location == false) ? (
                              x?.subarea_name.map((itm) => (
                                <Badge bg="secondary">{itm}</Badge>
                              ))
                            ) : (
                              <p className="fw-bold">All Location</p>
                            )}
                          </td>
                          <td>
                      {x?.is_active
                        ? "Active"
                        : 'Deleted'}
                    </td>
                    <td>
                      {x?.created_by == "admin"
                        ? "Admin"
                        : getUserFixedData(x?.created_by) == ""
                          ? x?.created_by
                          : getUserFixedData(x?.created_by)}
                    </td>
                    
                    <td>
                      {" "}
                      {moment.utc(x?.created_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                   
                    
                    <td>
                      <button
                        class="btn btn-blanktd text-primary ml-2"
                        onClick={() => onClickEdit(x)}
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                                <i
                                  class="far fa-trash-alt text-danger"
                                  onClick={() => {
                                    submitDelete(x?.user_id)
                                  }}
                                ></i>
                        </button>
                    </td>

                    {finalUrlValue?.E || finalUrlValue?.R ? "" : ""}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>NO Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {userList?.length ? (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    handleShow(e);
                  }}
                  style={{ textAlign: "center" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Url Type</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  Type: e.target.value,
                }))
              }
              value={filterdata?.Type}
              name="Type"
              class="form-control newbgselect"
            >
              <option value={null}>Select Type</option>
              <option value="Internal">Internal</option>
              <option value="External">External</option>
              {/* <option value="Popup">Popup</option> */}
            </select>
          </div>
          {/* <div class="form-group innergroup">
            <label>From Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  from_date: e.target.value,
                }))
              }
              value={filterdata?.from_date}
              max={moment(filterdata.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  to_date: e.target.value,
                }))
              }
              value={filterdata?.to_date}
              min={moment(filterdata.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div> */}
          <div class="form-group innergroup">
            <label>Status</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              value={filterdata?.status}
              name="status"
              class="form-control newbgselect"
            >
              <option value="">Select Type</option>
              <option value={true}>Active</option>
              <option value={false}>In-Active</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearfilter()}>
            Clear
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit User Role </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div className="row">
                {/* {editFlag !== true && */}
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      User ID <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={usersDropdown}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        set_USER(e.value);
                       
                      }}
                      isDisabled={editFlag}
                      value={USER}
                    />
                    {hasFormErrors && !formData.user_id ? (
                      <div className="small text-danger">
                        User id is mandatory
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* } */}
               {
                isVisibleSubPA && (
                  <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Sub Personal Area<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="role"
                      id="role"
                      isMulti
                      options={subAreaDrop}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => {
                        pushDept(e);
                      }}
                      value={subAreaDataSelected}
                    />
                     {hasFormErrors && !formData.sub_area_id ? (
                <div className="small text-danger">
                  *Sub-Personal Area is required
                </div>
              ) : (
                ""
              )}
                    
                  </div>
                </div>
                ) 
               }
                      {
                        isLocationFeild && (
                          <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Select For All Location?
                            </label>
                            <select 
                            className="form-control"
                            name="is_all_location"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            >
                              <option value=''>Select...</option>                              
                              <option value="true" selected={formData.is_all_location}>Yes</option>
                            </select>
                             {hasFormErrors && !formData.is_all_location ? (
                              <div className="small text-danger">
                                *This is required
                              </div>
                      ) : (
                        ""
                      )}
                            
                          </div>
                        </div>
                        )
                      }
                    

                <div class="col-md-12 mt-2">
                  <button
                    class="btn btn-outline-secondary mr-3"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <i class="far fa-times-circle"></i> Cancel
                  </button>

                  <button
                    // hidden={isViewing}
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => submitSubAreaForm()}
                  >
                    <i class="far fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={viewnewshow}
        onHide={viewnewclose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row news-detail">
                <div class="col-md-12 mb-3 mb-sm-4">
                  <h2>
                    PI Industries Vice Chairman and MD Mayank Singhal's formula
                    for Consistent Growth
                  </h2>
                  <h4>
                    Mayank Singhal speaks as fast as he thinks. It reflects on
                    his company's growth
                  </h4>
                </div>
                <div class="col-md-5">
                  <img src="images/new-detail.jpg" alt="" />
                </div>
                <div class="col-md-7">
                  <p>
                    If you think you speak fast, you should meet Mayank Singhal.
                    The Vice Chairman and MD of PI Industries, a major player in
                    agrochemicals, can dumbfound you by his speed of thought and
                    speech. And his company's growth is a worthy match. More
                    important is the consistency. Despite the Covid-19 pandemic,
                    PI Industries has grown its revenue and profits each year
                    since 2018.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserRole;
