import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getUserList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/users?${queryParm}`);
};
export const getUserDetails = async (data) => {
  return axios.get(`${AUTH_BASE_URL}/users?employee_code=${data}`);
};
// getUserDetails
export const deleteUser = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/users`,
    method: "DELETE",
    data: id,
  });
};

export const saveUser = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users`, data);
};
export const updateUser = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/users`, data);
};
export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getBranch = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
  );
};
export const getUsersListData = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${id}`
  );
};

export const getUsersListDataNew = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details-new?bussiness_unit_id=${id}`
  );
};

export const getUsersHrListData = async () => {
  return axios.get(`${AUTH_BASE_URL}/usersuser-hr-dropdown`);
};

export const getUsersListDataByOrg = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${0}&organisation_id=${id}`
  );
};
export const getVendorCode = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/vendor-code/drop-down/${id}`);
};
export const getCostCenter = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/cost-center/drop-down/${id}`);
};

export const getDepartment = async (id) => {
  return axios.post(`${AUTH_BASE_URL}/department/list?branch_id=${id}`);
};

export const getCountryList = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/country?paginate=${paginate}&sort_by=name`
  );
};

export const getCountryListSurvey = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/country/survey?paginate=${paginate}&sort_by=name`
  );
}

export const getStateList = async (id) => {
  return axios.post(`${AUTH_BASE_URL}/state/list/${id}`);
};
export const getCityList = async (id) => {
  return axios.post(`${AUTH_BASE_URL}/city/drop-down/city/${id}`);
};
export const getDistrictList = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/city/drop-down/district/${id}`);
};
export const getPersonalArea = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/personal-area/drop-down/${id}`);
};
export const getSubPersonalArea = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/personal-sub-area/drop-down/${id}`);
};
export const getPersonalAreaByOrg = async (ids) => {
  return axios.get(`${AUTH_BASE_URL}/personal-area/listByOrg?${ids}`);
};

export const getSubPersonalAreaByOrg = async (ids) => {
  return axios.get(`${AUTH_BASE_URL}/personal-sub-area/subAreaByOrg/${ids}`);
};

export const getPersonalAreaByArea = async (ids) => {
  return axios.get(`${AUTH_BASE_URL}/personal-sub-area/subAreaByArea?${ids}`);
};

export const getAllDesignation = async (id) => {
  return axios.post(
    `${AUTH_BASE_URL}/designation/list?business_units_id=${id}`
  );
};

export const groupsRoles = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/groups/assign_group`, data);
};

export const getRolesList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/groups?paginate=${paginate}&sort_by=name`);
};

export const isUserDataExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/users/exists`, isData);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/users/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getUserListByBranch = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  search
) => {
  return axios.get(
    `${AUTH_BASE_URL}/users?business_units_id=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};

export const getUsersCount = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/users/users_count?${queryParm}`);
};

export const getUserByReporting = async (reportingId, paginate, sortBy, is_sf, status) => {
  if (status === undefined || status === null) {
    return axios.get(
      `${AUTH_BASE_URL}/users?reportingId=${reportingId}&sort_by=${sortBy}&paginate=${paginate}&is_sf=${is_sf}`
    );
  } else {
    return axios.get(
      `${AUTH_BASE_URL}/users?reportingId=${reportingId}&sort_by=${sortBy}&paginate=${paginate}&is_sf=${is_sf}&status=${status}`
    );
  }
};

export const saveAdUserToken = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/adToken`, data);
};

export const logoutUser = async () => {
  return axios.get(`${AUTH_BASE_URL}/auth/logout`);
};

// FIlter APIs

export const getDesignationFilter = async (
  business_unit_id,
  personal_area_id,
  sub_personal_area_id
) => {
  return axios.get(
    `${AUTH_BASE_URL}/usersuser-designation-filter?${business_unit_id == undefined ||
      business_unit_id == "" ||
      business_unit_id == null
      ? ""
      : `&business_unit_id=${business_unit_id}`
    }${personal_area_id == undefined ||
      personal_area_id == "" ||
      personal_area_id == null
      ? ""
      : `&personal_area_id=${personal_area_id}`
    }
    ${sub_personal_area_id == undefined ||
      sub_personal_area_id == "" ||
      sub_personal_area_id == null
      ? ""
      : `&sub_personal_area_id=${sub_personal_area_id}`
    }`
  );
};

export const getBranchFilter = async () => {
  return axios.get(`${AUTH_BASE_URL}/usersuser-business-unit-filter`);
};

export const getRolesFilter = async (
  business_unit_id,
  personal_area_id,
  sub_personal_area_id,
  designation_id
) => {
  return axios.get(
    `${AUTH_BASE_URL}/usersuser-role-filter?${business_unit_id == undefined ||
      business_unit_id == "" ||
      business_unit_id == null
      ? ""
      : `&business_unit_id=${business_unit_id}`
    }${personal_area_id == undefined ||
      personal_area_id == "" ||
      personal_area_id == null
      ? ""
      : `&personal_area_id=${personal_area_id}`
    }
    ${sub_personal_area_id == undefined ||
      sub_personal_area_id == "" ||
      sub_personal_area_id == null
      ? ""
      : `&sub_personal_area_id=${sub_personal_area_id}`
    }${designation_id == undefined ||
      designation_id == "" ||
      designation_id == null
      ? ""
      : `&designation_id=${designation_id}`
    }`
  );
};

export const getPersonalFilter = async (business_unit_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/usersuser-personal-area-filter?${business_unit_id == undefined ||
      business_unit_id == "" ||
      business_unit_id == null
      ? ""
      : `&business_unit_id=${business_unit_id}`
    }`
  );
};
export const getSubPersonalFilter = async (
  business_unit_id,
  personal_area_id
) => {
  return axios.get(
    `${AUTH_BASE_URL}/usersuser-sub-personal-area-filter?${business_unit_id == undefined ||
      business_unit_id == "" ||
      business_unit_id == null
      ? ""
      : `&business_unit_id=${business_unit_id}`
    }${personal_area_id == undefined ||
      personal_area_id == "" ||
      personal_area_id == null
      ? ""
      : `&personal_area_id=${personal_area_id}`
    }`
  );
};

export const userCertficationData = async () => {
  return axios.get(`${AUTH_BASE_URL}/users/user-certification`);
};

export const submitUserCertification = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/user-certification`, data);
};

export const getUserCertificationData = (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get_user_certification_data?${queryParm}`
  );
};
export const UserCertificationDataExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/users/get_user_certification_data/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
