import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import JivaTab from "../../assets/images/loginjiva.png";
import MyPiTab from "../../assets/images/loginmypi.png";
import Jivasmall from "../../assets/images/jivasmall.png";
import { Formik } from "formik";
import { ResetOTP } from "../../service/login";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import * as moment from "moment";

function Forget() {
  function formatDate(date) {
    return new Date(date).toLocaleDateString();
  }
  let flag=true
  const phoneRegExp = /^[0]?[6789]\d{9}$/;
  const validationSchema = Yup.object().shape({
    employee_id: Yup.string()
      .required("*Please enter valid Employee ID.")
      .max(8, "Max 8 digit Employee Id should be there"),
    email: Yup.string()
      .email("*Please enter Email ID")
      .required("*Please enter Email ID"),
    dob: Yup.date()
      .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
      .required("*Please enter Date of Birth."),
    mobile_no: Yup.string()
      .max(10, "Max 10 digit Mobile Number should be there")
      .required("*Please enter Mobile Number")
      .matches(phoneRegExp, "*Phone number is not valid"),
  });
  let navigate = useNavigate();
  const [logoValue, setLogoValue] = useState("");
  const [resetData, setResetData] = useState({
    employee_id: "",
    dob: "",
    email: "",
    mobile_no: "",
  });

  const handleLogo = (e) => {
    setLogoValue(e);
  };

  const SendOTP = (values) => {
    flag=false
    ResetOTP(values)
      .then((response) => {
        flag=true
        navigate("/get-otp");
      })
      .catch((error) => {
        flag=true
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    localStorage.setItem("username", values.employee_id);
  };
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={logoValue === "jiva" ? "login-bg login-bg2" : "login-bg"}>
        <div className="container">
          <div className="row justify-content-between my-3">
            <div className="col-md-6 col-lg-6 d-flex flex-column text-center text-sm-left">
              <div>
                <img src={Logo} alt="" height="55px"/>
              </div>
              <div class="mt-auto mb-auto">
                <h1>
                  Somewhere,
                  <br />
                  something
                </h1>
                <h2>incredible is waiting to be known.</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="loginTabs">
                <Formik
                  initialValues={resetData}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    SendOTP(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <div className="tabfliping">
                      <h3>Forgot Password!!</h3>
                      <form onSubmit={handleSubmit}>
                        <div
                          className="text-center"
                          onClick={() => window.open('https://passwordreset.microsoftonline.com', '_blank')}
                        >
                          <Link class="btn btn-primary btn-block azurelogin-btn">
                            Microsoft online(SSO users) password reset
                          </Link>
                        </div>
                        <div class="orpromocodeline">
                          <span>OR</span>
                        </div>
                        <div className="form-group mb-3">
                          <label>
                            Employee ID<span class="text-danger">*</span>
                          </label>
                          <input
                            id="endDate"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employee_id}
                            name="employee_id"
                            type="text"
                            className="form-control"
                            placeholder="Enter your Employee ID"
                          />
                          {touched.employee_id && errors.employee_id ? (
                            <div className="small text-danger">
                              {errors.employee_id}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>
                            Date of Birth<span class="text-danger">*</span>
                            {console.log(values.dob, "dob")}
                          </label>

                          <input
                            onBlur={handleBlur}
                            onChange={handleChange}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            value={values.dob}
                            id="dobCalender"
                            name="dob"
                            type="date"
                            style={{ cursor: "pointer" }}
                            className="form-control"
                            placeholder="Enter your email address"
                          />
                          {touched.dob && errors.dob ? (
                            <div className="small text-danger">
                              {errors.dob}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>
                            Email ID<span class="text-danger">*</span>
                          </label>
                          <input
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter your Email ID"
                          />
                          {touched.email && errors.email ? (
                            <div className="small text-danger">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>
                            Mobile Number<span class="text-danger">*</span>
                          </label>
                          <input
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.mobile_no}
                            name="mobile_no"
                            type="number"
                            className="form-control"
                            placeholder="Enter your Mobile Number"
                          />
                          {touched.mobile_no && errors.mobile_no ? (
                            <div className="small text-danger">
                              {errors.mobile_no}
                            </div>
                          ) : null}
                        </div>
                      <button
                         disabled={!flag}
                          type="submit"
                          // onClick={() => SendOTP(values)}
                          className="btn btn-block btn-primary"
                        >
                          Get OTP
                        </button>
                        <div className="text-center mt-4">
                          <Link to="/custom" className="forgettxt">
                            <i className="fas fa-chevron-left f-12"></i> Back to
                            Login
                          </Link>
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forget;
