import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { getReimbDec, submitReimburseDeclaration } from "./claimReimbServices";
import { toast } from "react-toastify";
import moment from "moment/moment";

function ReimbursementDec() {
  const userData = useSelector(selectUserData);
  const [renderOptions, setRenderOptions] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [submittedOn, setSubmittedOn] = useState("");

  useEffect(() => {
    let options = [];
    if (userData?.emp_sub_group !== null || userData?.emp_sub_group !== "") {
      if (!userData?.emp_sub_group.includes("F")) {
        options.push({
          label: "LTA",
          value: false,
          checked: false,
        });
      }
      if (
        userData?.emp_sub_group.includes("S") ||
        userData?.emp_sub_group.includes("T") ||
        userData?.emp_sub_group.includes("D") ||
        userData?.emp_sub_group === "M2"
      ) {
        options.push({
          label: "Meal",
          value: false,
          checked: false,
        });
        options.push({
          label: "Driver",
          value: false,
          checked: false,
        });
      }
      if (
        userData?.emp_sub_group.includes("M") ||
        userData?.emp_sub_group.includes("S") ||
        userData?.emp_sub_group.includes("D") ||
        userData?.emp_sub_group.includes("T")
      ) {
        options.push({
          label: "Fuel",
          value: false,
          checked: false,
        });
      }
    }
    getUserDeclaration(options);
  }, [userData]);

  const getUserDeclaration = (opt) => {
    getReimbDec(`userId=${userData?.id}`).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.dataList?.result?.length > 0) {
          setIsDisable(true);          
          let res = response?.data?.dataList?.result[0];
          if(new Date() <= new Date("2024-09-20") && new Date(res?.updated_at) <= new Date("2024-09-03")){
            setIsDisable(false);
          }
          setSubmittedOn(res?.updated_at);
          let data = JSON.parse(JSON.stringify(opt));
          data?.map((obj) => {
            obj.checked = res[obj?.label.toLowerCase()];
            obj.value = res[obj?.label.toLowerCase()];
          });
          setRenderOptions(data);
        } else {
          setRenderOptions(opt);
        }
      }
    });
  };

  const handleChange = (e, index) => {
    let data = JSON.parse(JSON.stringify(renderOptions));
    data[index].value = e.target.value === "true" ? true : false;
    data[index].checked = e.target.value === "true" ? true : false;
    setRenderOptions(data);
  };

  const submitForm = () => {
    let notChecked = renderOptions?.filter((each) => {
      if (each?.value === "") {
        return true;
      }
    });
    if (notChecked?.length === 0) {
      if (renderOptions?.length > 0) {
        let obj = {
          payload: renderOptions,
        };
        submitReimburseDeclaration(obj).then((response) => {
          if (response?.status === 200) {
            toast.success("Reimbursement Declaration Submitted.");
          }
        });
      } else {
        toast.error("You have no reimbursement declarations.");
      }
    } else {
      toast.error("Kindly declare all details.");
    }
  };

  return (
    <div class="col-md-12">
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">
                Reimbursement Component Declaration
              </h4>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="border-top mt-2 mb-3"></div>
        </div>
        <div class="innerheadsec ml-5">
          <div className="row">
            {(userData?.reimb_flag === true || isDisable===true) ?
            renderOptions?.length > 0
              ? renderOptions?.map((obj, i) => {
                return (
                  <div class="col-md-12">
                    <div>{obj?.label}</div>
                    <div class="form-group innergroup answerboxpollsuy">
                      <input
                        type="radio"
                        disabled={isDisable}
                        name={obj?.label}
                        value={true}
                        checked={obj?.checked === true}
                        onChange={(e) => {
                          handleChange(e, i);
                        }}
                      />
                      <label class="logCheck d-inline-block mr-4">
                        Taxable
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="radio"
                        disabled={isDisable}
                        name={obj?.label}
                        value={false}
                        checked={obj?.checked === false}
                        onChange={(e) => {
                          handleChange(e, i);
                        }}
                      />
                      <label class="logCheck d-inline-block mr-4">
                        Non-Taxable (As per Income Tax Act)
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                    <br />
                    
                            </div>
                );
              }
              ) 
              :
              ""
              : 
              <div className="thank-you-page" style={{textAlign: "center", marginTop: "50px", backgroundColor: "white"}}>
              <h1 style={{color: "#333", fontSize: "36px", marginBottom: "20px"}}>Declaration window is Closed, Please Contact HR Team !!</h1>
              {/* <p style={{color: "#666", fontSize: "18px"}}>We appreciate your feedback.</p> */}
              </div>
              }
              {(userData?.reimb_flag === true || isDisable===true) && (
              <div class="col-md-12"><button
                class="btn btn-primary-inner bpi-main"
                onClick={submitForm}
                disabled={isDisable}
              >
                Submit
              </button></div>
              )}
          </div>
          
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <span className="text-bold">Notes :-</span>
          <br />
          <ol className="ml-3">
            <li>
              Taxable component will be paid in monthly salary with necessary
              tax deduction.
            </li>
            <li>
              Non-Taxable component will be paid after submitting claims over
              payroll portal.
            </li>
            <li>
              Changes will be effective from 1st April 2024 or your Date of
              Joining whichever is later.
            </li>
            <li>
              This is one time declaration. You can’t change your declaration in
              this financial Year.
            </li>
          </ol>
        </div>
        <div className="col-md-2">
          {submittedOn != "" && (
            <>
              <span>Last Submitted On:</span>
              <br />
              <span>{moment.utc(submittedOn).format("DD-MM-YYYY, HH:mm:ss")}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReimbursementDec;
