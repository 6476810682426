import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as OtvService from "../../service/otvServices";
import * as otvServiceData from "../../service/otvServices";
import * as HeaderService from "../../service/jv_service";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import Select from "react-select";
import { Tooltip } from "react-tippy";

const ViewOtvNonpo = (props) => {
  const { viewDataId, onClose } = props;
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [trackerData, setTrackerData] = useState({
    permission_id: 0,
    transaction_id: 0,
    attachment_id: 0,
  });
  const [viewData, setViewData] = useState();
  const [rejecteData, setRejectData] = useState(false);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [businessPlaceValue, setBusinessPlaceValue] = useState([]);
  const [businessAreaValue, setBusinessAreaValue] = useState([]);
  const [businessAreaList, setBusinessAreaList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [plantValue, setPlantValue] = useState([]);
  const [businessPlaceList, setBusinessPlaceList] = useState([]);
  const [costCenterDisable, setCostCenterDisable] = useState(false);
  const [file, setfiles] = useState({});
  const [imgSize, setImgSize] = useState(false);
  const [uploadDataIm, setUploadDataIm] = useState({});
  const [link, setLink] = useState();
  const [gSTListData, setGSTListData] = useState();
  const [gSTListValue, setGSTListValue] = useState();
  const [tdsListData, setTdsListData] = useState();
  const [tdsListValue, setTdsListValue] = useState();
  const [remarksData, setRemarksData] = useState({
    remarks: "",
  });
  const [viewIdEdit, setViewIdEdit] = useState();
  const [companyCode, setCompanyCode] = useState("");
  const [approveList, setApproveList] = useState();
  const [currentApprover, setCurrentApprover] = useState();
  const [formValidation, setFormValidation] = useState({
    remarks: "",
    gst_code_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { state } = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const pathname = window.location.pathname;
  const pathId = pathname.split("/")[2];
  const [attachment, setAttachment] = useState([]);
  const [approveUploadData, setApproveUploadData] = useState({
    attachment: {},
  });
  const [viewOtvData, setViewOtvData] = useState({
    vendor_id: 0,
    street: "",
    otv_name: "",
    otv_city_name: "",
    state_id: 0,
    business_place_id: 0,
    business_area_id: 0,
    plant_id: 0,
    organisation_id: 0,
    country_id: 0,
    city_id: 0,
    postal_code: "",
    invoice_number: "",
    type: "",
    invoice_date: "",
    gross_amount: 0,
    expense_category_id: 0,
    gl_id: 0,
    tds_code_id: 0,
    gst_code_id: 0,
    cost_center_id: 0,
    service_desc: "",
    bank_name: "",
    bank_account_number: "",
    bank_ifsc_code: "",
    attachments: [],
    company_code:"",
  });
  const [error, setError] = useState({
    vendor_id: "",
    invoice_number: "",
    invoice_date: "",
    gross_amount: "",
    expense_category_id: "",
    gl_id: "",
    service_desc: "",
    cost_center_id: "",
  });

  const getOtvViewData = (id) => {
    otvServiceData
      .getOtvViewData(id)
      .then((response) => {
        response?.data?.dataList?.result?.map((value) => {
          setViewData(value);
          let rejectedData = value?.approver_rejected_data;
          const hasRejectedPr = rejectedData.some(item => item.status === 'D');
          setRejectData(hasRejectedPr);          
          let temp = {
            value: value?.business_place_id,
            label: value?.business_place_description + "(" + value?.business_place_code + ")"
          }
          setBusinessPlaceValue(temp)
        });
        let obj = response?.data?.dataList?.result
        if(obj?.length > 0) {
          let is_exception = obj[0]?.gl_is_exception
          setCostCenterDisable(is_exception)
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGSTList = () => {
    otvServiceData
      .getGSTList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.section_description + "(" + res.gst_code + ")",
          };
        });
        setGSTListData(data);
        getTDSList(id);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getTDSList = (otv_id) => {
    otvServiceData
      .getTDSList(otv_id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label:
              res.tds_code +
              " " +
              res.applicable_tds_section +
              "(" +
              res.description +
              ")",
          };
        });
        setTdsListData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateTdsAndGSt = (id, data, value) => {
    otvServiceData
      .updateTdsAndGSt(id, data)
      .then((response) => {
        saveRemarksData(value);
        console.log(response, "updated");
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onPushTDS = (newValue, name) => {
    setTdsListValue(newValue);
    setViewOtvData((prev) => ({
      ...prev,
      tds_code_id: newValue.value,
    }));
  };
  const onPushGST = (newValue, name) => {
    setGSTListValue(newValue);
    setViewOtvData((prev) => ({
      ...prev,
      gst_code_id: newValue.value,
    }));
  };

  let navigate = useNavigate();

  const saveRemarksData = (value) => {
    setIsLoading(true);
    let approveRemarks = { approval_data: [] };
    let data = {
      otv_id: viewIdEdit,
      status: value,
      remarks: remarksData.remarks,
    };
    approveRemarks.approval_data.push(data);
    console.log(approveRemarks);
    OtvService.saveApprovalData(approveRemarks)
      .then((response) => {
        setIsLoading(false);
        if (location.state?.flag === true) {
          navigate("/otv-approval");
        } else if (location.state?.flag === 1) {
          navigate("/otv-vendor-request");
        } else {
          navigate(`/common-approve/${location?.state?.subId}`, {
            state: {
              subtypeid: location?.state?.subId,
              subTypeName: "OTV",
            },
          });
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = async (e) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await HeaderService.uploadImages(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    }
    setErrorAttachment(false);
    // setAttachment([]);
    // setflag(true);
    setUploadDataIm(files);
  };

  const addAttachmentData = (id, data) => {
    OtvService.addAttachmentData(id, data)
      .then((response) => {
        setAttachment(response.data.dataList.attachment);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessAreaList = (id) => {
    HeaderService.getBusinessAreaList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        setBusinessAreaList(data);
        getPlantList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPlantList = (id) => {
    HeaderService.getPlantList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessPlaceList = (id) => {
    HeaderService.getBusinessPlaceList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.description + ")",
          };
        });
        setBusinessPlaceList(data);
        getBusinessAreaList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    setApproveUploadData({ ...approveUploadData, attachment: uploadDataIm[0] });
  }, [uploadDataIm]);

  useEffect(() => {
    if (uploadDataIm.length > 0) {
      addAttachmentData(pathId, approveUploadData);
    }
  }, [approveUploadData]);

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!remarksData.remarks) {
      isError = true;
      formerr.remarks = "Remarks Required";
      setFormValidation(formerr);
    }
    console.log(
      !remarksData.gst_code_id &&
        approveList["SSC AP Processor"] &&
        approveList["SSC AP Processor"].approver_name === userData?.full_name &&
        approveList["SSC AP Processor"].status !== "A"
    );
    if (
      !viewOtvData.gst_code_id &&
      approveList &&
      approveList["SSC AP Processor"] &&
      approveList["SSC AP Processor"].approver_name === userData?.full_name &&
      approveList["SSC AP Processor"].status !== "A"
    ) {
      isError = true;
      formerr.gst_code_id = "GST Code Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const handleSaveValue = (value) => {
    if (handleValidate()) return false;
    if (
      approveList &&
      approveList["SSC AP Processor"] &&
      approveList["SSC AP Processor"].approver_name === userData?.full_name &&
      approveList["SSC AP Processor"].status !== "A"
    ) {
      updateTdsAndGSt(viewOtvData.id, viewOtvData, value);
    } else {
      saveRemarksData(value);      
    }
  };

  const deleteAttachment = (id) => {
    let data = { ...file };
    Object.entries(data).map((key, value) => {
      let val = key[0];
      if (val == id) {
        delete data[val];
      }
    });
    setfiles(data);
  };

  const referToSscProcessor = () =>{
    let approveRemarks = { approval_data: [] };
    let data = {
      otv_id: viewIdEdit,
      status: "RB",
      remarks: remarksData.remarks,
    };
    approveRemarks.approval_data.push(data);
    OtvService.referToSsc(approveRemarks).then((response)=>{
      toast.success("Referred to SSC Processor")
      window.history.go(-1)
    })
  }

  const attachments123 = (index) => {
    let data = [];
    let data2 = file[0] != undefined ? file[0] : "";
    data =
      file[0] != undefined ? (
        <>
          <div class="uploaded-image mr-1">
            <a href={data2} target={"_blank"} rel="noreferrer">
              {data2?.name?.includes(".pdf") ? (
                <img
                  src={"/images/policy-icon/pdf.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".xlsx") ? (
                <img
                  src={"/images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".xls") ? (
                <img
                  src={"/images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".doc") ? (
                <img
                  src={"/images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".docs") ? (
                <img
                  src={"/images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".pptx") ? (
                <img
                  src={"/images/policy-icon/ppt.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name?.includes(".mp4") ? (
                <img
                  src={"/images/policy-icon/video.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img src={data2} alt="pic" width="70px" height="70px" />
              )}
            </a>
            <button
              type="button"
              onClick={(e) => {
                deleteAttachment(index);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      ) : (
        ""
      );
    return data;
  };

  useEffect(() => {
    getBusinessPlaceList();
    if (id !== undefined) {
      getOtvViewData(id);
    } else {
      getOtvViewData(pathId);
    }
    setViewIdEdit(pathId);
  }, [id, pathId]);

  useEffect(() => {
    if (viewDataId !== undefined) {
      getOtvViewData(viewDataId);
    }
  }, [viewDataId]);

  useEffect(() => {
    getGSTList();
  }, []);

  useEffect(() => {
    if (viewData) {
      setErrorAttachment(false);
      Object.keys(viewData).forEach((item) => {
        viewOtvData[item] = viewData[item];
      });
      let jsonData = {};
      viewData?.approver_listing?.map((obj, i) => {
        jsonData[obj.approver_position] = obj;
      });
      setApproveList(jsonData);
      setCurrentApprover(viewData?.current_approver_data)
    }
  }, [viewData]);

  const pushDataBusinessArea = (newValue, name) => {
    setBusinessAreaValue(newValue);
    handleChange(newValue, name);
    let string = newValue?.label?.split('(')[1];
    let code = string.substring(0, string.length-1)
    let company_code = ""
        if(code === "PIAG"){
          company_code = 'PIIL'
        }
        else if(code === "PIJA"){
          company_code = 'PIJA'
        }
        else if(code === "PKAG"){
          company_code = 'PIKM'
        }
    setCompanyCode(company_code)
  };

  const pushDataBusinessPlace = (newValue, name) => {
    setBusinessPlaceValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataPlant = (newValue, name) => {
    setPlantValue(newValue);
    handleChange(newValue, name);
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "business_place_id":
        setViewOtvData((prev) => ({
          ...prev,
          business_place_id: event.value,
        }));
        break;
      case "business_area_id":
        setViewOtvData((prev) => ({
          ...prev,
          business_area_id: event.value,
        }));
        let string = event?.label?.split('(')[1];
        let code = string.substring(0, string.length-1)
        let company_code = ""
        if(code === "PIAG"){
          company_code = 'PIIL'
        }
        else if(code === "PIJA"){
          company_code = 'PIJA'
        }
        else if(code === "PKAG"){
          company_code = 'PIKM'
        }
        setViewOtvData((prev) => ({
          ...prev,
          company_code: company_code
        }));
        break;
      case "plant_id":
        setViewOtvData((prev) => ({
          ...prev,
          plant_id: event.value,
        }));
        break;
      default:
        break;
    }
  };

  useEffect(()=>{
   let data = {
      permission_id: finalUrlValue?.V?.id,
      transaction_id: pathId
    }
    dispatch(setTracker(data));
  }, [])

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    {viewData?.type === "OTV"
                      ? "One Time Vendor Payment Request"
                      : viewData?.type === "NONPO"
                      ? " Non PO Vendor Payment Request"
                      : "Vendor Invoice Reimb below 5K"}
                  </h4>
                  {/* <Breadcrumb>
               <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
               <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
               <Breadcrumb.Item active>
                 OTV Payment Request
               </Breadcrumb.Item>
             </Breadcrumb> */}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <div class="px-4 pb-2">
                <h5 class="text-theme border-bottom pb-2 mb-3">
                  <strong>Vendor Details</strong>
                </h5>

                <div class="bg-light px-3 pt-3 mb-3">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Vendor Name</label>
                        <input
                          type="text"
                          class="form-control"
                          name="vendor-name"
                          disabled
                          value={
                            viewData?.otv_name === null
                              ? viewData?.vendor_name
                              : viewData?.otv_name
                          }
                        />
                      </div>
                    </div>
                    {viewData?.type === "OTV" ? (
                      <>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              House Number & Street
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="street"
                              disabled
                              placeholder="Enter House Number & Street"
                              value={viewData?.street}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Postal Code<span class="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              name="postal_code"
                              disabled
                              placeholder="Enter Postal Code"
                              value={viewData?.postal_code}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Country<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="country"
                              disabled
                              placeholder="Enter Country Code"
                              value={viewData?.country_name}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              State<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="state"
                              disabled
                              placeholder="Enter State Code"
                              value={viewData?.state_name}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              City<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="city"
                              disabled
                              placeholder="Enter City Code"
                              value={
                                viewData?.city_name !== null ||
                                viewData?.city_name !== undefined
                                  ? viewData.otv_city_name
                                  : viewData?.city_name
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Vendor Code</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={viewData?.vendor_code}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>GST</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={viewData?.vendor_gst}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Location</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={viewData?.vendor_location}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <h5 class="text-theme border-bottom pb-2 mb-3">
                  <strong>Invoice Details</strong>
                </h5>

                <div class="bg-light px-3 pt-3 mb-3">
                  <div class="row">
                    {approveList &&
                    approveList["SSC AP Processor"] &&
                    approveList["SSC AP Processor"].approver_name ===
                      userData?.full_name &&
                    approveList["SSC AP Processor"].status !== "A" ? (
                      <>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Invoice No"
                              name="invoice_number"
                              onChange={(e) =>
                                setViewOtvData({
                                  ...viewOtvData,
                                  invoice_number: e.target.value,
                                })
                              }
                              value={viewOtvData?.invoice_number}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice Date<span class="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter Invoice Date"
                              name="invoice_date"
                              onChange={(e) =>
                                setViewOtvData({
                                  ...viewOtvData,
                                  invoice_date: e.target.value,
                                })
                              }
                              value={viewOtvData?.invoice_date}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Invoice No"
                              name="invoice_number"
                              disabled
                              value={viewData?.invoice_number}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice Date<span class="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter Invoice Date"
                              name="invoice_date"
                              disabled
                              value={viewData?.invoice_date}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {approveList &&
                    approveList["SSC AP Processor"] &&
                    approveList["SSC AP Processor"].approver_name ===
                      userData?.full_name &&
                    approveList["SSC AP Processor"].status !== "A" ? (
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Base Amount (Excluding Taxes){" "}
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Base Amount"
                            name="gross_amount"
                            onChange={(e) =>
                              setViewOtvData({
                                ...viewOtvData,
                                gross_amount: e.target.value,
                              })
                            }
                            value={viewOtvData?.gross_amount}
                          />
                        </div>
                      </div>
                    ) : (
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Base Amount (Excluding Taxes){" "}
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Base Amount"
                            name="gross_amount"
                            disabled
                            value={viewData?.gross_amount}
                          />
                        </div>
                      </div>
                    )}
                    {viewData?.type !== "NONREIMB" && (
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Expense Category
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Expense Category"
                            name="expense_name"
                            disabled
                            value={viewData?.expense_name}
                          />
                        </div>
                      </div>
                    )}

                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>GL Code</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter GL Code"
                          name="gl_code"
                          disabled
                          value={`${viewData?.gl_code} (${viewData?.gl_desc})`}
                        />
                      </div>
                    </div>
                    {
                      (costCenterDisable && (
                        <>
                        {viewData?.business_place_id === null ?
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                BUSINESS PLACE{" "}
                             <span class="text-danger">*</span>
                              </label>
                              <Select
                                options={businessPlaceList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="business_place_id"
                                onChange={(newValue, action) => {
                                  pushDataBusinessPlace(
                                    newValue,
                                    action?.name
                                  );
                                  setError({
                                    ...error,
                                    business_place_id: "",
                                  });
                                }}
                                value={businessPlaceValue}
                               
                              />
                             
                            </div>
                          </div>
                          : currentApprover?.approver_position === "SSC AP Processor" ?
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                BUSINESS PLACE{" "}
                             <span class="text-danger">*</span>
                              </label>
                              <Select
                                options={businessPlaceList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="business_place_id"
                                onChange={(newValue, action) => {
                                  pushDataBusinessPlace(
                                    newValue,
                                    action?.name
                                  );
                                  setError({
                                    ...error,
                                    business_place_id: "",
                                  });
                                }}
                                value={businessPlaceValue}
                               
                              />
                             
                            </div>
                          </div>
                          :
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                BUSINESS PLACE{" "}
                             <span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Business Place"
                                name="business_place_id"
                                disabled
                                value={`${viewData?.business_place_description} (${viewData?.business_place_code})`}
                              />
                            </div>
                          </div>
                          }
                      {viewData?.business_area_id === null ?
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                BUSINESS AREA<span class="text-danger">*</span>
                              </label>
                              <Select
                                options={businessAreaList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="business_area_id"
                                onChange={(newValue, action) => {
                                  pushDataBusinessArea(
                                    newValue,
                                    action?.name
                                  );
                                  setError({
                                    ...error,
                                    business_area_id: "",
                                  });
                                }}
                                value={businessAreaValue}
                               
                              />
                             
                            </div>
                          </div>
                            :
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>
                                  BUSINESS AREA{" "}
                              <span class="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Business Area"
                                  name="business_area_id"
                                  disabled
                                  value={`${viewData?.business_area_description} (${viewData?.business_area_code})`}
                                />
                              </div>
                            </div>
                            }
                        {viewData?.organisation_id === null ?
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>COMPANY CODE</label>
                              <input
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Please search COMPANY CODE"
                                value={companyCode}
                              />
                            </div>
                          </div>
                          :
                          <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>COMPANY CODE</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search COMPANY CODE"
                              value={viewData?.organisation_code}
                            />
                          </div>
                        </div>
                        }

                    {viewData?.plant_id === null ?
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Plant<span class="text-danger">*</span>
                              </label>
                              <Select
                                options={plantList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="plant_id"
                                onChange={(newValue, action) => {
                                  pushDataPlant(newValue, action?.name);
                                  setError({ ...error, plant_id: "" });
                                }}
                                value={plantValue}
                               
                              />
                              
                            </div>
                          </div>
                          :
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                              Plant{" "}
                             <span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Plant"
                                name="plant_id"
                                disabled
                                value={`${viewData?.plant_description} (${viewData?.plant_code})`}
                              />
                            </div>
                          </div>
                          }
                        </>
                      ))}

                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Cost Center<span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Cost Center"
                          name="cost_center_name"
                          disabled
                          value={`${viewData?.cost_center_code} (${viewData?.cost_center_name})`}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Description of Service/Material<span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Description of Service/Material"
                          name="service_desc"
                          disabled
                          value={viewData?.service_desc}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {viewData?.type === "OTV" && (
                  <>
                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Bank Details</strong>
                    </h5>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Bank Name<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Bank Name"
                              name="bank_name"
                              disabled
                              value={viewData?.bank_name}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Bank A/c No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Bank A/c No"
                              name="bank_account_number"
                              disabled
                              value={viewData?.bank_account_number}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              IFSC Code<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter IFS Code "
                              name="bank_ifsc_code"
                              disabled
                              value={viewData?.bank_ifsc_code}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <>
                  <h5 class="text-theme border-bottom pb-2 mb-3">
                    <strong>SAP Details</strong>
                  </h5>

                  <div class="bg-light px-3 pt-3 mb-3">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Sap Doc. No.<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter"
                            name="sap_doc_no"
                            disabled
                            value={viewData?.sap_doc_no}
                          />
                        </div>
                      </div>

                      <div class="col-md-8">
                        <div class="form-group innergroup">
                          <label>
                            Sap Message<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter"
                            name="sap_message"
                            disabled
                            value={viewData?.sap_message}
                          />
                        </div>
                      </div>

                      {/* <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              IFSC Code<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter IFS Code "
                              name="bank_ifsc_code"
                              disabled
                              value={viewData?.bank_ifsc_code}
                            />
                          </div>
                        </div> */}
                    </div>
                  </div>
                </>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured ">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Approver Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Date & Time</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData?.approver_listing?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item?.approver_name}</td>
                          <td>{item?.approver_position}</td>
                          <td>
                            {item?.status === "A"
                              ? "Approved"
                              : item?.status === "D"
                              ? "Rejected"
                              : "Pending"}
                          </td>
                          <td>
                            {(item?.status === "A" || item?.status === "D") &&
                              moment(item?.updated_at)
                                .utc()
                                .format("DD-MM-YYYY hh:mm a")}
                          </td>
                          {item?.remark?.length > 45 ?
                          <td>
                          <Tooltip position="bottom" title={item?.remark}>
                          {item?.remark?.substr(0,45) + "..."}
                          </Tooltip>
                          </td>
                          :
                          <td>{item?.remark}</td>
                          }
                        </tr>
                      ))}

                      {/*                  
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
                {(viewData?.approver_rejected_data.length > 0 && rejecteData) && (
                <div class="table-responsive">
                   <h5 class="text-theme border-bottom pb-2 mb-3">
                    <strong>OTV History Details</strong>
                  </h5>
                  <table class="table table-striped table-bordered tablecured ">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Approver Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Date & Time</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewData?.approver_rejected_data.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item?.approver_name}</td>
                          <td>{item?.approver_position}</td>
                          <td>
                            {item?.status === "A"
                              ? "Approved"
                              : item?.status === "D"
                              ? "Rejected"
                              : "Pending"}
                          </td>
                          <td>
                            {(item?.status === "A" || item?.status === "D" || item?.status === "P" ) &&
                              moment(item?.updated_at)
                                .utc()
                                .format("DD-MM-YYYY hh:mm a")}
                          </td>
                          {item?.remark?.length > 45 ?
                          <td>
                          <Tooltip position="bottom" title={item?.remark}>
                          {item?.remark?.substr(0,45) + "..."}
                          </Tooltip>
                          </td>
                          :
                          <td>{item?.remark}</td>
                          }
                        </tr>
                      ))}

                      {/*                  
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
                )}
                {location.state?.flag === 2 && (
                  <>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>TDS Code</label>
                          <input
                            class="form-control"
                            placeholder="Enter"
                            name="tds_code_id"
                            disabled
                            value={
                              viewData?.tdscode === null
                                ? ""
                                : viewData?.tdscode +
                                    " " +
                                    viewData?.tds_applicable_section ===
                                  null
                                ? ""
                                : viewData?.tds_applicable_section +
                                    "(" +
                                    viewData?.tds_desc ===
                                  null
                                ? ""
                                : viewData?.tds_desc + ")"
                            }
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            GST Code<span class="text-danger">*</span>
                          </label>
                          <input
                            class="form-control"
                            placeholder="Enter"
                            name="gst_code"
                            disabled
                            value={
                              viewData?.gst_section_desc === null
                                ? ""
                                : viewData?.gst_section_desc
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <h5 class="text-theme border-bottom pb-2 mb-3">
                  <strong>Attachments</strong>
                </h5>

                <div class="bg-light px-3 pt-3 mb-3">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group innergroup position-relative modaldefaultclose ">
                        <label>
                          Invoice Attachment
                          <span class="text-danger">*</span>
                        </label>
                        <>
                        {viewData?.attachments?.filter((each)=>{if(each?.type==="invoice"){return true}}).map((data)=>(
                          <p>
                            {/* <p>{item?.attachment}</p> */}
                            <br />
                            <span class="mr-3">
                              {/* <i class="fas fa-file-download"></i> */}
                              <a
                                href={data?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {data?.attachment_path.includes(
                                  ".pdf"
                                ) ? (
                                  <i class="fas fa-file-pdf fa-3x"></i>
                                ) : data?.attachment_path.includes(
                                    ".xlsb"
                                  ) ||
                                  data?.attachment_path.includes(
                                    ".xlsx"
                                  ) ||
                                  data?.attachment_path.includes(
                                    ".csv"
                                  ) ? (
                                  <img
                                    src={data?.attachment_path}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : data?.attachment_path.includes(
                                    ".doc"
                                  ) ||
                                  data?.attachment_path.includes(
                                    ".docs"
                                  ) ||
                                  data?.attachment_path.includes(
                                    ".docx"
                                  ) ? (
                                  <img
                                    src={data?.attachment_path}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : data?.attachment_path.includes(
                                    ".jpg"
                                  ) ||
                                  data?.attachment_path.includes(
                                    ".png"
                                  ) || 
                                  data?.attachment_path.includes(
                                    ".PNG"
                                  )
                                  ? (
                                    <i class="fas fa-file-image fa-3x"></i>
                                ) : (
                                  ""
                                )}
                              </a>
                            </span>
                          </p>
                          ))}
                        </>
                      </div>
                    </div>
                    {viewData?.type === "OTV" && (
                      <div class="col-md-4">
                        <div class="form-group innergroup position-relative modaldefaultclose ">
                          <label>
                            Cheque Attachment
                            <span class="text-danger">*</span>
                          </label>
                          {viewData?.attachments[1] !== undefined ? (
                            <>
                              <p>
                                <br />
                                <span class="mr-3">
                                  <i class="fas fa-file-download"></i>
                                  <a
                                    href={
                                      viewData?.attachments[1]?.attachment_path
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {viewData?.attachments[1]?.attachment_path.includes(
                                      ".pdf"
                                    ) ? (
                                      <img
                                        src={"/images/policy-icon/pdf.png"}
                                        alt="image"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : viewData?.attachments[1]?.attachment_path.includes(
                                        ".xlsb"
                                      ) ||
                                      viewData?.attachments[1]?.attachment_path.includes(
                                        ".xlsx"
                                      ) ||
                                      viewData?.attachments[1]?.attachment_path.includes(
                                        ".csv"
                                      ) ? (
                                      <img
                                        src={"/images/policy-icon/xlsx.png"}
                                        alt="image"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : viewData?.attachments[1]?.attachment_path.includes(
                                        ".doc"
                                      ) ||
                                      viewData?.attachments[1]?.attachment_path.includes(
                                        ".docs"
                                      ) ||
                                      viewData?.attachments[1]?.attachment_path.includes(
                                        ".docx"
                                      ) ? (
                                      <img
                                        src={"/images/policy-icon/docs.png"}
                                        alt="image"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : viewData?.attachments[1]?.attachment_path.includes(
                                        ".jpg"
                                      ) ||
                                      viewData?.attachments[0]?.attachment_path.includes(
                                        ".png"
                                      ) ? (
                                      <img
                                        src={"/images/policy-icon/img.png"}
                                        alt="image"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </a>
                                </span>
                              </p>
                            </>
                          ) : (
                            <p>
                              {" "}
                              <br />
                              There is no attachment
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="col-md-4">
                      <div class="form-group innergroup position-relative modaldefaultclose ">
                        <label>Other Attachment</label>
                        {viewData?.attachments?.length>0 ?
                        viewData?.attachments?.filter((each)=>{if(each?.type==="other"){return true}}).map((data)=>(
                          <>
                            <p>
                              <br />
                              <span class="mr-3">
                                {/* <i class="fas fa-file-download"></i> */}
                                <a
                                  href={
                                    data?.attachment_path
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {data?.attachment_path.includes(
                                    ".pdf"
                                  ) ? (
                                    <i class="fas fa-file-pdf fa-3x"></i>
                                  ) : data?.attachment_path.includes(
                                      ".xlsb"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".xlsx"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".csv"
                                    ) ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : data?.attachment_path.includes(
                                      ".doc"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".docs"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".docx"
                                    ) ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : data?.attachment_path.includes(
                                      ".jpg"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".png"
                                    ) ? (
                                      <i class="fas fa-file-image fa-3x"></i>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </span>
                            </p>
                          </>
                          ))
                         : (
                          <p>
                            {" "}
                            <br />
                            There is no attachment
                          </p>
                        )}
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group innergroup position-relative modaldefaultclose ">
                        <label>SSC Invoice Attachment</label>
                        {viewData?.attachments?.length>0 ?
                        viewData?.attachments?.filter((each)=>{if(each?.type==="SSC"){return true}}).map((data)=>(
                          <>
                            <p>
                              <br />
                              <span class="mr-3">
                                {/* <i class="fas fa-file-download"></i> */}
                                <a
                                  href={
                                    data?.attachment_path
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {data?.attachment_path.includes(
                                    ".pdf"
                                  ) ? (
                                    <i class="fas fa-file-pdf fa-3x"></i>
                                  ) : data?.attachment_path.includes(
                                      ".xlsb"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".xlsx"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".csv"
                                    ) ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : data?.attachment_path.includes(
                                      ".doc"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".docs"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".docx"
                                    ) ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : data?.attachment_path.includes(
                                      ".jpg"
                                    ) ||
                                    data?.attachment_path.includes(
                                      ".png"
                                    ) ? (
                                      <i class="fas fa-file-image fa-3x"></i>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </span>
                            </p>
                          </>
                          ))
                         : (
                          <p>
                            {" "}
                            <br />
                            There is no attachment
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                

                {approveList &&
                location.state?.flag === false &&
                approveList["SSC AP Processor"] &&
                // approveList["SSC AP Processor"].approver_name ===
                //   userData?.full_name &&
                approveList["SSC AP Processor"].status !== "A" ? (
                  <>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group innergroup position-relative modaldefaultclose ">
                          <label>SSC Invoice Attachment</label>
                          <input
                            type="text"
                            class="form-control bg-white"
                            disabled
                            placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                          />
                          {errorAttachment ? (
                            <div className="small text-danger">
                              *Attachment is required
                            </div>
                          ) : null}
                          <div class="upload-btn-wrapper up-loposition">
                            <button class="uploadBtn">Browse</button>
                            <input
                              type="file"
                              id="attachmentsFile"
                              accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                              onChange={(e) => onProfileChange(e)}
                            />
                          </div>
                          {imgSize ? (
                            <div className="small text-danger">
                              *File size should not exceed 1mb
                            </div>
                          ) : null}
                          <p>
                            Selected files
                            {[file].map((a) => (
                              <span>
                                <br />
                                {a.name}
                              </span>
                            ))}
                          </p>
                          {Object.keys(file)?.length !== 0 ? (
                            <div className="d-flex">{attachments123(0)}</div>
                          ) : link ? (
                            <>
                              <div class="d-flex">
                                <div class="uploaded-image mr-1">
                                  <i class="" style={{ width: "20px" }}>
                                    <a
                                      href={link}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={link}
                                        id="searchbar"
                                        alt=""
                                        style={{ width: "40px" }}
                                      />
                                    </a>
                                  </i>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>TDS Code</label>
                          <Select
                            options={tdsListData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="tds_code_id"
                            onChange={(newValue, action) => {
                              onPushTDS(newValue, action?.name);
                            }}
                            value={tdsListValue}
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            GST Code<span class="text-danger">*</span>
                          </label>
                          <Select
                            options={gSTListData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="gst_code_id"
                            onChange={(newValue, action) => {
                              onPushGST(newValue, action?.name);
                              setFormValidation({
                                ...formValidation,
                                gst_code_id: "",
                              });
                            }}
                            value={gSTListValue}
                          />
                          <div className="small text-danger">
                            {formValidation.gst_code_id}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : viewData?.attachments[3] !== undefined ? (
                  <>
                    <p>
                      <br />
                      <span class="mr-3">
                        <i class="fas fa-file-download"></i>
                        <a
                          href={viewData?.attachments[3]?.attachment_path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {viewData?.attachments[3]?.attachment_path.includes(
                            ".pdf"
                          ) ? (
                            <img
                              src={"/images/policy-icon/pdf.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : viewData?.attachments[3]?.attachment_path.includes(
                              ".xlsb"
                            ) ||
                            viewData?.attachments[3]?.attachment_path.includes(
                              ".xlsx"
                            ) ||
                            viewData?.attachments[3]?.attachment_path.includes(
                              ".csv"
                            ) ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : viewData?.attachments[3]?.attachment_path.includes(
                              ".doc"
                            ) ||
                            viewData?.attachments[3]?.attachment_path.includes(
                              ".docs"
                            ) ||
                            viewData?.attachments[3]?.attachment_path.includes(
                              ".docx"
                            ) ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : viewData?.attachments[3]?.attachment_path.includes(
                              ".jpg"
                            ) ||
                            viewData?.attachments[3]?.attachment_path.includes(
                              ".png"
                            ) ? (
                            <img
                              src={"/images/policy-icon/img.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : (
                            ""
                          )}
                        </a>
                      </span>
                    </p>
                  </>
                ) : (
                  <p>
                    {" "}
                    <br />
                    There is no attachment
                  </p>
                )}

                {location.state?.flag === false && (
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        Add Comments<span class="text-danger">*</span>
                      </label>
                      <textarea
                        class="form-control"
                        placeholder="Enter Comments here..."
                        name="remarks"
                        onChange={(e) => {
                          setRemarksData({
                            ...remarksData,
                            remarks: e.target.value,
                          });
                          setFormValidation({
                            ...formValidation,
                            remarks: "",
                          });
                        }}
                        value={remarksData.remarks}
                      ></textarea>
                      <div className="small text-danger">
                        {formValidation.remarks}
                      </div>
                    </div>
                  </div>
                )}
                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      location.state?.flag === true
                        ? navigate("/otv-approval")
                        : location.state?.flag === 1
                        ? navigate("/otv-vendor-request")
                        : location.state?.flag === 2
                        ? navigate("/otv-report")
                        : navigate(
                            `/common-approve/${location?.state?.subId}`,
                            {
                              state: {
                                subtypeid: location?.state?.subId,
                                subTypeName: "OTV",
                              },
                            }
                          );
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {location.state?.flag === 1 ||
                  location.state?.flag === 2 ||
                  location.state?.flag === true ? (
                    ""
                  ) : isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        class="btn btn-outline-danger mr-2"
                        onClick={() => handleSaveValue("D")}
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>
                      <button
                        class="btn btn-primary-inner bpi-main"
                        onClick={() => handleSaveValue("A")}
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                      {currentApprover?.approver_position === "SSC AP Lead" && (
                      <button
                        class="btn btn-outline-danger bpi-main ml-2"
                        onClick={() => referToSscProcessor()}
                      >
                        <i class="fas fa-backward"></i> Refer Back to SSC Processor
                      </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOtvNonpo;
