import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const saveSsoList = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/buss-transactions/sso-url/list`, data);
};

export const getGrcUsername = async (value) => {
  return axios.get(`${AUTH_BASE_URL}/esop/grc-username?username=${value}`);
};

export const getEsopList = async (user_id, data) => {
  return axios.get(`${AUTH_BASE_URL}/esop/esop_list?user_id=${user_id}`, data);
};

export const saveVmsDataList = async (user_id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/esop/vms_details?user_id=${user_id}`,
    data
  );
};

export const saveItsmLinklist = async (user_id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/esop/itsm_details?user_id=${user_id}`,
    data
  );
};

export const saveSuccessFactorTodo = async (user_id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/esop/success_factor_todo?user_id=${user_id}`,
    data
  );
};

export const saveCapexAccess = async (user_id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/esop/capex_access?user_id=${user_id}`,
    data
  );
};
export const getClickIPR = async () => {
  return axios.get(`${AUTH_BASE_URL}/esop/click-ipr`);
};

export const getComplianceManager = async (myRequest) => {
  return axios.get(
    `${AUTH_BASE_URL}/esop/compliance_manager?saml_request=${myRequest.saml_Request}&issuer_id=${myRequest.issuer_id}&flag=${myRequest.flag}&c_user=${myRequest.c_user}`
  );
};

export const getComplianceTodo = async (user_id, role) => {
  return axios.get(
    `${AUTH_BASE_URL}/esop/compliance-todo?user_id=${user_id}&role=${role}`
  );
};

export const getCapexTodo = async (user_id) => {
  return axios.post(`${AUTH_BASE_URL}/esop/capex_approvals?user_id=${user_id}`);
};
export const getGRCTodoList = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/esop/grc-todo?user_id=${user_id}`);
};
export const getPriceSchemeApprovalList = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/esop/price-scheme-todo?user_id=${user_id}`);
};
export const getLinksAccess = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/esop/get-links-access?user_id=${user_id}`);
};

export const getProdTodo = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/esop/get-prod-todo?user_id=${user_id}`);
};

export const save_module_log = async (id,data) => {
  return axios.post(
    `${AUTH_BASE_URL}/users/save_module_log?module_id=${id}`,
    data
  );
};