import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import * as TravelListingAPI from "../../service/travelService";
import * as DomesticService from "../../service/domestic";
import moment from "moment";
import * as VehicleLogApi from "../../service/vehiclelog";
import { Tooltip } from "react-tippy";
import * as ClaimTravel from "../../service/claimTravel";

function ViewPrintPage() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [hotelList, setHotelList] = useState([]);
  const [daList, setDaList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const userData = useSelector(selectUserData);
  const [conveyanceList, setConveyanceList] = useState([]);
  const userDetails = useSelector(selectUserData);
  const [dataNew, setDataNew] = useState([]);
  const [logData, setLogData] = useState([]);
  const [amountVehicle, setAmountVehicle] = useState();
  const [vehicleList, setVehicleList] = useState([]);
  const [foreignExpData, setForeignExpData] = useState(undefined);
  const [hDetails, setHdetails] = useState();
  const [sd, setSd] = useState();
  const [sda, setSda] = useState();
  const [td, setTd] = useState();
  const [cd, setCd] = useState();
  const [cdA, setCdA] = useState();
  const [foreignHotel, setForeignHotel] = useState();
  const [foreignFood, setForeignFood] = useState();
  const [foreignDa, setForeignDa] = useState();
  const [foreignConv, setForeignConv] = useState();
  const [ticketDetBA, setticketDetBA] = useState();
  const [ticketDetOC, setticketDetOC] = useState();
  const [ticketDetTA, setticketDetTA] = useState();
  const [ticketDetABA, setticketDetABA] = useState();
  const [ticketDetAOC, setticketDetAOC] = useState();
  const [ticketDetATC, setticketDetATC] = useState();
  const [ticketDetTC, setticketDetTC] = useState();
  const [ticketDetATA, setticketDetATA] = useState();
  const [hotelDetSharing, setHotelDetSharing] = useState();
  const [hotelDetBBA, setHotelDetBBA] = useState();
  const [hotelDetTA, setHotelDetTA] = useState();
  const [hotelDetOC, setHotelDetOC] = useState();
  const [hotelDetBA, setHotelDetBA] = useState();
  const [hotelDetCA, setHotelDetCA] = useState();
  const [totalKms, setTotalKms] = useState();
  const [totalfuel, setTotalFuel] = useState();
  const [totalfuelAmt, setTotalfuelAmt] = useState();
  const [totalddw, setTotalDdw] = useState();
  const [driverDa, setDriverDa] = useState();
  const [hotelDetTCA, setHotelDetTCA] = useState();
  const [vehOther, setVehOther] = useState();
  const [vehTta, setTta] = useState();
  const [totalWash, setTotalWash] = useState();
  const [vehicleData, setVehicalData] = useState({});
  let abortTravelExpenseVehicleSummary = null;
  const [gridDataNew, setGridDataNew] = useState([]);
  const [delayPP, setDelayPP] = useState(0);
  const [contAmount, setContAmount] = useState([]);
  const [fExp, setFExp] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const daType = {
    0: "Local",
    100: "RHQ",
    155: "NH",
  };

  const getConveyenceList = async (data) => {
    dispatch(setDisplayLoader("Display"));
    await TravelListingAPI.getConveyenceList(data)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let res = response?.data?.dataList?.result;
        setConveyanceList(res);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(error);
      });
  };
  const getForeignExpData = async (data) => {
    await TravelListingAPI.getForeignExpData(data)
      .then((response) => {
        let res = response?.data?.dataList;
        setForeignExpData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const todayDate = new Date();
  const getVehicleList = async (data, id) => {
    await TravelListingAPI.getVehicleExpense(data, id)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setVehicleList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateDateOfJoining = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    } else {
      return 0;
    }
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };

  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    ClaimTravel.getTravelList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        travel_id,
        request_type,
        from_date,
        to_date
      )
    )
      .then((response) => {
        setGridDataNew(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getContinentEntt = (travel_id) => {
    TravelListingAPI.getContinentEntt(travel_id)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setContAmount(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getTravelExpenseVehicleSummary = (userId, travelId) => {
    abortTravelExpenseVehicleSummary = new AbortController();
    TravelListingAPI.getTravelExpenseVehicleSummary(
      userId,
      travelId,
      abortTravelExpenseVehicleSummary.signal
    ).then((res) => {
      if (res.status) setVehicalData(res.data.dataList);
    });
  };

  const getHotelList = async (data) => {
    dispatch(setDisplayLoader("Display"));
    await TravelListingAPI.getHotelList(data)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let res = response?.data?.dataList?.result;
        setHotelList(res);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(error);
      });
  };
  const getDaList = async (data) => {
    await TravelListingAPI.getDaList(data)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setDaList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTicketList = (data, id) => {
    TravelListingAPI.getTicketExpense(data, id)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setTicketList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };
  const calculateDateDifference1 = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    } else {
      return 0;
    }
  };
  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    // window.location.reload();
    document.body.innerHTML = originalContents;
  };

  window.onafterprint = () => {
    setTimeout(() => {
      document.getElementById("print-btn-1").onclick = Print;
      document.getElementById("btnPrint").onclick = Print;
    }, 2000);
  };

  const getVehicleLog = (travelId, user_id) => {
    VehicleLogApi.getVehicleLog(travelId, user_id)
      .then((response) => {
        setLogData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const calculateDelayDeductionPercent = (calcDaya) => {
    if (userData?.joiningDate?.date_of_joining) {
      let claimDaysFromJoining = moment(state?.claim_date).diff(
        moment(userData?.joiningDate?.date_of_joining, "YYYY-MM-DD"),
        "days"
      );
      let days = calcDaya + " Days";
      let deductedPercent = 100;

      if (claimDaysFromJoining < 61) {
        days = "0 Days";
      }
      if (
        ["D1", "D2", "C1"].includes(userData?.emp_sub_group) ||
        userData?.sales_dist == "CONS"
      ) {
        days = "0 Days";
      }
      days = days.split(" ");
      if (days[0] <= 7) {
        deductedPercent = 0;
      } else if (days[0] >= 8 && days[0] <= 20) {
        deductedPercent = 5;
      }
      setDelayPP(deductedPercent);
    } else {
      setDelayPP(0);
    }
  };

  useEffect(() => {
    if (
      gridDataNew?.length > 0 &&
      typeof gridDataNew[0]?.currency_data == "string"
    ) {
      let data = gridDataNew[0]?.currency_data
        ? JSON.parse(gridDataNew[0]?.currency_data?.replace(/'/g, '"'))
        : "";
      if (data?.length > 0) {
        let usdData = {};
        let tempCurrencyList = [];
        data?.map((obj) => {
          let temp = obj;
          if (temp?.name === "USD") usdData = obj.amount == "" ? 0 : obj.amount;
          if (temp !== "") tempCurrencyList.push(obj);
        });
        setFExp(usdData);
        setCurrencyList(tempCurrencyList);
      }
    }
  }, [gridDataNew]);

  useEffect(() => {
    let ticket_data = state?.ticket_data;
    let ticket_user = userData?.id;
    if (ticket_data?.length > 0) {
      ticket_user = ticket_data[0]?.user_id;
    }
    getHotelList(state?.id);
    getDaList(state?.id);
    getTicketList(state?.id, ticket_user);
    getConveyenceList(state?.id);
    getUsersDetails(userDetails?.username);
    getVehicleLog(state?.id, userData.id);
    getVehicleList(state?.id, userData.id);
    getContinentEntt(state?.id);
    getForeignExpData(state?.id);
    getTravelExpenseVehicleSummary(userData.id, state?.id);
    getTravelList(
      "",
      1,
      10,
      "-id",
      true,
      userDetails?.id,
      "A",
      state?.id
      // "False"
    );
    calculateDelayDeductionPercent(
      moment(state?.claim_date).diff(
        moment(
          moment(
            state?.travel_detail_data[state?.travel_detail_data.length - 1]
              .to_date,
            "YYYY-MM-DD"
          ),
          "YYYY-MM-DD"
        ),
        "days"
      )
    );
    return () => {
      if (abortTravelExpenseVehicleSummary)
        abortTravelExpenseVehicleSummary.abort();
    };
  }, []);

  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    let sum5 = 0;
    let sum6 = 0;
    let sum7 = 0;
    let sum8 = 0;
    logData?.map((value, i) => {
      sum = value?.total_amount + sum;
      sum1 = value?.total_kms + sum1;
      sum2 = value?.fuel_quantity + sum2;
      sum3 = value?.fuel_amount + sum3;
      sum4 = value?.driver_daily_wages + sum4;
      sum5 = value?.driver_da_value + sum5;
      sum6 = value?.other_expenses + sum6;
      sum7 = value?.total_amount + sum7;
      sum8 = value?.washing_amount + sum8;
    });
    setAmountVehicle(sum);
    setTotalKms(sum1);
    setTotalFuel(sum2);
    setTotalfuelAmt(sum3);
    setTotalDdw(sum4);
    setDriverDa(sum5);
    setVehOther(sum6);
    setTta(sum7);
    setTotalWash(sum8);
  }, [logData]);

  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    daList?.map((ddd, i) => {
      sum = ddd?.gross_total + sum;
      sum1 = ddd?.appr_da_amount + sum1;
    });
    setSd(sum);
    setSda(sum1);
  }, [daList]);
  useEffect(() => {
    let sum = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    let sum5 = 0;
    let sum6 = 0;
    let sum7 = 0;
    let sum8 = 0;
    hotelList?.map((ddd, i) => {
      sum = ddd?.total_claim_amount + sum;
      sum2 =
        (ddd?.second_entitlement !== null
          ? Number(ddd?.second_entitlement)
          : 0) +
        (ddd?.third_entitlement !== null ? Number(ddd?.third_entitlement) : 0) +
        sum2;
      sum3 = ddd?.taxable_amount + sum3;
      sum4 = ddd?.tax_amount + sum4;
      sum5 = ddd?.other_charges + sum5;
      sum6 = ddd?.tax_bill_amount + sum6;
      sum7 = ddd?.claim_amount + sum7;
      sum8 = ddd?.total_claim_amount + sum8;
    });
    setHdetails(sum);
    setHotelDetSharing(sum2);
    setHotelDetBBA(sum3);
    setHotelDetTA(sum4);
    setHotelDetOC(sum5);
    setHotelDetBA(sum6);
    setHotelDetCA(sum7);
    setHotelDetTCA(sum8);
  }, [hotelList]);
  useEffect(() => {
    let sum = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    let sum5 = 0;
    let sum6 = 0;
    let sum7 = 0;
    let sum8 = 0;
    let sum9 = 0;

    ticketList?.map((ddd, i) => {
      sum = ddd?.taxable_amount + ddd?.other_charges + ddd?.tax_amount + sum;
      sum2 = ddd?.taxable_amount + sum2;
      sum3 = ddd?.other_charges + sum3;
      sum4 = ddd?.tax_amount + sum4;
      sum5 =
        Number(ddd?.taxable_amount) +
        Number(ddd?.tax_amount) +
        Number(ddd?.other_charges) +
        sum5;
      sum6 = ddd?.appr_total_bill_amount + sum6;
      sum7 = ddd?.appr_other_charges + sum7;
      sum8 =
        Number(ddd?.appr_taxable_amount) +
        Number(ddd?.tax_amount) +
        Number(ddd?.appr_other_charges) +
        sum8;
      sum9 = ddd?.appr_taxable_amount + sum9;
    });
    setTd(sum);
    setticketDetBA(sum2);

    setticketDetOC(sum3);
    setticketDetTA(sum4);
    setticketDetTC(sum5);
    setticketDetABA(sum6);
    setticketDetAOC(sum7);
    setticketDetATC(sum8);
    setticketDetATA(sum9);
  }, [ticketList]);
  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    conveyanceList?.map((ddd, i) => {
      sum = ddd?.amount + sum;
      sum1 = ddd?.approved_amount + sum1;
    });
    setCd(sum);
    setCdA(sum1);
  }, [conveyanceList]);
  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    foreignExpData?.hotel_data?.map((ddd, i) => {
      sum = ddd?.amount_in_inr + sum;
    });
    foreignExpData?.food_data?.map((ddd, i) => {
      sum1 = ddd?.amount_in_inr + sum1;
    });
    foreignExpData?.da_data?.map((ddd, i) => {
      sum2 = ddd?.amount_in_inr + sum2;
    });
    foreignExpData?.conveyance_data?.map((ddd, i) => {
      sum3 = ddd?.amount_in_inr + sum3;
    });
    setForeignHotel(sum);
    setForeignFood(sum1);
    setForeignDa(sum2);
    setForeignConv(sum3);
  }, [foreignExpData]);
  let newDate = new Date();
  const calculateDelayDate = (exp, loc) => {
    const submitExp = new Date(exp);
    const toLocation = new Date(loc);
    if (submitExp && toLocation && !isNaN(submitExp) && !isNaN(toLocation)) {
      const diffTime = Math.abs(submitExp - toLocation);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    } else {
      return 0;
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="content-wrapper-inner content-wrapper-inner2">
                  <div className="innerheadsec">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="defaultchatgptquest text-right">
                          <Link
                            to="/expense-listing"
                            className="badge badge-secondary"
                          >
                            <i className="fas fa-arrow-circle-left"></i> Back
                          </Link>
                          <button
                            className="badge badge-primary"
                            id="print-btn-1"
                            onClick={() => Print()}
                          >
                            <i className="fas fa-print"></i> Print
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="printablediv">
                      <h3 className="policyhead">
                        {dataNew[0]?.organisation_name}
                        <br />
                        <strong
                          className={
                            userData?.dark_mode == false
                              ? "text f-15"
                              : "text-dark f-15"
                          }
                        >
                          TRAVEL EXPENSE CLAIM
                        </strong>
                      </h3>
                      <div className="row">
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Emp No</label>
                            <p>{userData?.username}</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Name</label>
                            <p>{userData?.full_name}</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Grade</label>
                            <p>{userData?.emp_sub_group}</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Head Quarters</label>
                            <p>{dataNew[0]?.location}</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Intimation Number</label>
                            <p>
                              {state?.travel_intimation_number}
                              {/* PITI{fDate}
                              {userDetails?.username}
                              {dataNew[0]?.id} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label class="text-danger">Deputation</label>
                            <p className="text-danger">
                              {" "}
                              {dataNew[0]?.action_code === "10"
                                ? "Yes"
                                : "No" ?? "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Vendor Code</label>
                            <p>{userData?.employee_vendor_code}</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>SAP Trip No.</label>
                            <p>
                              {/* {state?.sap_trip_number} */}
                              {state?.sap_trip_number?.split(" ", 1)}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Tour Applied on</label>
                            <p>
                              {moment(state?.created_at)
                                .utc()
                                .format("DD-MM-YYYY")}{" "}
                              {moment(state?.created_at).utc().format("HH:mm")}
                            </p>
                          </div>
                        </div>

                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Tour Edit on</label>
                            <p>
                              {state?.tour_edit_on === null ||
                              state?.tour_edit_on === undefined
                                ? "-"
                                : moment(state?.tour_edit_on)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                              {state?.tour_edit_on === null ||
                              state?.tour_edit_on === undefined
                                ? "-"
                                : moment(state?.tour_edit_on)
                                    .utc()
                                    .format("HH:mm")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Tour Approved on</label>
                            <p>
                              {moment(state?.approval_date)
                                .utc()
                                .format("DD-MM-YYYY")}{" "}
                              {moment(state?.approval_date)
                                .utc()
                                .format("HH:mm")}
                            </p>
                          </div>
                        </div> */}
                        <div className="col-sm col-6">
                          {state?.claim_flag === false ? (
                            <div className="form-group innergroupPrint">
                              <label>Printed on</label>
                              <p>
                                {moment(todayDate).format("DD-MM-YYYY HH:mm")}
                              </p>
                            </div>
                          ) : (
                            <div className="form-group innergroupPrint">
                              <label>Tour Approved on</label>
                              <p>
                                {state?.approval_date === null ||
                                state?.approval_date === undefined
                                  ? ""
                                  : moment(state?.approval_date)
                                      .utc()
                                      .format("DD-MM-YYYY HH:mm")}
                                {/* {state?.expense_approver_remark_data.length ===
                                2
                                  ? moment(
                                      state?.expense_approver_remark_data[1]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("DD-MM-YYYY") -
                                    moment(
                                      state?.expense_approver_remark_data[1]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("HH:mm")
                                  : state?.expense_approver_remark_data
                                      .length === 1
                                  ? moment(
                                      state?.expense_approver_remark_data[0]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("DD-MM-YYYY") -
                                    moment(
                                      state?.expense_approver_remark_data[0]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("HH:mm")
                                  : ""} */}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Zone</label>
                            <p> {dataNew[0]?.sales_dist ?? "-"}</p>
                          </div>
                        </div>

                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>From Location</label>
                            <p>
                              {state?.travel_detail_data[0]?.from_city_name ===
                              "Other"
                                ? state?.travel_detail_data[0]?.from_city_input
                                : state?.travel_detail_data[0]?.from_city_name}
                              {/* -
                              {state?.travel_detail_data[0]?.from_city_category} */}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Departure Date</label>
                            <p>
                              {moment(
                                state?.travel_detail_data[0]?.from_date
                              ).format("DD-MM-YYYY")}{" "}
                              {/* {moment(state?.travel_detail_data[0]?.from_time)
                                .utc()
                                .format("HH:mm A")} */}
                              {state?.travel_detail_data[0]?.from_time.slice(
                                0,
                                5
                              )}{" "}
                              {/* {state?.travel_detail_data[0]?.from_time.slice(
                                0,
                                3
                              ) > 12
                                ? "PM"
                                : "AM"} */}
                              {/* {moment(
                                state?.travel_detail_data[0]?.from_time, "h:mm A"
                              ).format("HH:mm A")} */}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>To Location</label>
                            <p>
                              {state?.travel_detail_data[0]?.to_city_name ===
                              "Other"
                                ? state?.travel_detail_data[0]?.to_city_input
                                : state?.travel_detail_data[0]?.to_city_name}
                              {/* -{state?.travel_detail_data[0]?.to_city_category} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Arrival Date</label>
                            <p>
                              {moment(
                                state?.travel_detail_data[
                                  state?.travel_detail_data.length - 1
                                ]?.to_date
                              ).format("DD-MM-YYYY")}{" "}
                              {state?.travel_detail_data[
                                state?.travel_detail_data.length - 1
                              ]?.to_time.slice(0, 5)}{" "}
                              {/* {state?.travel_detail_data[
                                state?.travel_detail_data.length - 1
                              ]?.to_time.slice(0, 3) > 12
                                ? "PM"
                                : "AM"} */}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Days</label>
                            <p>
                              {/* {state?.travel_detail_data?.length > 0 &&
                                calculateDelayDate(
                                  state?.expense_claim_date,
                                  state?.travel_detail_data[
                                    state?.travel_detail_data?.length - 1
                                  ]?.to_date
                                )}
  */}
                              {state?.travel_detail_data?.length > 0 &&
                                calculateDateDifference(
                                  state?.travel_detail_data[
                                    state?.travel_detail_data?.length - 1
                                  ]?.to_date,
                                  state?.travel_detail_data[0]?.from_date
                                )}{" "}
                              Days
                            </p>
                          </div>
                        </div>

                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label class="text-danger">Teritory</label>
                            <p className="text-danger">No</p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Date of Joining</label>
                            <p>
                              {moment(
                                userData?.joiningDate?.date_of_joining
                              ).format("DD-MM-YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Expense Status</label>
                            <p>
                              {state?.claim_flag === true
                                ? "Submitted"
                                : "Saved"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {state?.claim_flag === true && (
                          <div className="col-sm col-6">
                            <div className="form-group innergroupPrint">
                              <label>Submitted Date</label>
                              <p>
                                {moment
                                  .parseZone(state?.claim_date)
                                  .format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Reporting Person</label>
                            <p>
                              {state?.rm_user_name}- ({state?.rm_user_code})
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint">
                            <label>Exception Approved By</label>
                            <p>
                              {state?.is_exception == true &&
                              state?.expense_approver_remark_data[
                                state?.expense_approver_remark_data?.length - 1
                              ]?.status == "A"
                                ? state?.expense_approver_remark_data[
                                    state?.expense_approver_remark_data
                                      ?.length - 1
                                  ]?.approver_name
                                : "-"}
                              {state?.is_exception == true &&
                              state?.expense_approver_remark_data[
                                state?.expense_approver_remark_data?.length - 1
                              ]?.status == "A"
                                ? `-(${
                                    state?.expense_approver_remark_data[
                                      state?.expense_approver_remark_data
                                        ?.length - 1
                                    ]?.approver_username
                                  })`
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint"></div>
                        </div>
                        <div className="col-sm col-6">
                          <div className="form-group innergroupPrint"></div>
                        </div>
                      </div>
                      <div>
                        <>
                          <div className="mb-2 ml-3">
                            <h6 className="text-theme mb-0">
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>Travel Intimation</strong>
                            </h6>
                          </div>

                          <div className="table-responsive mb-4">
                            {state?.travel_detail_data?.length === 0 ? (
                              ""
                            ) : (
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab ">
                                <thead>
                                  <tr>
                                    <th>From</th>
                                    <th>Departure Date</th>
                                    <th>Departure Time</th>
                                    <th>Places Visited</th>
                                    <th>To</th>
                                    <th>Reaching Date</th>
                                    <th>Reaching Time</th>
                                    <th>HQ</th>
                                    <th>Purpose</th>
                                    <th>Mode</th>
                                    <th>Territory</th>
                                    <th>Hotel</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {state?.travel_detail_data?.map((data) => (
                                    <tr>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {" "}
                                        {state?.request_type === "International"
                                          ? `${data?.from_continent_name} (${
                                              data?.from_country_name
                                            } ||  ${
                                              data?.from_city_name === "Other"
                                                ? data?.from_city_input
                                                : data?.from_city_name
                                            })`
                                          : `${
                                              data?.from_city_name === "Other"
                                                ? data?.from_city_input
                                                : data?.from_city_name
                                            }`}
                                        {/* {data?.from_city_name === "Other"
                                          ? data?.from_city_input
                                          : data?.from_city_name} */}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {moment(data?.from_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {moment(
                                          data?.from_time,
                                          "hh:mm"
                                        ).format("HH:mm")}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {data?.additional_information}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {state?.request_type === "International"
                                          ? `${data?.to_continent_name} (${
                                              data?.to_country_name
                                            } ||  ${
                                              data?.to_city_name === "Other"
                                                ? data?.to_city_input
                                                : data?.to_city_name
                                            })`
                                          : `${
                                              data?.to_city_name === "Other"
                                                ? data?.to_city_input
                                                : data?.to_city_name
                                            }`}
                                        {/* {data?.to_city_name === "Other"
                                          ? data?.to_city_input
                                          : data?.to_city_name} */}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {moment(data?.to_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {moment(data?.to_time, "hh:mm").format(
                                          "HH:mm"
                                        )}
                                      </td>
                                      {data?.hq === true ? (
                                        <td
                                          style={
                                            data?.reason_for_exception_name ===
                                              null ||
                                            data?.reason_for_exception_name ===
                                              undefined
                                              ? {}
                                              : { color: "red" }
                                          }
                                        >
                                          Yes
                                        </td>
                                      ) : (
                                        <td
                                          style={
                                            data?.reason_for_exception_name ===
                                              null ||
                                            data?.reason_for_exception_name ===
                                              undefined
                                              ? {}
                                              : { color: "red" }
                                          }
                                        >
                                          No
                                        </td>
                                      )}
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {data?.purpose}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {data?.mode_name}{" "}
                                        {data?.colleague_user_code === null ||
                                        data?.colleague_user_code === undefined
                                          ? ""
                                          : data?.colleague_user_code}
                                      </td>
                                      <td
                                        style={
                                          data?.reason_for_exception_name ===
                                            null ||
                                          data?.reason_for_exception_name ===
                                            undefined
                                            ? {}
                                            : { color: "red" }
                                        }
                                      >
                                        {data?.territory === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                      {data?.stay_needed === true ? (
                                        <td
                                          style={
                                            data?.reason_for_exception_name ===
                                              null ||
                                            data?.reason_for_exception_name ===
                                              undefined
                                              ? {}
                                              : { color: "red" }
                                          }
                                        >
                                          Yes
                                        </td>
                                      ) : (
                                        <td
                                          style={
                                            data?.reason_for_exception_name ===
                                              null ||
                                            data?.reason_for_exception_name ===
                                              undefined
                                              ? {}
                                              : { color: "red" }
                                          }
                                        >
                                          No
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </>
                        {ticketList?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-3">
                              <h6 className="text-theme mb-0">
                                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>Ticket Details</strong>
                              </h6>
                            </div>
                            <div className="table-responsive mb-4">
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                <thead>
                                  <tr>
                                    <th>Boarding On</th>
                                    <th>Ticket Between</th>
                                    <th>Ticket By</th>
                                    <th>Bill Amt</th>
                                    <th>Other Charges</th>
                                    <th>Tax Amount</th>
                                    <th>Total Claim</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr Bill Amt</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr Other Charges</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr Tax Amt</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr Total Claim</th>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {ticketList
                                    ?.filter((ele) => ele.paid_by !== null)
                                    .map((data) => (
                                      <>
                                        <tr>
                                          <td>
                                            {moment(data?.travel_date).format(
                                              "DD-MM-YYYY"
                                            )}{" "}
                                            {data?.travel_time !== null &&
                                              `${moment(
                                                data?.travel_time,
                                                "hh:mm"
                                              ).format("hh:mm A")}`}
                                          </td>
                                          <td>
                                            {data?.from_location_name == "Other"
                                              ? data?.from_city_input
                                              : data?.from_location_name}{" "}
                                            -{" "}
                                            {data?.to_location_name == "Other"
                                              ? data?.to_city_input
                                              : data?.to_location_name}
                                          </td>
                                          <td>
                                            {data?.paid_by?.toLowerCase() ===
                                            "self"
                                              ? "Self"
                                              : "Company"}
                                          </td>
                                          <td>
                                            {JSON.stringify(
                                              data?.taxable_amount
                                            )?.includes(".")
                                              ? data?.taxable_amount.toFixed(2)
                                              : data?.taxable_amount + ".00"}
                                          </td>
                                          <td>
                                            {/* {data?.other_charges === null ||
                                            data?.other_charges === undefined
                                              ? ""
                                              : data?.other_charges} */}
                                            {/* {JSON.stringify(
                                              data?.other_charges
                                            )?.includes(".")
                                              ? data?.other_charges === null ||
                                                data?.other_charges ===
                                                  undefined
                                                ? "0"
                                                : data?.other_charges
                                              : data?.other_charges + ".00"} */}

                                            {data?.other_charges === null ||
                                            data?.other_charges === undefined
                                              ? "0.00"
                                              : data?.other_charges.toFixed(2)}
                                          </td>

                                          <td>
                                            {JSON.stringify(
                                              data?.tax_amount
                                            )?.includes(".")
                                              ? data?.tax_amount == null
                                                ? "0.00"
                                                : data?.tax_amount.toFixed(2)
                                              : data?.tax_amount == undefined
                                              ? "0.00"
                                              : data?.tax_amount + ".00"}
                                          </td>
                                          <td>
                                            {" "}
                                            {Number(
                                              data?.taxable_amount +
                                                data?.tax_amount +
                                                data?.other_charges
                                            ).toFixed(2)}
                                          </td>
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td>
                                              {state?.ssc_head_status === "A" ||
                                              state?.ssc_manager_status === "A"
                                                ? data?.appr_total_bill_amount
                                                  ? (
                                                      Math.round(
                                                        data?.appr_total_bill_amount *
                                                          100
                                                      ) / 100
                                                    )?.toFixed(2)
                                                  : "0.00"
                                                : "-"}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td>
                                              {state?.ssc_head_status === "A" ||
                                              state?.ssc_manager_status === "A"
                                                ? data?.appr_other_charges
                                                  ? (
                                                      Math.round(
                                                        data?.appr_other_charges *
                                                          100
                                                      ) / 100
                                                    )?.toFixed(2)
                                                  : "0.00"
                                                : ""}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td>
                                              {state?.ssc_head_status === "A" ||
                                              state?.ssc_manager_status === "A"
                                                ? data?.appr_taxable_amount
                                                  ? (
                                                      Math.round(
                                                        data?.appr_taxable_amount *
                                                          100
                                                      ) / 100
                                                    )?.toFixed(2)
                                                  : "0.00"
                                                : "-"}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td>
                                              {state?.ssc_head_status === "A" ||
                                              state?.ssc_manager_status === "A"
                                                ? (
                                                    Number(
                                                      data?.appr_taxable_amount
                                                        ? data?.appr_taxable_amount
                                                        : 0
                                                    ) +
                                                    Number(
                                                      data?.tax_amount
                                                        ? data?.tax_amount
                                                        : 0
                                                    ) +
                                                    Number(
                                                      data?.appr_other_charges
                                                        ? data?.appr_other_charges
                                                        : 0
                                                    )
                                                  )?.toFixed(2)
                                                : "-"}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                        </tr>
                                      </>
                                    ))}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {ticketDetBA === null ||
                                      ticketDetBA === undefined
                                        ? "0.00"
                                        : (
                                            Math.round(ticketDetBA * 100) / 100
                                          )?.toFixed(2)}
                                    </td>
                                    <td>
                                      {ticketDetOC === null ||
                                      ticketDetOC === undefined
                                        ? "0.00"
                                        : (
                                            Math.round(ticketDetOC * 100) / 100
                                          )?.toFixed(2)}
                                      {/* {JSON.stringify(ticketDetOC)?.includes(
                                        "."
                                      )
                                        ? (Math.round(ticketDetOC * 100) / 100).toFixed(2)
                                        : ".00"} */}
                                    </td>
                                    <td>
                                      {ticketDetTA === null ||
                                      ticketDetTA === undefined
                                        ? "0.00"
                                        : (
                                            Math.round(ticketDetTA * 100) / 100
                                          )?.toFixed(2)}
                                    </td>
                                    <td>
                                      {ticketDetTC === null ||
                                      ticketDetTC === undefined
                                        ? "0.00"
                                        : (
                                            Math.round(ticketDetTC * 100) / 100
                                          )?.toFixed(2)}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {ticketDetABA === null ||
                                        ticketDetABA === undefined
                                          ? "0.00"
                                          : (
                                              Math.round(ticketDetABA * 100) /
                                              100
                                            )?.toFixed(2)}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {ticketDetAOC === null ||
                                        ticketDetAOC === undefined
                                          ? "0.00"
                                          : (
                                              Math.round(ticketDetAOC * 100) /
                                              100
                                            )?.toFixed(2)}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {ticketDetATA === null ||
                                        ticketDetATA === undefined
                                          ? "0.00"
                                          : (
                                              Math.round(ticketDetATA * 100) /
                                              100
                                            )?.toFixed(2)}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {ticketDetATC === null ||
                                        ticketDetATC === undefined
                                          ? "0.00"
                                          : (
                                              Math.round(ticketDetATC * 100) /
                                              100
                                            )?.toFixed(2)}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-sm-12">
                                <p
                                  style={{
                                    color:
                                      userData?.dark_mode == false
                                        ? "white"
                                        : "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied: ₹
                                  {td === null || td === undefined
                                    ? "0.00"
                                    : td?.toFixed(2)}
                                  {/* {JSON.stringify(td)?.includes(".")
                                    ? td?.toFixed(2)
                                    : ".00"} */}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? ` Approved: ₹ ${ticketDetATC}`
                                    : ""}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? JSON.stringify(ticketDetATC)?.includes(
                                        "."
                                      )
                                      ? ""
                                      : ".00"
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {hotelList?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-3">
                              <h6 className="text-theme mb-0">
                                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>Hotel Details</strong>
                              </h6>
                            </div>

                            <div className="table-responsive mb-4">
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                <thead>
                                  <tr>
                                    <th>Check in Date</th>
                                    <th>Check Out Date</th>
                                    <th>Days</th>
                                    <th>City/Entitlement</th>
                                    <th>Stay At</th>
                                    <th>Hotel Name</th>
                                    <th>Hotel GST No.</th>
                                    <th>Invoice</th>
                                    <th>Invoice Date</th>
                                    <th>Sharing</th>
                                    <th>Bill Basic Amt</th>
                                    <th>Tax Amount</th>
                                    {/* <th>Other Charges</th> */}
                                    <th>Total Bill Amt</th>
                                    <th>Claim Amount</th>
                                    <th>Total Claim Amt</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr. Bill Base Amt</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr. Claim Amt</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr. Tax Amt</th>
                                    ) : (
                                      ""
                                    )}
                                    {/* {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr. Other</th>
                                    ) : (
                                      ""
                                    )} */}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>Appr. Total Claim</th>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {hotelList?.map((data) => (
                                    <tr>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {moment(data?.check_in_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {" "}
                                        {moment(data?.check_out_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.days}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.city_name === "Other"
                                          ? data?.city_input
                                          : data?.city_name}
                                        -{data?.city_category}-{" "}
                                        {data?.city_entitlement
                                          ? Number(
                                              data?.city_entitlement
                                            )?.toFixed(2)
                                          : "0.00"}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.stay_at}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.hotel_name}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.hotel_gst_no}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.invoice_no}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {" "}
                                        {data?.invoice_date === null
                                          ? "-"
                                          : moment(data?.invoice_date).format(
                                              "DD-MM-YYYY"
                                            )}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.person_one_username}
                                        {data?.person_two_username
                                          ? `, ${data?.person_two_username}`
                                          : ""}
                                        {/* {Number(
                                          data?.second_entitlement !== null
                                            ? data?.second_entitlement
                                            : 0
                                        ) +
                                          Number(
                                            data?.third_entitlement !== null
                                              ? data?.third_entitlement
                                              : 0
                                          )} */}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.taxable_amount
                                          ? data.taxable_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.taxable_amount
                                        )?.includes(".")
                                          ? data?.taxable_amount == null
                                            ? "0.00"
                                            : data?.taxable_amount
                                          : data?.taxable_amount == null
                                          ? "0.00"
                                          : data?.taxable_amount + ".00"} */}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.tax_amount
                                          ? data.tax_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.tax_amount
                                        )?.includes(".")
                                          ? data?.tax_amount == null
                                            ? "0.00"
                                            : data?.tax_amount
                                          : data?.tax_amount == null
                                          ? "0.00"
                                          : data?.tax_amount + ".00"} */}
                                      </td>
                                      {/* <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.other_charges
                                          ? data.other_charges?.toFixed(2)
                                          : "0.00"}
                                       
                                      </td> */}
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.tax_bill_amount
                                          ? data.tax_bill_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.tax_bill_amount
                                        )?.includes(".")
                                          ? data?.tax_bill_amount == null
                                            ? "0.00"
                                            : data?.tax_bill_amount
                                          : data?.tax_bill_amount == null
                                          ? "0.00"
                                          : data?.tax_bill_amount + ".00"} */}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.claim_amount
                                          ? data.claim_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.claim_amount
                                        )?.includes(".")
                                          ? data?.claim_amount == null
                                            ? "0.00"
                                            : data?.claim_amount
                                          : data?.claim_amount == null
                                          ? "0.00"
                                          : data?.claim_amount + ".00"} */}
                                      </td>
                                      {/* <td>
                                        {JSON.stringify(
                                          data?.total_claim_amount
                                        )?.includes(".")
                                          ? data?.total_claim_amount == null
                                            ? "0.00"
                                            : data?.total_claim_amount
                                          : data?.total_claim_amount == null
                                          ? "0.00"
                                          : data?.total_claim_amount + ".00"}
                                      </td> */}
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.total_claim_amount
                                          ? data.total_claim_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.total_claim_amount
                                        )?.includes(".")
                                          ? data?.total_claim_amount == null
                                            ? "0.00"
                                            : data?.total_claim_amount
                                          : data?.total_claim_amount == null
                                          ? "0.00"
                                          : data?.total_claim_amount + ".00"} */}
                                      </td>
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {" "}
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A"
                                            ? data?.appr_bill_amount
                                              ? data?.appr_bill_amount?.toFixed(
                                                  2
                                                )
                                              : "0"
                                            : "0"}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A"
                                            ? data?.appr_claim_amount
                                              ? data?.appr_claim_amount?.toFixed(
                                                  2
                                                )
                                              : "0"
                                            : "0"}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A"
                                            ? data?.appr_tax_amount
                                              ? data?.appr_tax_amount?.toFixed(
                                                  2
                                                )
                                              : "0"
                                            : "0"}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {/* {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A"
                                            ? data?.other_charges
                                              ? data?.other_charges?.toFixed(2)
                                              : "0.00"
                                            : "0"}
                                        </td>
                                      ) : (
                                        ""
                                      )} */}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A"
                                            ? data?.total_claim_amount
                                              ? data?.total_claim_amount?.toFixed(
                                                  2
                                                )
                                              : "0"
                                            : "0"}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {/* {hotelDetSharing === null ||
                                      hotelDetSharing === undefined
                                        ? "0"
                                        : hotelDetSharing}
                                      {JSON.stringify(
                                        hotelDetSharing
                                      )?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                    </td>
                                    <td>
                                      {hotelDetBBA === null ||
                                      hotelDetBBA === undefined
                                        ? "0"
                                        : hotelDetBBA}
                                      {JSON.stringify(hotelDetBBA)?.includes(
                                        "."
                                      )
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td>
                                      {hotelDetTA === null ||
                                      hotelDetTA === undefined
                                        ? "0"
                                        : hotelDetTA}
                                      {JSON.stringify(hotelDetTA)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>

                                    {/* <td>
                                      {hotelDetOC === null ||
                                      hotelDetOC === undefined
                                        ? "0"
                                        : hotelDetOC}
                                      {JSON.stringify(hotelDetOC)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td> */}
                                    <td>
                                      {hotelDetBA === null ||
                                      hotelDetBA === undefined
                                        ? "0"
                                        : hotelDetBA}
                                      {JSON.stringify(hotelDetBA)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td>
                                      {hotelDetCA === null ||
                                      hotelDetCA === undefined
                                        ? "0"
                                        : hotelDetCA}
                                      {JSON.stringify(hotelDetCA)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td>
                                      {hotelDetTCA === null ||
                                      hotelDetTCA === undefined
                                        ? "0"
                                        : hotelDetTCA}
                                      {JSON.stringify(hotelDetTCA)?.includes(
                                        "."
                                      )
                                        ? ""
                                        : ".00"}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {hotelDetBBA === null ||
                                        hotelDetBBA === undefined
                                          ? "0"
                                          : hotelDetBBA}
                                        {JSON.stringify(hotelDetBBA)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {hotelDetCA === null ||
                                        hotelDetCA === undefined
                                          ? "0"
                                          : hotelDetCA}
                                        {JSON.stringify(hotelDetCA)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {hotelDetTA === null ||
                                        hotelDetTA === undefined
                                          ? "0"
                                          : hotelDetTA}
                                        {JSON.stringify(hotelDetTA)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {/* {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {hotelDetOC === null ||
                                        hotelDetOC === undefined
                                          ? "0"
                                          : hotelDetOC}
                                        {JSON.stringify(hotelDetOC)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )} */}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {hotelDetTCA === null ||
                                        hotelDetTCA === undefined
                                          ? "0"
                                          : hotelDetTCA}
                                        {JSON.stringify(hotelDetTCA)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-sm-12">
                              <p
                                style={{
                                  color:
                                    userData?.dark_mode == false
                                      ? "white"
                                      : "black",
                                  marginTop: "10px",
                                  fontWeight: "700",
                                }}
                              >
                                Applied: ₹{hDetails}
                                {JSON.stringify(hDetails)?.includes(".")
                                  ? ""
                                  : ".00"}
                                &nbsp;&nbsp;
                                {state?.ssc_head_status === "A" ||
                                state?.ssc_manager_status === "A"
                                  ? ` Approved: ₹ ${state?.hotel_self}`
                                  : ""}
                                {state?.ssc_head_status === "A" ||
                                state?.ssc_manager_status === "A"
                                  ? JSON.stringify(state?.hotel_self)?.includes(
                                      "."
                                    )
                                    ? ""
                                    : ".00"
                                  : ""}
                              </p>
                            </div>
                          </>
                        )}
                        {daList?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-3">
                              <h6 className="text-theme mb-0">
                                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>DA Details</strong>
                              </h6>
                            </div>
                            <div className="table-responsive mb-4">
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Hours</th>
                                    <th>City/Entitlement</th>
                                    <th>Remarks</th>
                                    <th>Total Claim Amt</th>
                                    <th>Day/Leave</th>
                                    {/* {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App DA %</th>
                                    ) : (
                                      ""
                                    )} */}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App DA Text</th>
                                    ) : (
                                      ""
                                    )}
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>APP DA AMT</th>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {daList?.map((data) => (
                                    <tr>
                                      <td>
                                        {moment(data?.da_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td> {data?.hours?.toFixed(2)}</td>
                                      <td>
                                        {data?.city_name === "Other"
                                          ? data?.city_input
                                          : data?.city_name}
                                        -{data?.category}-{" "}
                                        {data?.entitlement
                                          ? data?.entitlement?.toFixed(2)
                                          : "0.00"}
                                      </td>

                                      <td>
                                        <Tooltip
                                          title={data?.remarks}
                                          position="bottom"
                                        >
                                          {data?.remarks}
                                        </Tooltip>
                                      </td>
                                      <td>
                                        {data?.gross_total?.toFixed(2)}

                                        {/* {data?.total_amount
                                          ? data?.total_amount?.toFixed(2)
                                          : "0.00"} */}
                                      </td>

                                      <td style={{ color: "red" }}>
                                        {(data?.annual_meet === "Leave" ||
                                          data?.annual_meet ===
                                            "Holiday/WeeklyOff") &&
                                        moment(data?.da_date).day() === 0
                                          ? "Sunday"
                                          : ""}
                                        {moment(data?.da_date).day() !== 0 &&
                                        data?.annual_meet === "Leave"
                                          ? "Leave"
                                          : data?.annual_meet ===
                                            "Holiday/WeeklyOff"
                                          ? "Holiday/WeeklyOff"
                                          : ""}
                                      </td>

                                      {/* {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td>
                                          {
                                            data?.appr_da_percent
                                              ? data?.appr_da_percent + "%"
                                              : "-"

                                         
                                          }
                                        </td>
                                      ) : (
                                        ""
                                      )} */}

                                      {/* da_date */}

                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td>
                                          {" "}
                                          <Tooltip
                                            title={data?.appr_da_remarks}
                                            position="bottom"
                                          >
                                            {data?.appr_da_remarks}
                                          </Tooltip>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td>
                                          {data?.appr_da_amount?.toFixed(2)}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {sd === null || sd === undefined
                                        ? "0"
                                        : sd}
                                      {JSON.stringify(sd)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>

                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td></td>
                                    ) : (
                                      ""
                                    )}
                                    <td></td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {sda === null || sd === undefined
                                          ? "0"
                                          : sda}
                                        {JSON.stringify(sda)?.includes(".")
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-sm-12">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color:
                                      userData?.dark_mode == false
                                        ? "white"
                                        : "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied: ₹
                                  {Number(state?.total_da_amount)?.toFixed(2)}
                                  {/* {JSON.stringify(
                                    state?.final_da_total
                                  )?.includes(".")
                                    ? ""
                                    : ".00"} */}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? ` Approved: ₹ ${state?.da_self}`
                                    : ""}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? JSON.stringify(state?.da_self)?.includes(
                                        "."
                                      )
                                      ? ""
                                      : ".00"
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {logData?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-3">
                              <h6 className="text-theme mb-0">
                                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>Vehicle Details</strong>
                              </h6>
                            </div>
                            <div className="table-responsive mb-4">
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Start Km</th>
                                    <th>End Km</th>
                                    <th>Total Km</th>
                                    <th>Fuel Qty</th>
                                    <th>Fuel Amount</th>

                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Fuel Amount</th>
                                    ) : (
                                      ""
                                    )}

                                    <th>Driver Daily Wages</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Driver Daily Wages</th>
                                    ) : (
                                      ""
                                    )}
                                    {/* <th>Stay</th> */}
                                    <th>Driver DA </th>
                                    <th>Driver DA Val</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Driver DA</th>
                                    ) : (
                                      ""
                                    )}
                                    <th>Other </th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Other</th>
                                    ) : (
                                      ""
                                    )}
                                    <th>Washing Charges</th>
                                    <th>Travel With</th>
                                    <th>Partner Name</th>
                                    <th>Vehicle Type</th>
                                    <th>Total Claim Amt</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Total</th>
                                    ) : (
                                      ""
                                    )}
                                    <th>Exception Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {logData
                                    ?.filter((ele) => ele?.start_kms !== 0)
                                    ?.map((data, index) => (
                                      <tr>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {moment
                                            .parseZone(data?.travel_date)

                                            .format("DD-MM-YYYY")}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.start_kms}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.close_kms}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.total_kms}{" "}
                                          {JSON.stringify(
                                            data?.total_km
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.fuel_quantity}
                                          {JSON.stringify(
                                            data?.fuel_quantity
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.fuel_amount}
                                          {JSON.stringify(
                                            data?.fuel_amount
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <td
                                            style={
                                              data?.is_exception == true &&
                                              data?.is_personal_car_exception ==
                                                false
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.fuel_amount}
                                            {JSON.stringify(
                                              data?.fuel_amount
                                            )?.includes(".")
                                              ? ""
                                              : ".00"}
                                          </td>
                                        ) : (
                                          ""
                                        )}

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.driver_daily_wages}
                                          {JSON.stringify(
                                            data?.driver_daily_wages
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <td
                                            style={
                                              data?.is_exception == true &&
                                              data?.is_personal_car_exception ==
                                                false
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.driver_daily_wages}
                                            {JSON.stringify(
                                              data?.driver_daily_wages
                                            )?.includes(".")
                                              ? ""
                                              : ".00"}
                                          </td>
                                        ) : (
                                          ""
                                        )}

                                        {/* <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.stay_at}
                                        </td> */}
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {daType[data?.driver_da_value]}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.driver_da_value}{" "}
                                          {JSON.stringify(
                                            data?.driver_da_value
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        {/* <td
                                          style={
                                            data?.is_exception == true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.driver_da_value}
                                        </td> */}

                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <td
                                            style={
                                              data?.is_exception == true &&
                                              data?.is_personal_car_exception ==
                                                false
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.driver_da_value}
                                            {JSON.stringify(
                                              data?.driver_da_value
                                            )?.includes(".")
                                              ? ""
                                              : ".00"}
                                          </td>
                                        ) : (
                                          ""
                                        )}

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.other_expenses}
                                          {JSON.stringify(
                                            data?.other_expenses
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <td
                                            style={
                                              data?.is_exception == true &&
                                              data?.is_personal_car_exception ==
                                                false
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.other_expenses}
                                            {JSON.stringify(
                                              data?.other_expenses
                                            )?.includes(".")
                                              ? ""
                                              : ".00"}
                                          </td>
                                        ) : (
                                          ""
                                        )}

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.washing_amount === null
                                            ? 0
                                            : data?.washing_amount}
                                          {JSON.stringify(
                                            data?.washing_amount
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.travel_with}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.partner_name}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.modee_name ?? "-"}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception == true &&
                                            data?.is_personal_car_exception ==
                                              false
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {data?.total_amount}
                                          {JSON.stringify(
                                            data?.total_amount
                                          )?.includes(".")
                                            ? ""
                                            : ".00"}
                                        </td>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <td
                                            style={
                                              data?.is_exception == true &&
                                              data?.is_personal_car_exception ==
                                                false
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.total_amount}
                                            {JSON.stringify(
                                              data?.total_amount
                                            )?.includes(".")
                                              ? ""
                                              : ".00"}
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        <td>-</td>
                                      </tr>
                                    ))}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {totalKms === null ||
                                      totalKms === undefined
                                        ? "0"
                                        : totalKms}
                                      {JSON.stringify(totalKms)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td>
                                      {totalfuel === null ||
                                      totalfuel === undefined
                                        ? "0"
                                        : totalfuel}
                                      {JSON.stringify(totalfuel)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td>
                                      {totalfuelAmt === null ||
                                      totalfuelAmt === undefined
                                        ? "0"
                                        : Number(totalfuelAmt)?.toFixed(2)}
                                      {/* {JSON.stringify(totalfuelAmt)?.includes(
                                        "."
                                      )
                                        ? ""
                                        : ".00"} */}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {totalfuelAmt === null ||
                                        totalfuelAmt === undefined
                                          ? "0"
                                          : Number(totalfuelAmt).toFixed(2)}
                                        {/* {JSON.stringify(totalfuelAmt)?.includes(
                                          "."
                                        )
                                          ? ""
                                          : ".00"} */}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    {/* <td>
                                      {totalddw === null ||
                                      totalddw === undefined
                                        ? "0"
                                        : totalddw}
                                      {JSON.stringify(totalddw)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td> */}

                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {totalddw === null ||
                                        totalddw === undefined
                                          ? "0"
                                          : totalddw}
                                        {JSON.stringify(totalddw)?.includes(".")
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {driverDa === null ||
                                      driverDa === undefined
                                        ? "0"
                                        : driverDa}
                                      {JSON.stringify(driverDa)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {driverDa === null ||
                                        driverDa === undefined
                                          ? "0"
                                          : driverDa}
                                        {JSON.stringify(driverDa)?.includes(".")
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    <td>
                                      {vehOther === null ||
                                      vehOther === undefined
                                        ? "0"
                                        : vehOther}
                                      {JSON.stringify(vehOther)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {vehOther === null ||
                                        vehOther === undefined
                                          ? "0"
                                          : vehOther}
                                        {JSON.stringify(vehOther)?.includes(".")
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    <td>
                                      {totalWash === null ||
                                      totalWash === undefined
                                        ? "0"
                                        : totalWash}
                                      {JSON.stringify(totalWash)?.includes(".")
                                        ? ""
                                        : ".00"}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {vehTta === null || vehTta === undefined
                                        ? "0"
                                        : vehTta?.toFixed(2)}
                                      {/* {JSON.stringify(vehTta)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {vehTta === null || vehTta === undefined
                                          ? "0"
                                          : vehTta?.toFixed(2)}
                                        {/* {JSON.stringify(vehTta)?.includes(".")
                                          ? ""
                                          : ".00"} */}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-sm-12">
                              {/* <p style={{color:'black', float:'left'}}></p> */}
                              <p
                                style={{
                                  color:
                                    userData?.dark_mode == false
                                      ? "white"
                                      : "black",
                                  marginTop: "10px",
                                  fontWeight: "700",
                                }}
                              >
                                Applied: ₹
                                {Number(state?.final_vehicle_amount)?.toFixed(
                                  2
                                )}
                                {/* {JSON.stringify(
                                  state?.final_vehicle_amount
                                )?.includes(".")
                                  ? ""
                                  : ".00"} */}
                                {state?.ssc_head_status === "A" ||
                                state?.ssc_manager_status === "A"
                                  ? ` Approved: ₹ ${state?.final_vehicle_amount}`
                                  : ""}
                                {state?.ssc_head_status === "A" ||
                                state?.ssc_manager_status === "A"
                                  ? JSON.stringify(
                                      state?.final_vehicle_amount
                                    )?.includes(".")
                                    ? ""
                                    : ".00"
                                  : ""}
                                {/* {amountVehicle} */}
                              </p>
                            </div>
                          </>
                        )}
                        {vehicleData["monthly limit"] ? (
                          <>
                            <div class="mb-2 ml-3">
                              <h6 class="text-theme mb-0">
                                <i class="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>Vehicle Summary</strong>
                              </h6>
                            </div>

                            <div class="table-responsive mb-4">
                              <table class="table table-striped table-bordered new-table tablecured viewpo innergroupPrint checkexpentable viewpoprinttab">
                                <thead>
                                  <tr></tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Monthly KM limit</td>
                                    <td>{vehicleData["monthly limit"]}</td>
                                  </tr>
                                  <tr>
                                    <td>KM Travelled in current Month</td>
                                    <td>{vehicleData["km traveled"]}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      KM Travelled - in current travel claim
                                    </td>
                                    <td>
                                      {
                                        vehicleData[
                                          "current travel km travelled"
                                        ]
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Travelled KM MTD</td>
                                    <td>{vehicleData["traveled km MTD"]}</td>
                                  </tr>
                                  <tr>
                                    <td>Excess KM</td>
                                    <td>{vehicleData["Excess KM"]}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {conveyanceList?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-3">
                              <h6 className="text-theme mb-0">
                                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                <strong>Local Conveyance Details</strong>
                              </h6>
                            </div>
                            <div className="table-responsive mb-4">
                              <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>From-To</th>
                                    <th>Travel By</th>
                                    <th>Vendor Name</th>
                                    <th>Vendor State Name</th>
                                    <th>Claim Amount</th>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <th>App Amount </th>
                                    ) : (
                                      ""
                                    )}
                                    {/* <th>Travel Mode</th>
                                    <th>Exception</th> */}
                                  </tr>
                                </thead>

                                <tbody>
                                  {conveyanceList?.map((data) => (
                                    <tr>
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {moment(data?.conveyance_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {" "}
                                        {data?.from_location_name}-
                                        {data?.to_location_name}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.modee_name == null
                                          ? data?.mode_name
                                          : data?.modee_name}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.vendor_name === null
                                          ? "-"
                                          : data?.vendor_name}
                                        {/* {data?.amount} */}
                                      </td>{" "}
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.vendor_state_name === null
                                          ? "-"
                                          : data?.vendor_state_name}
                                        {/* {data?.amount} */}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception === true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {JSON.stringify(data?.amount)?.includes(
                                          "."
                                        )
                                          ? data?.amount?.toFixed(2)
                                          : data?.amount + ".00"}
                                        {/* {data?.amount} */}
                                      </td>
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A" ? (
                                        <td
                                          style={
                                            data?.is_exception === true
                                              ? { color: "red" }
                                              : {}
                                          }
                                        >
                                          {" "}
                                          {data?.approved_amount
                                            ? data?.approved_amount?.toFixed(2)
                                            : ".00"}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      {cd === null || cd === undefined
                                        ? "0.00"
                                        : cd?.toFixed(2)}
                                      {/* {JSON.stringify(cd)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                    </td>
                                    {state?.ssc_head_status === "A" ||
                                    state?.ssc_manager_status === "A" ? (
                                      <td>
                                        {cdA === null || cdA === undefined
                                          ? "0.00"
                                          : cdA?.toFixed(2)}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-sm-12">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color:
                                      userData?.dark_mode == false
                                        ? "white"
                                        : "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied: ₹{" "}
                                  {cd === null || cd === undefined
                                    ? "0.00"
                                    : cd?.toFixed(2)}
                                  {/* {JSON.stringify(cd)?.includes(".")
                                    ? ""
                                    : ".00"} */}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? ` Approved: ₹ ${cdA}`
                                    : ""}
                                  {state?.ssc_head_status === "A" ||
                                  state?.ssc_manager_status === "A"
                                    ? JSON.stringify(cdA)?.includes(".")
                                      ? ""
                                      : ".00"
                                    : ""}
                                </p>
                              </div>
                            </div>{" "}
                          </>
                        )}{" "}
                        {!foreignExpData ||
                        (foreignExpData?.hotel_data?.length === 0 &&
                          foreignExpData?.food_data?.length === 0 &&
                          foreignExpData?.da_data?.length === 0 &&
                          foreignExpData?.conveyance_data?.length === 0) ? (
                          ""
                        ) : (
                          <>
                            <div className="mb-2 ml-1">
                              <h6 className="text-theme mb-1">
                                <strong>Foreign Expenses</strong>
                              </h6>
                              <label>
                                Boarding & Lodging Entitlement (USD :
                                {contAmount ?? "0"}, INR :{" "}
                                <i className="fas fa-rupee-sign f-12"></i>
                                {Number(contAmount * fExp).toFixed(2)})
                              </label>
                            </div>
                            <div className="d-flex flex-wrap">
                              <p>Exchange Rate:</p>
                              <div className="d-flex">
                                <span className="mr-4 ml-4">USD: {fExp}</span>
                                {currencyList.map((obj) => {
                                  if (
                                    obj?.name !== "USD" &&
                                    obj?.name !== "INR"
                                  ) {
                                    return (
                                      <span className="mr-4">
                                        {obj?.name}: {obj?.amount}
                                      </span>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })}
                              </div>
                            </div>
                            {foreignExpData?.hotel_data?.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                <div className="mb-2 ml-3">
                                  <h6 className="text-theme mb-0">
                                    <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                    <strong>Hotel Details</strong>
                                  </h6>
                                </div>
                                <div className="table-responsive mb-4">
                                  <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                    <thead>
                                      <tr>
                                        <th>Hotel Name</th>
                                        <th>Stay From</th>
                                        <th>Stay Till</th>
                                        <th>Paid By</th>
                                        <th>Vendor Invoice</th>
                                        <th>Invoive Date</th>
                                        <th>Currency</th>
                                        <th>Amt(In Forex)</th>
                                        <th>Amt(In Inr)</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In Forex)</th>
                                        ) : (
                                          ""
                                        )}
                                        <th>Exchange Rate</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In INR)</th>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {foreignExpData?.hotel_data?.map(
                                        (data) => (
                                          <tr>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.hotel_name ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {moment(
                                                data?.check_in_date
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {moment(
                                                data?.check_out_date
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.paid_by}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.vendor_invoice}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {moment(
                                                data?.invoice_date
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.currency_code}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_forex
                                              )?.includes(".")
                                                ? data?.amount_in_forex.toFixed(
                                                    2
                                                  )
                                                : data?.amount_in_forex + ".00"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_inr
                                              )?.includes(".")
                                                ? data?.amount_in_inr.toFixed(2)
                                                : data?.amount_in_inr + ".00"}
                                            </td>
                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_forex
                                                )?.includes(".")
                                                  ? data?.amount_in_forex.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_forex +
                                                    ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {currencyList
                                                ?.filter(
                                                  (ele) =>
                                                    ele?.name ==
                                                    data?.currency_code
                                                )
                                                ?.map((item) => (
                                                  <span>{item?.amount}</span>
                                                ))}
                                            </td>
                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_inr
                                                )?.includes(".")
                                                  ? data?.amount_in_inr.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_inr + ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="col-sm-12">
                                    <p
                                      style={{
                                        color:
                                          userData?.dark_mode == false
                                            ? "white"
                                            : "black",
                                        marginTop: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Applied: ₹
                                      {foreignHotel === null ||
                                      foreignHotel === undefined
                                        ? "0"
                                        : foreignHotel?.toFixed(2)}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? ` Approved: ₹ ${foreignHotel?.toFixed(
                                            2
                                          )}`
                                        : ""}
                                      {/* {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? JSON.stringify(
                                            foreignHotel
                                          )?.includes(".")
                                          ? ""
                                          : ".00"
                                        : ""} */}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Food Details */}
                            {foreignExpData?.food_data?.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                <div className="mb-2 ml-3">
                                  <h6 className="text-theme mb-0">
                                    <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                    <strong>Food Details</strong>
                                  </h6>
                                </div>
                                <div className="table-responsive mb-4">
                                  <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Vendor Name</th>
                                        <th>Paid By</th>
                                        <th>Currency</th>
                                        <th>Amt(In Forex)</th>
                                        <th>Amt(In Inr)</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In Forex)</th>
                                        ) : (
                                          ""
                                        )}
                                        <th>Exchange Rate</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In INR)</th>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {foreignExpData?.food_data?.map(
                                        (data) => (
                                          <tr>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {moment(data?.food_date).format(
                                                "DD-MM-YYYY"
                                              )}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.vendor_name ?? "-"}
                                            </td>

                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.paid_by ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.currency_code}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_forex
                                              )?.includes(".")
                                                ? data?.amount_in_forex.toFixed(
                                                    2
                                                  )
                                                : data?.amount_in_forex + ".00"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_inr
                                              )?.includes(".")
                                                ? data?.amount_in_inr.toFixed(2)
                                                : data?.amount_in_inr + ".00"}
                                            </td>
                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_forex
                                                )?.includes(".")
                                                  ? data?.amount_in_forex.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_forex +
                                                    ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {currencyList
                                                ?.filter(
                                                  (ele) =>
                                                    ele?.name ==
                                                    data?.currency_code
                                                )
                                                ?.map((item) => (
                                                  <span>{item?.amount}</span>
                                                ))}
                                            </td>
                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_inr
                                                )?.includes(".")
                                                  ? data?.amount_in_inr.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_inr + ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="col-sm-12">
                                    {/* <p style={{color:'black', float:'left'}}></p> */}
                                    <p
                                      style={{
                                        color:
                                          userData?.dark_mode == false
                                            ? "white"
                                            : "black",
                                        marginTop: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Applied: ₹
                                      {foreignFood === null ||
                                      foreignFood === undefined
                                        ? "0"
                                        : foreignFood?.toFixed(2)}
                                      {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? ` Approved: ₹ ${foreignFood?.toFixed(
                                            2
                                          )}`
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Incedental Details */}
                            {foreignExpData?.da_data?.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                <div className="mb-2 ml-3">
                                  <h6 className="text-theme mb-0">
                                    <strong>Incidental Details</strong>
                                    <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                  </h6>
                                </div>
                                <div className="table-responsive mb-4">
                                  <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>ENT Currency</th>
                                        <th>ENT Amount(In Forex)</th>
                                        <th>ENT Amount(In INR)</th>
                                        <th>Currency</th>
                                        <th>Amt(In Forex)</th>
                                        <th>Amt(In Inr)</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In Forex)</th>
                                        ) : (
                                          ""
                                        )}
                                        <th>Exchange Rate</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In INR)</th>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {foreignExpData?.da_data?.map((data) => (
                                        <tr>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {moment(
                                              data?.incidental_date
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.currency_code ?? "-"}
                                          </td>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.ent_amount_in_forex ===
                                              null ||
                                            data?.ent_amount_in_forex ===
                                              undefined
                                              ? "0.00"
                                              : data?.ent_amount_in_forex.toFixed(
                                                  2
                                                )}
                                          </td>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.ent_amount_in_inr === null ||
                                            data?.ent_amount_in_inr ===
                                              undefined
                                              ? "0.00"
                                              : data?.ent_amount_in_inr.toFixed(
                                                  2
                                                )}
                                          </td>

                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {data?.currency_code ?? "-"}
                                          </td>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {JSON.stringify(
                                              data?.amount_in_forex
                                            )?.includes(".")
                                              ? data?.amount_in_forex.toFixed(2)
                                              : data?.amount_in_forex + ".00"}
                                          </td>
                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {JSON.stringify(
                                              data?.amount_in_inr
                                            )?.includes(".")
                                              ? data?.amount_in_inr.toFixed(2)
                                              : data?.amount_in_inr + ".00"}
                                          </td>
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_forex
                                              )?.includes(".")
                                                ? data?.amount_in_forex.toFixed(
                                                    2
                                                  )
                                                : data?.amount_in_forex + ".00"}
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          <td
                                            style={
                                              data?.is_exception === true
                                                ? { color: "red" }
                                                : {}
                                            }
                                          >
                                            {currencyList
                                              ?.filter(
                                                (ele) =>
                                                  ele?.name ==
                                                  data?.currency_code
                                              )
                                              ?.map((item) => (
                                                <span>{item?.amount}</span>
                                              ))}
                                          </td>
                                          {state?.ssc_head_status === "A" ||
                                          state?.ssc_manager_status === "A" ? (
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {JSON.stringify(
                                                data?.amount_in_inr
                                              )?.includes(".")
                                                ? data?.amount_in_inr.toFixed(2)
                                                : data?.amount_in_inr + ".00"}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <div className="col-sm-12">
                                    {/* <p style={{color:'black', float:'left'}}></p> */}
                                    <p
                                      style={{
                                        color:
                                          userData?.dark_mode == false
                                            ? "white"
                                            : "black",
                                        marginTop: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Applied: ₹
                                      {foreignDa === null ||
                                      foreignDa === undefined
                                        ? "0"
                                        : foreignDa?.toFixed(2)}
                                      {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? ` Approved: ₹ ${foreignDa?.toFixed(
                                            2
                                          )}`
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Conveyance Details */}
                            {foreignExpData?.conveyance_data?.length === 0 ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                <div className="mb-2 ml-3">
                                  <h6 className="text-theme mb-0">
                                    <strong>Conveyance Details</strong>
                                    <i className="far fa-arrow-alt-circle-right"></i>{" "}
                                  </h6>
                                </div>
                                <div className="table-responsive mb-4">
                                  <table className="table table-striped table-bordered new-table tablecured viewpo viewpoprinttab">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Travel Mode</th>
                                        <th>From</th>

                                        <th>To</th>
                                        <th>Paid By</th>
                                        <th>Currency</th>

                                        <th>Amt(In Forex)</th>
                                        <th>Amt(In Inr)</th>

                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In Forex)</th>
                                        ) : (
                                          ""
                                        )}
                                        <th>Exchange Rate</th>
                                        {state?.ssc_head_status === "A" ||
                                        state?.ssc_manager_status === "A" ? (
                                          <th>Approved Amt(In INR)</th>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {foreignExpData?.conveyance_data?.map(
                                        (data) => (
                                          <tr>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {moment(
                                                data?.conveyance_date
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.modee_name ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.from_location ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.to_location ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.paid_by ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.currency_code ?? "-"}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.amount_in_forex === null ||
                                              data?.amount_in_forex ===
                                                undefined
                                                ? "0.00"
                                                : data?.amount_in_forex.toFixed(
                                                    2
                                                  )}
                                            </td>
                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {data?.amount_in_inr === null ||
                                              data?.amount_in_inr === undefined
                                                ? "0.00"
                                                : data?.amount_in_inr.toFixed(
                                                    2
                                                  )}
                                            </td>

                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_forex
                                                )?.includes(".")
                                                  ? data?.amount_in_forex.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_forex +
                                                    ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            <td
                                              style={
                                                data?.is_exception === true
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {currencyList
                                                ?.filter(
                                                  (ele) =>
                                                    ele?.name ==
                                                    data?.currency_code
                                                )
                                                ?.map((item) => (
                                                  <span>{item?.amount}</span>
                                                ))}
                                            </td>
                                            {state?.ssc_head_status === "A" ||
                                            state?.ssc_manager_status ===
                                              "A" ? (
                                              <td
                                                style={
                                                  data?.is_exception === true
                                                    ? { color: "red" }
                                                    : {}
                                                }
                                              >
                                                {JSON.stringify(
                                                  data?.amount_in_inr
                                                )?.includes(".")
                                                  ? data?.amount_in_inr.toFixed(
                                                      2
                                                    )
                                                  : data?.amount_in_inr + ".00"}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="col-sm-12">
                                    {/* <p style={{color:'black', float:'left'}}></p> */}
                                    <p
                                      style={{
                                        color:
                                          userData?.dark_mode == false
                                            ? "white"
                                            : "black",
                                        marginTop: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Applied: ₹
                                      {foreignConv === null ||
                                      foreignConv === undefined
                                        ? "0"
                                        : foreignConv?.toFixed(2)}
                                      {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                                      {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? ` Approved: ₹ ${foreignConv?.toFixed(
                                            2
                                          )}`
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <div className="d-flex justify-content-between viewformaftersubmit m-0">
                          <div className="col-md-4">
                            <div className="form-group innergroupPrint">
                              <label>Expense By Self</label>

                              <p>
                                <i className="fas fa-rupee-sign f-12"></i>
                                {Number(
                                  hDetails +
                                    foreignHotel +
                                    sd +
                                    cd +
                                    // state?.foreign_conveyance_self +
                                    Number(
                                      ticketList
                                        .filter((obj) => {
                                          if (
                                            obj.paid_by?.toLowerCase() ===
                                            "self"
                                          )
                                            return true;
                                        })
                                        .reduce((sum, obj) => {
                                          return (
                                            sum +
                                            (obj?.taxable_amount !== null ||
                                            obj?.taxable_amount !== undefined
                                              ? obj.taxable_amount
                                              : 0) +
                                            (obj?.tax_amount !== null ||
                                            obj?.tax_amount !== undefined
                                              ? obj.tax_amount
                                              : 0) +
                                            (obj?.other_charges !== null ||
                                            obj?.other_charges !== undefined
                                              ? obj.other_charges
                                              : 0)
                                          );
                                        }, 0)
                                    ) +
                                    amountVehicle +
                                    (foreignFood === "" ||
                                    foreignFood === null ||
                                    foreignFood === undefined
                                      ? 0
                                      : foreignFood) +
                                    (foreignDa === "" ||
                                    foreignDa === null ||
                                    foreignDa === undefined
                                      ? 0
                                      : foreignDa) +
                                    (foreignConv === "" ||
                                    foreignConv === null ||
                                    foreignConv === undefined
                                      ? 0
                                      : foreignConv)
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group innergroupPrint">
                              <label>Expense By Company</label>
                              <p>
                                <i className="fas fa-rupee-sign f-12"></i>{" "}
                                {Number(
                                  ticketList
                                    .filter((obj) => {
                                      if (
                                        obj.paid_by === "Company" ||
                                        obj.paid_by === "company"
                                      )
                                        return true;
                                    })
                                    .reduce((sum, obj) => {
                                      return (
                                        sum +
                                        (obj?.taxable_amount !== null ||
                                        obj?.taxable_amount !== undefined
                                          ? obj.taxable_amount
                                          : 0) +
                                        (obj?.tax_amount !== null ||
                                        obj?.tax_amount !== undefined
                                          ? obj.tax_amount
                                          : 0) +
                                        (obj?.other_charges !== null ||
                                        obj?.other_charges !== undefined
                                          ? obj.other_charges
                                          : 0)
                                      );
                                    }, 0)
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group innergroupPrint">
                              <label>Total Expense</label>
                              <p>
                                <i className="fas fa-rupee-sign f-12"></i>
                                {Number(
                                  (hDetails ? Number(hDetails) : 0) +
                                    (foreignHotel ? Number(foreignHotel) : 0) +
                                    (Number(sd) ? Number(sd) : 0) +
                                    (Number(cd) ? Number(cd) : 0) +
                                    // (state?.foreign_conveyance_self
                                    //   ? Number(state?.foreign_conveyance_self)
                                    //   : 0) +
                                    (foreignFood === "" ||
                                    foreignFood === null ||
                                    foreignFood === undefined
                                      ? 0
                                      : foreignFood) +
                                    +(foreignDa === "" ||
                                    foreignDa === null ||
                                    foreignDa === undefined
                                      ? 0
                                      : foreignDa) +
                                    (foreignConv === "" ||
                                    foreignConv === null ||
                                    foreignConv === undefined
                                      ? 0
                                      : foreignConv) +
                                    Number(
                                      ticketList
                                        .filter((obj) => {
                                          if (
                                            obj?.paid_by?.toLowerCase() ===
                                            "self"
                                          )
                                            return true;
                                        })
                                        .reduce((sum, obj) => {
                                          return (
                                            sum +
                                            (obj?.taxable_amount !== null ||
                                            obj?.taxable_amount !== undefined
                                              ? obj.taxable_amount
                                              : 0) +
                                            (obj?.tax_amount !== null ||
                                            obj?.tax_amount !== undefined
                                              ? obj.tax_amount
                                              : 0) +
                                            (obj?.other_charges !== null ||
                                            obj?.other_charges !== undefined
                                              ? obj.other_charges
                                              : 0)
                                          );
                                        }, 0)
                                    ) +
                                    (amountVehicle
                                      ? Number(amountVehicle)
                                      : 0) +
                                    ticketList
                                      .filter((obj) => {
                                        if (
                                          obj?.paid_by?.toLowerCase() ===
                                          "company"
                                        )
                                          return true;
                                      })
                                      .reduce((sum, obj) => {
                                        return (
                                          sum +
                                          (obj?.taxable_amount !== null ||
                                          obj?.taxable_amount !== undefined
                                            ? obj.taxable_amount
                                            : 0) +
                                          (obj?.tax_amount !== null ||
                                          obj?.tax_amount !== undefined
                                            ? obj.tax_amount
                                            : 0) +
                                          (obj?.other_charges !== null ||
                                          obj?.other_charges !== undefined
                                            ? obj.other_charges
                                            : 0)
                                        );
                                      }, 0)
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between viewformaftersubmit m-0">
                          {state?.ssc_head_status === "A" ||
                          state?.ssc_manager_status === "A" ? (
                            <div className="col-md-3">
                              <div className="form-group innergroupPrint">
                                <label>Appr. Exp. By Company</label>
                                <p>
                                  <i className="fas fa-rupee-sign f-12"></i>
                                  {Number(
                                    ticketList
                                      .filter((obj) => {
                                        if (
                                          obj.paid_by === "company" ||
                                          obj.paid_by === "Company"
                                        )
                                          return true;
                                      })
                                      .reduce((sum, obj) => {
                                        return (
                                          sum +
                                          (obj?.appr_taxable_amount !== null ||
                                          obj?.appr_taxable_amount !== undefined
                                            ? obj.appr_taxable_amount
                                            : 0) +
                                          (obj?.tax_amount !== null ||
                                          obj?.tax_amount !== undefined
                                            ? obj.tax_amount
                                            : 0) +
                                          (obj?.appr_other_charges !== null ||
                                          obj?.appr_other_charges !== undefined
                                            ? obj.appr_other_charges
                                            : 0)
                                        );
                                      }, 0)
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {state?.ssc_head_status === "A" ||
                          state?.ssc_manager_status === "A" ? (
                            <div className="col-md-3">
                              <div className="form-group innergroupPrint">
                                <label>Appr. Exp. By Self:</label>
                                <p>
                                  <i className="fas fa-rupee-sign f-12"></i>{" "}
                                  {Number(
                                    (hotelDetTCA ? Number(hotelDetTCA) : 0) +
                                      (sda ? Number(sda) : 0) +
                                      (foreignHotel
                                        ? Number(foreignHotel)
                                        : 0) +
                                      (cdA ? Number(cdA) : 0) +
                                      // (state?.foreign_conveyance_self
                                      //   ? Number(state?.foreign_conveyance_self)
                                      //   : 0) +
                                      (foreignFood === "" ||
                                      foreignFood === null ||
                                      foreignFood === undefined
                                        ? 0
                                        : foreignFood) +
                                      +(foreignDa === "" ||
                                      foreignDa === null ||
                                      foreignDa === undefined
                                        ? 0
                                        : foreignDa) +
                                      (foreignConv === "" ||
                                      foreignConv === null ||
                                      foreignConv === undefined
                                        ? 0
                                        : foreignConv) +
                                      Number(
                                        ticketList
                                          .filter((obj) => {
                                            if (obj.paid_by === "self")
                                              return true;
                                          })
                                          .reduce((sum, obj) => {
                                            return (
                                              sum +
                                              (obj?.appr_taxable_amount !==
                                                null ||
                                              obj?.appr_taxable_amount !==
                                                undefined
                                                ? obj.appr_taxable_amount
                                                : 0) +
                                              (obj?.tax_amount !== null ||
                                              obj?.tax_amount !== undefined
                                                ? obj.tax_amount
                                                : 0) +
                                              (obj?.appr_other_charges !==
                                                null ||
                                              obj?.appr_other_charges !==
                                                undefined
                                                ? obj.appr_other_charges
                                                : 0)
                                            );
                                          }, 0)
                                      ) +
                                      (amountVehicle
                                        ? Number(amountVehicle)
                                        : 0)
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {state?.ssc_head_status === "A" ||
                          state?.ssc_manager_status === "A" ? (
                            <div className="col-md-2">
                              <div className="form-group innergroupPrint">
                                <label>Total Appr. Amt.:</label>
                                <p>
                                  <i className="fas fa-rupee-sign f-12"></i>
                                  {Number(
                                    (hotelDetTCA ? Number(hotelDetTCA) : 0) +
                                      (foreignHotel
                                        ? Number(foreignHotel)
                                        : 0) +
                                      (Number(sda) ? Number(sda) : 0) +
                                      (Number(cdA) ? Number(cdA) : 0) +
                                      // (state?.foreign_conveyance_self
                                      //   ? Number(state?.foreign_conveyance_self)
                                      //   : 0) +
                                      (foreignFood === "" ||
                                      foreignFood === null ||
                                      foreignFood === undefined
                                        ? 0
                                        : foreignFood) +
                                      (foreignDa === "" ||
                                      foreignDa === null ||
                                      foreignDa === undefined
                                        ? 0
                                        : foreignDa) +
                                      (foreignConv === "" ||
                                      foreignConv === null ||
                                      foreignConv === undefined
                                        ? 0
                                        : foreignConv) +
                                      Number(
                                        ticketList
                                          .filter((obj) => {
                                            if (obj.paid_by === "self")
                                              return true;
                                          })
                                          .reduce((sum, obj) => {
                                            return (
                                              sum +
                                              (obj?.appr_taxable_amount !==
                                                null ||
                                              obj?.appr_taxable_amount !==
                                                undefined
                                                ? obj.appr_taxable_amount
                                                : 0) +
                                              (obj?.tax_amount !== null ||
                                              obj?.tax_amount !== undefined
                                                ? obj.tax_amount
                                                : 0) +
                                              (obj?.appr_other_charges !==
                                                null ||
                                              obj?.appr_other_charges !==
                                                undefined
                                                ? obj.appr_other_charges
                                                : 0)
                                            );
                                          }, 0)
                                      ) +
                                      (amountVehicle
                                        ? Number(amountVehicle)
                                        : 0) +
                                      ticketList
                                        .filter((obj) => {
                                          if (
                                            obj.paid_by === "Company" ||
                                            obj.paid_by === "company"
                                          )
                                            return true;
                                        })
                                        .reduce((sum, obj) => {
                                          return (
                                            sum +
                                            (obj?.appr_taxable_amount !==
                                              null ||
                                            obj?.appr_taxable_amount !==
                                              undefined
                                              ? obj.appr_taxable_amount
                                              : 0) +
                                            (obj?.tax_amount !== null ||
                                            obj?.tax_amount !== undefined
                                              ? obj.tax_amount
                                              : 0) +
                                            (obj?.appr_other_charges !== null ||
                                            obj?.appr_other_charges !==
                                              undefined
                                              ? obj.appr_other_charges
                                              : 0)
                                          );
                                        }, 0)
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {state?.ssc_head_status === "A" ||
                          state?.ssc_manager_status === "A" ? ( */}
                          <div class="px-2 pb-2 " style={{ fontSize: "14px" }}>
                            {/* <h6 class="f-14 mt-2"> */}
                            <strong>
                              <span class="text-muted">Delay Deduction :</span>
                              <span class="text-danger">
                                {calculateDateOfJoining(
                                  new Date(
                                    userDetails?.joiningDate?.date_of_joining
                                  ),
                                  state?.courier_date !== null ? moment(state?.courier_date).format("YYYY-MM-DD") : state?.claim_date !== null ? moment(state?.claim_date).format("YYYY-MM-DD") : new Date()
                                ) < 61
                                  ? 0
                                  : userDetails?.emp_sub_group?.includes([
                                      "D1",
                                      "D2",
                                      "C1",
                                    ])
                                  ? 0
                                  : state?.travel_detail_data?.length > 0 &&
                                  state?.courier_date !== null
                                ? calculateDateDifference1(
                                    moment.utc(state?.courier_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) + " Days" 
                                 : state?.travel_detail_data?.length > 0 &&
                                    state?.claim_date !== null
                                  ? calculateDateDifference1(
                                      moment.utc(state?.claim_date).format(
                                        "YYYY-MM-DD"
                                      ),
                                      state?.travel_detail_data[
                                        state?.travel_detail_data?.length - 1
                                      ]?.to_date
                                    ) + " Days"
                                  : calculateDateDifference1(
                                      moment(newDate).format("YYYY-MM-DD"),
                                      state?.travel_detail_data[
                                        state?.travel_detail_data?.length - 1
                                      ]?.to_date
                                    ) + " Days"}
                              </span>
                              &nbsp;&nbsp;
                            </strong>
                            <span class="text-danger">
                              {calculateDateOfJoining(
                                new Date(
                                  userDetails?.joiningDate?.date_of_joining
                                ),
                                state?.courier_date !== null ? moment(state?.courier_date).format("YYYY-MM-DD") : state?.claim_date !== null ? moment(state?.claim_date).format("YYYY-MM-DD") : new Date()
                              ) < 61 ||
                              userDetails?.emp_sub_group?.includes([
                                "D1",
                                "D2",
                                "C1",
                              ])
                                ? "0%"
                                : state?.claim_date !== null &&
                                  calculateDateDifference1(
                                    moment.utc(state?.claim_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) <= Number(7)
                                ? "0%"
                                : calculateDateDifference1(
                                    moment(newDate).format("YYYY-MM-DD"),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) <= Number(7)
                                ? "0%"
                                : state?.claim_date !== null &&
                                  calculateDateDifference1(
                                    moment.utc(state?.claim_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) >= Number(8) &&
                                  calculateDateDifference1(
                                    moment.utc(state?.claim_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) <= Number(20)
                                ? "(5%)"
                                : calculateDateDifference1(
                                    moment(newDate).format("YYYY-MM-DD"),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) >= Number(8) &&
                                  calculateDateDifference1(
                                    moment(newDate).format("YYYY-MM-DD"),
                                    state?.travel_detail_data[
                                      state?.travel_detail_data?.length - 1
                                    ]?.to_date
                                  ) <= Number(20)
                                ? "(5%)"
                                : "(100%)"}
                            </span>
                          </div>
                          {/* ) : (
                            ""
                          )} */}
                        </div>
                        <div
                          className="row viewformaftersubmit m-0"
                          style={{ float: "right" }}
                        >
                          Signature
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                  <div className="mb-2 ml-3">
                    {/* <h6 className="text-theme mb-0">
                      <i className="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Query Claim</strong>
                    </h6> */}
                  </div>
                  <div className="col-md-12 text-center mt-3 pb-3">
                    <Link
                      to="/expense-listing"
                      className="btn btn-outline-secondary mr-2"
                    >
                      Close
                    </Link>
                    <button
                      className="btn btn-primary-inner bpi-main"
                      id="btnPrint"
                      onClick={Print}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPrintPage;
